import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { GetPkTlds } from "../../../services/Network.js";
import DomainExtraction from "./includes/PKDomain/DomainExtraction";
import DomainMigration from "./includes/PKDomain/DomainMigration";
import DomainSearchGlobal from "../../reuseable/DomainSearchGlobal";
import Faq from "../../reuseable/Faq";
import GoingOnline from "./includes/PKDomain/GoingOnline";
import WillingForHelp from "../../reuseable/WillingForHelp";
import DomainTips from "./includes/PKDomain/DomainTips";
import BundelBooster from "./includes/PKDomain/BundelBooster";
import InPakistan from "./includes/PKDomain/InPakistan";

const BuyPkDomain = () => {
  const [spotlight, setspotlight] = useState([]);
  const { currencyName } = useSelector((state) => state.currencyName);

  useEffect(() => {
    GetPkTlds().then((res) => {
      setspotlight(res);
    });
  }, [currencyName]);

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "How do I know if my domain name is available?",
        desc: "It’s really simple to find out. Just enter your ideal domain name in the Search Box and click Search. If the domain name is available you can purchase it instantly, or we can help you search for another unique domain name that is equally perfect. Moreover, you also have the option of choosing from a variety of extensions for your domain name.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "How many minimum characters are required to register a .pk domain?",
        desc: "While other domain extensions could be registered with even a single-character domain name, in order to register a .pk domain, you’d need at least 4 minimum characters.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Is the registration of an edu.pk domain similar to other TLDs?",
        desc: "No, it isn’t. For this, you’d need to fulfil a certain criterion, e.g. your institute must be registered by the government. For further details on an edu.pk domain, you can contact our pre-sales team.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "What is domain extension?",
        desc: "These are the letters that appear after your domain name, such as .pk, .com, .edu, .gov, etc. These extensions usually represent a word, for instance, .pk is short for Pakistan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Net-Ops-Engineer-Muhammad-Rameez.png",
  };
  const BundleData = {
    Heading: "Domain Renewals & Transfers",
    SubHeading: "Keeping Your Website, and Your Business, Alive",
    Bundles: [
      {
        id: 0,
        bundleHeading: "Transfer .pk Domain",
        bundleParagh: [
          <>
            <p>
              We know getting stuck can be terrible. So, to help you out, and to
              keep your site running smoothly, we’re offering hassle-free domain
              name transfer without any charges at all.
            </p>
            <p>
              Ready to take on the web with us? Download our Domain Transfer
              Letter now and let us get you through!
            </p>
          </>,
        ],
        bundleImage: "assests/img/Transfer-pk-Domain.png",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
      },
      {
        id: 1,
        bundleHeading: "Domain Renewals",
        bundleParagh: [
          <>
            <p>
              Having your domain accidently expired can be a horrifying
              nightmare. We can help you stay on the World Wide Web, even if we
              aren’t your current domain provider. Our magic wand is: we’re the
              “gold partners of .pk domains registry”!
            </p>
            <p>So, are you stumbling? Grab our hand!</p>
          </>,
        ],
        bundleImage: "assests/img/Transfer-pk-Domain.png",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
        bundleId: "malware-scanning-tab",
        bundleDataTarget: "#malware",
        bundleAriaControl: "malware",
        bundleParaghId: "malware",
        bundleParaghAriaLable: "malware-scanning-tab",
      },
    ],
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading: "We Don’t Just Build Websites, We Create an Impact",
    box: [
      {
        id: 0,
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const InPakistanData = {
    Heading: ".pk Domain Registration in Pakistan",
    subHeading: "The Right Domain Name Can Get You the Right Visitors",
    peragraph: [
      <>
        <p>
          Getting a pk domain registered for your new business site by a
          Pakistani host can be a bit daunting since it’s not always easy
          finding the perfect domain name. However, with the help of our
          dedicated professionals providing their services at our offices in
          Pakistan, we can help make .com.pk domain name registration a piece of
          cake for you!
        </p>
        <p>
          Not just this, but as the No. 1 pak web hosting service provider and
          domain registrar in Pakistan, we also offer great additional bonus
          services including domain name privacy and protection, auto-renewal,
          domain lock, as well as easy management in addition to powerful &amp;
          optimized <Link to="/business-hosting">web hosting in Pakistan</Link>.
          Furthermore, you can easily check, find and purchase dot com domains
          at cheap prices with our online domain finder.
        </p>
        <p>
          Also, in case you’re facing difficulty in how to and where to find the
          perfect domain name that resonates with your business, our experts can
          help you out with some great tips. And for that, you don’t even need
          to visit us, just contact our team through live chat, email, or a
          phone call and we’d assist you in your .pknic domain search.
        </p>
        <p>
          Other than this, we’re got a variety of domain extensions that you can
          choose from and buy at affordable rates. Being the{" "}
          <Link to="/">no. 1 web hosting company in Pakistan</Link>, we ensure
          availability of the best domain names that you can pair up with any of
          our reliable hosting plans and top-notch{" "}
          <Link to="/web-development">web development services</Link> to create
          a website that shows your true brand spirit.
        </p>
      </>,
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Don’t Be Stumped. We’ve Got You Covered!",
    subHeading: "Domain Name Tips from Experts",
    box: [
      {
        id: 0,
        icon: "icon ws-quick",
        title: "Easy-to-Remember",
        peragraph:
          "It’s great if your domain name includes a keyword to help with SEO, but make sure to keep it simple, catchy, and memorable. Avoid going for a name that’s way too long or complicated.",
      },
      {
        id: 1,
        icon: "icon ws-brand",
        title: "Resonates with Your Brand",
        peragraph:
          "Nothing is better than a domain name that’s brandable, highly unique, and resonates with your business and company goals. Try and keep it relevant to the purpose of your website",
      },
      {
        id: 2,
        icon: "icon ws-time-a",
        title: "Don’t Waste Time",
        peragraph:
          "When it comes to domain names, they get registered pretty fast. So, if you’ve found your ideal one, don’t waste any more time, just hurry up and register before anyone else does!",
      },
    ],
  };

  const domainSearch = {
    Heading: "Let Your Ideal Identity Be Born!",
    SubHeading:
      "Find and claim the perfect domain name before anyone else does!",
  };

  return (
    <>
      <Helmet>
        <title>
          Buy PK Domain | Com PK Domain Registration | PK Domain Availability
        </title>
        <meta
          name="title"
          content="Buy PK Domain | Com PK Domain Registration | PK Domain Availability"
        />
        <meta
          name="description"
          content="Buy PK Domain Name on best rate, only at WebSouls. We offer .com.pk domain name registration services, check pk domain availability and get your domain today."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Pk Domain Registration - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "Getting a pk domain registered for your new business site can be a bit daunting since it’s not always easy finding the perfect domain name. However, with the help of our dedicated professionals providing their services at our offices in Pakistan, we can help make .com .pk domain name registration a piece of cake for you!",
  "brand": "WebSouls",
  "sku": "Pk Domain Registration",
  "mpn": "Pk Domain Registration",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/buy-pk-domain",
    "priceCurrency": "PKR",
    "lowPrice": "2549",
    "highPrice": "2549"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "4",
    "ratingCount": "6251"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How do I know if my domain name is available?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It’s really simple to find out. Just enter your ideal domain name in the Search Box and click Search. If the domain name is available you can purchase it instantly, or we can help you search for another unique domain name that is equally perfect. Moreover, you also have the option of choosing from a variety of extensions for your domain name."
    }
  },{
    "@type": "Question",
    "name": "How many minimum characters are required to register a .pk domain?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "While other domain extensions could be registered with even a single-character domain name, in order to register a .pk domain, you’d need at least 4 minimum characters."
    }
  },{
    "@type": "Question",
    "name": "Is the registration of an edu.pk domain similar to other TLD's?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, it isn’t. For this, you’d need to fulfil a certain criterion, e.g. your institute must be registered by the government. For further details on an edu.pk domain, you can contact our pre-sales team."
    }
  },{
    "@type": "Question",
    "name": "What is domain extension?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "These are the letters that appear after your domain name, such as .pk, .com, .edu, .gov, etc. These extensions usually represent a word, for instance, .pk is short for Pakistan."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/buy-pk-domain" />
      </Helmet>
      <DomainSearchGlobal  domainSearch={domainSearch}/>
      <DomainExtraction spotlight={spotlight} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <DomainTips Data={technicalSpecificationsData} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default BuyPkDomain;
