import React from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../../reuseable/Banner2";
import DomainMigration from "./includes/DomainMigration";

const DomainTransfer = () => {
  const bannerData = {
    heading: "Relax. We’ve Got It Under Control",
    peragraph: "Getting You Onboard Is Our Job, Succeeding Is Yours",
  };
  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  return (
    <>
      <Helmet>
        <title>
          Domain Transfer Process | .com Domain Transfer Process | WebSouls
        </title>
        <meta
          name="title"
          content="Domain Transfer Process | .com Domain Transfer Process | WebSouls"
        />
        <meta
          name="description"
          content="Transfer your domain to best provider. Talk to us, learn domain transfer process and leave everything to us. Our experts can transfer your domain without any downtime."
        />
        <meta name="keywords" content="" />

        <script type="application/ld+json">
          {`{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name" : "WebSouls - Domain Transfer",
  "image" : "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "telephone" : "+92 322 525 2352",
  "email" : "sales@websouls.com",
  "address" : {
    "@type" : "PostalAddress",
    "streetAddress" : "Office # 22, 2nd Office Floor, IT Tower, Behind Hafeez Center, 73-E-1 Hali Road, Gulberg III",
    "addressLocality" : "Lahore",
    "addressRegion" : "Punjab",
    "addressCountry" : "Pakistan",
    "postalCode" : "54000"
  },
  "url" : "https://www.websouls.com/domain-transfer",
  "aggregateRating" : {
    "@type" : "AggregateRating",
    "ratingValue" : "4.9",
    "bestRating" : "5",
    "ratingCount" : "1747"
  },
  "review" : {
    "@type" : "Review",
    "author" : {
      "@type" : "Person",
      "name" : "Faisal Rafiq"
    },
    "reviewRating" : {
      "@type" : "Rating",
      "ratingValue" : "5",
      "bestRating" : "5"
    },
    "reviewBody" : "I have been using this company service since 2010 and I did not face any problem except only one time as I remember. I will give this company 7 stars."
  }
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/domain-transfer" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <section className="sec-transfer-sets pt-100">
        <div className="container">
          <p>
            Is your current domain provider not fulfilling all your
            expectations? We’ve got you covered with our FREE, quick, and easy
            domain transfer service. Our team can handle the migration process
            and we can get you up and running in no time at all! Here’s how it
            all goes:
          </p>
          <div className="row mb-5 mt-5">
            <div className="col-md-2 pe-0 ">
              <div className="setps-1 bordersetps">
                <div className="d-table-cell align-middle ">
                  <span>Step 1</span>
                </div>
              </div>
            </div>
            <div className="col-md-10 ps-0">
              <div className="setps-progress">
                <div className="d-table-cell align-middle ">
                  <h3>Unlock the Domain</h3>
                  <p />
                  <p>
                    First things first, in order to transfer a domain, you’d
                    need to check and confirm that the said domain isn’t locked.
                    For that, contact your current registrar for details and
                    make sure the “Domain Lock” is disabled.
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-2 pe-0">
              <div className="setps-1 bordersetps">
                <div className="d-table-cell align-middle ">
                  <span>Step 2</span>
                </div>
              </div>
            </div>
            <div className="col-md-10 ps-0">
              <div className="setps-progress">
                <div className="d-table-cell align-middle">
                  <h3>Get an Authorisation code</h3>
                  <p />
                  <p>
                    Next up, you’d need to get an authorisation code from your
                    current registrar, it is also often referred to as a
                    transfer key or EPP code. Also, in order to carry out the
                    desired task, we’d need approval from your domain’s
                    Administrative Contact. The request is sent in the form of
                    an e-mail, and your transfer request may be denied in case
                    the e-mail address is invalid on the current domain
                    registration information.
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-2 pe-0">
              <div className="setps-1 bordersetps">
                <div className="d-table-cell align-middle ">
                  <span>Step 3</span>
                </div>
              </div>
            </div>
            <div className="col-md-10 ps-0">
              <div className="setps-progress">
                <div className="d-table-cell align-middle">
                  <h3>Let us Handle the Rest</h3>
                  <p />
                  <p>
                    Now that you’ve provided us with an authorisation code and
                    the “Domain Lock” on the said domain is disabled, you can
                    sit back and relax while our team handles the rest.
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-2 pe-0">
              <div className="setps-1 bordersetps">
                <div className="d-table-cell align-middle ">
                  <span>Tip</span>
                </div>
              </div>
            </div>
            <div className="col-md-10 ps-0">
              <div className="setps-progress w-100">
                <div className="d-table-cell align-middle">
                  <h3>Expired Domains aren’t Transferable</h3>
                  <p />
                  <p>
                    Whenever in need of a domain transfer, always remember that
                    an expired domain cannot be transferred.
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-2 pe-0">
              <div className="setps-1 bordersetps">
                <div className="d-table-cell align-middle">
                  <span>Bonus</span>
                </div>
              </div>
            </div>
            <div className="col-md-10 ps-0">
              <div className="setps-progress">
                <div className="d-table-cell align-middle">
                  <h3>An Uplift for You</h3>
                  <p />
                  <p>
                    All the domains transferred to WebSouls are automatically
                    renewed for one year through our auto-renewal service. Which
                    means, if you transfer a domain to WebSouls, you can stay
                    carefree for a whole year without worrying about your domain
                    being accidentally lost or expired.
                  </p>
                  <p />
                </div>
              </div>
            </div>
          </div>
          <p>
            <strong>Note:</strong> This procedure solely meant for the transfer
            of routine TLDs such as .com .org .net .ae etc. Some TLDs carry
            different transfer procedures. For further information, contact our &nbsp;
            <a href="mailto:sales@websouls.com?subject=Domain%20Transfer">
              sales department
            </a>
            .
          </p>
        </div>
      </section>
      <DomainMigration Data={data} />
    </>
  );
};

export default DomainTransfer;
