import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
  delayTime,
  getResellerPrice,
} from "../../../services/Helper";
import AllPlanIncludes from "./includes/ResellerHosting/AllPlanIncludes";
import BundelBooster from "./../../reuseable/BundelBooster";
import WhyChooseUs from "./../../reuseable/WhyChooseUs";
import DomainTips from "./includes/ResellerHosting/DomainTips";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Faq from "../../reuseable/Faq";
import InPakistan from "../../reuseable/InPakistan";
import DomainMigration from "../../reuseable/DomainMigration";
import ComparePlans from "./includes/ResellerHosting/ComparePlans";
import Carousel from "react-elastic-carousel";
import WhyChooseUsThird from "../../reuseable/WhyChooseUsThird";
import GoingOnline from "./includes/ResellerHosting/GoingOnline";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";

const ResellerHosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [linuxPids, setLiniuxPids] = useState([
    109, 111, 113, 312, 313, 314, 315, 316,
  ]);
  const [winodwsPids, setWindowsPids] = useState([110, 112, 297]);
  const [statusOne, setStatusOne] = useState(true);
  const [statusTwo, setStatusTwo] = useState(true);

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Hosting Solutions Focused on Cost-Effective Reliability",
    Box: [
      {
        id: 0,
        heading: "1-Click Installation",
        subHeading: "(WordPress, Joomla, Drupal etc)",
        icon: "icon ws-1-click-install-script",
      },
      {
        id: 1,
        heading: "Proactive Support",
        subHeading: "(Phone, Chat, Tickets)",
        icon: "icon ws-proactive",
      },
      {
        id: 2,
        heading: "Personalized Email",
        subHeading: "(Example: info@yourdomain.com)",
        icon: "icon ws-email-b",
      },
      {
        id: 3,
        heading: "Easy To Use",
        subHeading: "(Hosting Panel)",
        icon: "icon ws-easy",
      },
      {
        id: 4,
        heading: "Easy Management",
        subHeading: "(Database management from cPanel)",
        icon: "icon ws-disk-b",
      },
      {
        id: 5,
        heading: "Swift Upgrading",
        subHeading: "(CPU, RAM, I/O etc.)",
        icon: "icon ws-chain",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        bundleHeading: "Backup & Restore",
        bundleParagh:
          "Website backups mean more than just peace of mind - it’s the difference between a business failing and a business succeeding, no matter what. With our daily automatic backups, we can help you bring your website back to life with just one click in case of any accidental data loss.",
        bundleImage: "assests/img/Backup-&-Restore-services.png",
        bundleId: "backupAndRestore-tab",
        bundleDataTarget: "#backupAndRestore",
        bundleAriaControl: "backupAndRestore",
        bundleParaghId: "backupAndRestore",
        bundleParaghAriaLable: "backupAndRestore-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "Keeping our clients’ data secure is essential, but keeping the data of their customers safe is what we really take pride in. By making SSL certification easy, we help businesses in building the trust of their customers, turning their website into a lead generating machine with higher rate of conversions, guaranteed.",
        bundleImage: "assests/img/Purchase-SSL-Certificate-in-Pakistan.png",
        bundleId: "ssl-certificate-tab",
        bundleDataTarget: "#ssl-tabs-certificate",
        bundleAriaControl: "ssl-tabs-certificates",
        bundleParaghId: "ssl-tabs-certificate",
        bundleParaghAriaLable: "ssl-certificate-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Dedicated IP",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Dedicated-IP-Price-In-Pakistan.png",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Website Security",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Website-Security-in-Pakistan.png",
        bundleId: "web-security-tab",
        bundleDataTarget: "#web-security",
        bundleAriaControl: "web-security",
        bundleParaghId: "web-security",
        bundleParaghAriaLable: "web-security-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Team-Websouls-Gulraiz-Khan.png",
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is Reseller hosting?",
        desc: "With reseller web hosting we enable our customers to own a web hosting business without having to deal with any kind of server management procedures. You can also host multiple websites if you buy our reseller hosting package.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        title:
          "Who will provide customer service to my customers with reseller hosting?",
        desc: "As a reseller who has purchased a reseller hosting plan, you’d provide your customers with your own outclass support services.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        title: "Can my customer trace you as my host?",
        desc: "Most probably not, because we try and stay as anonymous as possible. So, if your customer tries to track your services, he’d see only your name.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        title: "Can I upgrade my accounts after purchasing?",
        desc: "Of course, you can! The process of upgrading an account from one reseller package to other is quite an easy one and you can start enjoying the benefits as soon as the payment is cleared.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "Hosting Plans Fueled With the Latest Technology",
    box: [
      {
        id: 0,
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        id: 1,
        icon: "icon ws-cpanel",
        title: "cPanel Official Partner",
      },
      {
        id: 2,
        icon: "icon ws-clients",
        title: "1000+ Corporate Clients",
      },
      {
        id: 3,
        icon: "icon ws-scripts",
        title: "Bash Scripts",
      },
      {
        id: 4,
        icon: "icon ws-email-c",
        title: "Smooth Email Service",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-recycle",
        title: "Proactive Monitoring",
        peragraph:
          "We offer 24/7 security monitoring and DDos protection to keep all your business and personal data safe and secure from spam, malware, and hackers.",
      },
      {
        id: 1,
        icon: "icon ws-rack",
        title: "Guaranteed Server Uptime",
        peragraph:
          "You can experience the real 99.99% uptime with us, paired up with our dedicated customer support and servers that offer blazing-fast speed at all times.",
      },
      {
        id: 2,
        icon: "icon ws-scripts",
        title: "Languages & Performance",
        peragraph:
          "Our hosting plans come with high level performance and we also support numerous programming languages including PHP, CSS, HTML, and many more.",
      },
      {
        id: 3,
        icon: "icon ws-processor",
        title: "Fast and High Core CPUs",
        peragraph:
          "We can keep your site online and always running smoothly with our high core CPU, Ram and Disk IO limits. Experience the best speed load times with us!",
      },
      {
        id: 4,
        icon: "icon ws-1-click-install-script",
        title: "1-Click install Scripts",
        peragraph:
          "We offer 1-click app install so that you can easily build your website while using your favourite app. Our clients can install all the applications they love with just a single click!",
      },
      {
        id: 5,
        icon: "icon ws-ftp",
        title: "Highly Secure FTP Access",
        peragraph:
          "We provide FTP (File Transfer Protocol) access so that you can upload your website files with ease while making sure all your site data is safe and secure.",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Reseller Hosting in Pakistan",
    subHeading: "Hosting by the Experts, for the Experts",
    peragraph: [
      <>
        <p>
          Our&nbsp;
          <strong style={{ color: "grey" }}>
            reseller web hosting packages
          </strong>
          &nbsp; are ideal for hosting multiple domain names and websites, as
          well as perfectly designed to fully accommodate the demands of all
          hosting service providers.
        </p>
        <p>
          At <Link to="/">WebSouls</Link>, you can buy the best reseller web
          hosting plan with guaranteed high performance, unmatched speed, and
          more than enough disk space. Our reseller services come with cPanel
          and Plesk control panels to provide our customers with full support
          and easy to manage hosting, all at cheap price rates.
        </p>
        <span id="dots">...</span>
        <span id="more">
          <p>
            What our customer’s love the most about us is our fully automated 
            <Link to="/shared-hosting">web hosting services</Link> and the fact
            that our dedicated support team is always up for assistance. No
            prior technical knowledge or expertise is required to get started
            with our reseller cloud hosting, and, in case of any queries, you
            can contact us through live chat, phone call, or email, we respond
            to all our customers within 24 hours. Also, for face-to-face
            discussions we’re always ready to receive you at our offices in
            Lahore and Karachi.
          </p>
          <p>
            As a leading service provider of the best reseller hosting plans in
            Pakistan, we take pride in delivering 100% white label packages that
            are built on our private cloud infrastructure and are loaded with
            amazing features. We also provide unlimited bandwidth paired up with
            other amazing features within a budget.
          </p>
          <p>
            We’ve been developing businesses online since 2002 and you can trust
            us with yours too!
          </p>
        </span>
        <a className="read-more-link">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        id: 0,
        topTitle: "Need More Power?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Affordable?",
        Title: "Try Shared Hosting",
        buttonLink: "/shared-hosting",
      },
    ],
  };

  const [resellerHostingLinuxList, setResellerHostingLinuxList] = useState([]);
  const [resellerHostingWindowsList, setResellerHostingWindowsList] = useState(
    []
  );
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(false);

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots]);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  useEffect(() => {
    if (linuxPids?.length <= 3) {
      setStatusOne(false);
    }
    if (winodwsPids?.length <= 3) {
      setStatusTwo(false);
    }
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
    GetActivePromotions().then((ress) => {
      setActivePromotions(ress);
    });
    GetPackages(linuxPids).then((res) => {
      setResellerHostingLinuxList(res);
      setLoading(true);
      //delayTime(5000).then(() => {
        GetPackages(winodwsPids).then((respo) => {
          setResellerHostingWindowsList(respo);
          setLoading(true);
        
        });
      //});
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };
  return (
    <>
      <Helmet>
        <title>
          Reseller Hosting Pakistan | Reseller Hosting in Pakistan | WebSouls
        </title>
        <meta
          name="title"
          content="Reseller Hosting Pakistan | Reseller Hosting in Pakistan | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls is the right choice for reseller hosting Pakistan. You can get the best packages of reseller hosting in Pakistan at WebSouls. Start your own web hosting business today!."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Reseller Web Hosting - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "Our reseller web hosting packages are ideal for hosting multiple domain names and websites, as well as perfectly designed to fully accommodate the demands of all hosting service providers.
At WebSouls, you can buy the best reseller web hosting plan with guaranteed high performance, unmatched speed, and more than enough disk space. Our reseller services come with cPanel and Plesk control panels to provide our customers with full support and easy to manage hosting, all at cheap price rates.",
  "brand": "WebSouls",
  "sku": "Reseller Web Hosting",
  "mpn": "Reseller Web Hosting",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/reseller-hosting",
    "priceCurrency": "PKR",
    "lowPrice": "5999",
    "highPrice": "26000",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "3.5",
    "ratingCount": "2181"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is Reseller hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With reseller web hosting we enable our customers to own a web hosting business without having to deal with any kind of server management procedures. You can also host multiple websites if you buy our reseller hosting package."
    }
  },{
    "@type": "Question",
    "name": "Who will provide customer service to my customers with reseller hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "As a reseller who has purchased a reseller hosting plan, you’d provide your customers with your own outclass support services."
    }
  },{
    "@type": "Question",
    "name": "Can my customer trace you as my host?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Most probably not, because we try and stay as anonymous as possible. So, if your customer tries to track your services, he’d see only your name."
    }
  },{
    "@type": "Question",
    "name": "Can I upgrade my accounts after purchasing?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Of course, you can! The process of upgrading an account from one reseller package to other is quite an easy one and you can start enjoying the benefits as soon as the payment is cleared."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/reseller-hosting" />
      </Helmet>
      <section className="ssliderhost banner-size">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">Ready, Set, Earn!</h2>
            <h5 className="second-heading">
              Here to Help you Build Your Own Hosting Business
            </h5>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item " role="presentation">
                <button
                  className="nav-link active"
                  id="linux-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#linux"
                  type="button"
                  role="tab"
                  aria-controls="linux"
                  aria-selected="true"
                >
                  <i className="fa fa-linux" />
                  Linux
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="windows-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#windows"
                  type="button"
                  role="tab"
                  aria-controls="windows"
                  aria-selected="false"
                >
                  <i className="fa fa-windows" />
                  Windows
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="linux"
                role="tabpanel"
                aria-labelledby="linux-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusOne}
                      >
                        {resellerHostingLinuxList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getResellerPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map((pr) => pr[billingCycleHosting]),
                                  item?.pid,
                                  billingCycleHosting,
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
              <div
                className="tab-pane fade show"
                id="windows"
                role="tabpanel"
                aria-labelledby="windows-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusTwo}
                      >
                        {resellerHostingWindowsList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getResellerPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map((pr) => pr[billingCycleHosting]),
                                  item?.pid,
                                  billingCycleHosting,
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <DomainTips Data={technicalSpecificationsData} />
      <ComparePlans comparePlansState={resellerHostingLinuxList} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default ResellerHosting;
