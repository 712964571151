import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, message, Popconfirm } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  getForCartPrice,
  getPackageBasePrice,
  getAddonPriceForcart,
  domainAdonPrice,
  getPackageMinPrice,
  packageBillingcycle,
  domainBillingcycle,
  test2,
  DomainTld,
} from "../../services/Helper";
import {
  GetPackages,
  GetActivePromotions,
  GetPackageFreeDomains,
} from "../../services/Network.js";
const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode?.current?.contains(event?.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const CartOrder = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let domNode = useClickOutside(() => {
    setisCartOpen(false);
  });
  const selectPaymentMethod = props.paymentMethodSelected;
  const bypasschk = props.name;
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [showFreeDomainPrice, setshowFreeDomainPrice] = useState([]);
  const [y, setY] = useState([]);
  const [myy, setMyy] = useState(false);
  const [currentCurrencyId, setCurrentCurrencyId] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [isCartOpen, setisCartOpen] = useState(false);
  const [selectedDomainYear, setDomainSelectedYear] = useState("");
  const [selectPremDomainYear, SeletectedDomainPre] = useState("annually");
  const [pid] = useState([125, 130]);

  //subtotal the cart states

  const [domainTotal, setDomainTotal] = useState(0);
  const [addonTotal, setAddonTotal] = useState(0);
  const [packageTotal, setPackageTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [gstPrice, setGstPrice] = useState(0);
  const [totalCart, setTotalCart] = useState(0);
  const [setUpFeeCart, setSetUpFeeCart] = useState(0);
  const [checkDomainInPackage, setCheckDomainInPackage] = useState(false);
  const [setUpFeeCycleSS, setSetupfeeCycleSS] = useState("asetupfee");
  //const [test, setTest] = useState([]);

  //reducer states
  const { packageCart } = useSelector((state) => state.packageCart);
  const { domainNameClient } = useSelector((state) => state.domainNameClient);
  const { currencyName } = useSelector((state) => state.currencyName);
  const { currentUrl } = useSelector((state) => state.currentUrl);
  const { addonCart } = useSelector((state) => state.addonCart);
  const { domainSearchCart } = useSelector((state) => state.domainSearchCart);
  const { tempdomainaddingCycle } = useSelector(
    (state) => state.tempdomainaddingCycle
  );
  const { tempdomainSearchCart } = useSelector(
    (state) => state.tempdomainSearchCart
  );
  const { updatingCycle } = useSelector((state) => state.updatingCycle);
  const { addingCycle } = useSelector((state) => state.addingCycle);
  const { addingCycleDomain } = useSelector((state) => state.addingCycleDomain);
  const { cartPayment } = useSelector((state) => state.cartPayment);
  const { packagesCycles } = useSelector((state) => state.packagesCycles);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );
  const { billingCycleDomain } = useSelector(
    (state) => state.billingCycleDomain
  );
  const [aaloading, setAALoading] = useState(true);

  const handelContinue = (e) => {
    e.preventDefault();

    if (props.currentURL === "/domain") {
      navigate("/get-hosting");
    }
  };

  const handelContinueWithDomain = (e) => {
    e.preventDefault();

    for (let t = 0; t < packageCart?.length; t++) {
      dispatch({
        type: "removePackageCart",
        payload: {
          pid: packageCart[t].tid,
        },
      });
      dispatch({
        type: "removePackageAddingCycle",
        payload: {
          pid: packageCart[t].tid,
        },
      });
      dispatch({
        type: "domainSearchCart",
        payload: {
          name: packageCart[t].freedomain[0].name,
          domainName: packageCart[t].freedomain[0].domainName,
          price: packageCart[t].freedomain[0].price,
          ispremium: packageCart[t].freedomain[0].ispremium,
          // domainAddonTitle: "Privacy Protection",
          // domainAddon: packageCart[t].freedomain[0].name,
        },
      });
      dispatch({
        type: "addingCycleDomain",
        payload: {
          domainName: packageCart[t].freedomain[0].domainName,
          packageCycle: "annually",
        },
      });

      dispatch({
        type: "removeTempDomainCart",
        payload: {
          name: packageCart[t].freedomain[0].name,
          domainName: packageCart[t].freedomain[0].domainName,
          price: packageCart[t].freedomain[0].price,
          ispremium: packageCart[t].freedomain[0].ispremium,
          domainAddonTitle: "Privacy Protection",
        },
      });
      dispatch({
        type: "removeTempDomainCycle",
        payload: {
          domainName: packageCart[t].freedomain[0].domainName,
          packageCycle: "annually",
        },
      });
    }
    navigate("/cart");

    const checkDomainExits = packageCart.some((el) => el.freedomain);
  };

  const handelContinueWonFree = (e) => {
    e.preventDefault();

    navigate("/get-addons");
  };

  const handelcontineAddons = (e) => {
    e.preventDefault();
    navigate("/cart");
  };

  const handelDomainDelete = (e, action) => {
    e.preventDefault();
    dispatch({
      type: "removeDomainCart",
      payload: action,
    });
    dispatch({
      type: "removeCyleDomain",
      payload: action,
    });

    dispatch({
      type: "removeTempDomainCart",
      payload: action,
    });
    dispatch({
      type: "removeTempDomainCycle",
      payload: action,
    });
    toast.info("Domain Removed Successfully", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handelIdDelete = (e, action) => {
    e.preventDefault();

    // return;
    dispatch({
      type: "removeId",
      payload: action,
    });
  };

  const handelRemoveAddon = (e, mapAddon) => {
    e.preventDefault();
    dispatch({
      type: "removeAddons",
      payload: mapAddon,
    });
    toast.info("Addon Removed Successfully", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleDelete = (e, action) => {
    e.preventDefault();

    localStorage.removeItem("f");
    dispatch({
      type: "removePackageCart",
      payload: action,
    });
    dispatch({
      type: "removePackageAddingCycle",
      payload: action,
    });
    toast.info("Package Removed Successfully", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const disabledcon = () => {
    var va = document.getElementById("dedicated_check_search").value;
    if (va != "") {
    } else {
      var va = document
        .getElementById("mmm")
        .classList.add("pacakgenotAllowed");
      return;
    }
  };

  const handelExitingDomain = (e) => {
    e.preventDefault();
    var va = document.getElementById("dedicated_check_search").value;
    let nameConcatenate;
    if (va && va.includes(".")) {
      nameConcatenate = `${va}`;
    } else {
      nameConcatenate = `${va}.com`;
    }
    if (nameConcatenate != "" && packageCart.length !== 0) {
      dispatch({
        type: "clientDomainName",
        payload: nameConcatenate,
      });
      navigate("/get-addons");
    } else {
      return;
    }
  };

  const handelExitingSSLDomain = (e) => {
    e.preventDefault();
    var va = document.getElementById("dedicated_check_search").value;
    let nameConcatenate;
    if (va && va.includes(".")) {
      nameConcatenate = `${va}`;
    } else {
      nameConcatenate = `${va}.com`;
    }
    if (nameConcatenate !== "" && packageCart.length !== 0) {
      dispatch({
        type: "clientDomainName",
        payload: nameConcatenate,
      });
      navigate("/cart");
    } else {
      return;
    }
  };

  useEffect(() => {
    // console.log("welcome");
    GetActivePromotions().then((ress) => {
      localStorage.setItem("pr56m", JSON.stringify(ress));
      setActivePromotions(ress);
    });
    if (isCartOpen) {
      document.getElementById("body").style.overflow = "hidden";
    } else {
      document.getElementById("body").style.overflow = "visible";
    }

    /////// my code
    var yr = false;
    for (let i = 0; i < packageCart?.length; i++) {
      if (packageCart[i]?.freedomain?.length !== 0) {
        yr = true;
        break;
      }
    }

    //if((domainSearchCart && domainSearchCart.length !== 0) || (packageCart && checkDomainInPackage)){
    if (
      (domainSearchCart && domainSearchCart.length > 0) ||
      (packageCart && yr)
    ) {
      setMyy(false);

      setMyy(true);
    } else {
      setMyy(false);
    }

    ////////

    if (localStorage.getItem("addingCycle")) {
      let ad = localStorage.getItem("addingCycle");
      ad = JSON.parse(ad);

      for (var p = 0; p < ad.length; p++) {
        dispatch({
          type: "addingCycle",
          payload: {
            tid: ad[p].tid,
            packageId: ad[p].packageId,
            packageCycle: ad[p].packageCycle,
          },
        });
      }
    }

    // if (localStorage.getItem("packageCart")) {
    //   let cp = localStorage.getItem("packageCart");
    //   cp = JSON.parse(cp);

    //   for (var i = 0; i < cp.length; i++) {
    //     dispatch({
    //       type: "packageCart",
    //       payload: cp[i],
    //     });
    //     // if(cp[i]?.addons?.length>0){

    //     //   dispatch({
    //     //     type: "addonsExrra",
    //     //     payload: cp[i]?.addons,
    //     //   });
    //     // }
    //     if (localStorage.getItem("addingCycle").length == 0) {
    //       let ad = localStorage.getItem("addingCycle");
    //       ad = JSON.parse(ad);

    //       const adc = ad.find((o) => o.packageId === cp[i].pid);

    //       if (adc) {
    //         dispatch({
    //           type: "addingCycle",
    //           payload: {
    //             packageId: cp[i].pid,
    //             packageCycle: adc.packageCycle,
    //           },
    //         });
    //       }
    //     }
    //   }
    // }

    // if (localStorage.getItem("domainSearchCart")) {
    //   let adserch = localStorage.getItem("domainSearchCart");
    //   adserch = JSON.parse(adserch);

    //   for (var pp = 0; pp < adserch.length; pp++) {
    //     if (adserch[pp].domainName.includes(".pk")) {
    //       dispatch({
    //         type: "domainSearchCart",
    //         payload: {
    //           name: "domainregister",
    //           domainName: adserch[pp].domainName,
    //           price: adserch[pp].price,
    //           ispremium: adserch[pp].ispremium,
    //           value: 1,
    //           // domainAddonTitle: "Privacy Protection",
    //           //  domainAddon: response.data.domainAddon[0],
    //         },
    //       });
    //     } else {
    //       dispatch({
    //         type: "domainSearchCart",
    //         payload: {
    //           name: "domainregister",
    //           domainName: adserch[pp].domainName,
    //           price: adserch[pp].price,
    //           ispremium: adserch[pp].ispremium,
    //           value: 1,
    //           domainAddonTitle: "Privacy Protection",
    //           domainAddon: adserch[pp].domainAddon,
    //         },
    //       });
    //     }
    //   }
    // }

    if (localStorage.getItem("addingCycleDomain")) {
      let adcyc = localStorage.getItem("addingCycleDomain");
      adcyc = JSON.parse(adcyc);

      // return;
      for (var p = 0; p < adcyc.length; p++) {
        if (adcyc[p].domainName != "") {
          dispatch({
            type: "addingCycleDomain",
            payload: {
              domainName: adcyc[p].domainName,
              packageCycle: adcyc[p].packageCycle,
            },
          });
        }
      }
    }

    //updating the billing cycle
    const currencyCheck = `${currencyName.api_id}`;
    setCurrentCurrencyId(currencyCheck);

    dispatch({
      type: "updateubngsaCycker",
      payload: selectedYear,
    });

    setSelectedYear(selectedYear);

    // if (packageCart.length !== 0) {
    //   setSelectedYear(
    //     `${addingCycle[0].packageCycle}.${addingCycle[0].packageId}`
    //   );
    // }

    dispatch({
      type: "updatingCycleDomain",
      payload: selectedDomainYear,
    });

    setDomainSelectedYear(selectedDomainYear);

    if (packageCart && packageCart.length !== 0) {
      let lastIndex = packageCart.length - 1;
      GetPackageFreeDomains(packageCart[lastIndex].pid).then((response) => {
        for (let i = 0; i < packageCart?.length; i++) {
          if (packageCart[i]?.freedomain?.length === 0) {
            for (let k = 0; k < domainSearchCart?.length; k++) {
              const isSplit = domainSearchCart[k]?.domainName.split(".");
              let domainTLD = "";
              if (isSplit.length === 2) {
                domainTLD = "." + isSplit[1];
              } else {
                domainTLD = "." + isSplit[1] + "." + isSplit[2];
              }
              console.log("domainTLD: ", domainTLD);
              console.log("response[0]: ", response[0]);
              console.log("isSplit: ", isSplit);

              const segments = response[0].split(",");
              console.log("segments: ", segments);

              const isSecondStringInsideFirst = segments.some(
                (segment) =>
                  segment === domainTLD || segment.endsWith(`.${domainTLD}`)
              );

              console.log("isdomain exits: ", isSecondStringInsideFirst);

              if (isSecondStringInsideFirst) {
                dispatch({
                  type: "freeDomain",
                  payload: {
                    name: domainSearchCart[k].name,
                    domainName: domainSearchCart[k].domainName,
                    price: domainSearchCart[k].price,
                    ispremium: domainSearchCart[k].ispremium,
                  },
                });
                dispatch({
                  type: "removeDomainCart",
                  payload: {
                    name: domainSearchCart[k].name,
                    domainName: domainSearchCart[k].domainName,
                    price: domainSearchCart[k].price,
                    ispremium: domainSearchCart[k].ispremium,
                  },
                });
                dispatch({
                  type: "removeCyleDomain",
                  payload: {
                    name: domainSearchCart[k].name,
                    domainName: domainSearchCart[k].domainName,
                    price: domainSearchCart[k].price,
                    ispremium: domainSearchCart[k].ispremium,
                  },
                });
                break;
              }

              // const isResponseSplit = response[0].split(".")[1];
              // console.log("isResponseSplit: ", isResponseSplit);
              // let getLastIndex = isSplit.length - 1;
              // console.log("getLastIndex: ", getLastIndex);
              // if (isSplit[getLastIndex] === isResponseSplit) {
              //   dispatch({
              //     type: "freeDomain",
              //     payload: {
              //       name: domainSearchCart[k].name,
              //       domainName: domainSearchCart[k].domainName,
              //       price: domainSearchCart[k].price,
              //       ispremium: domainSearchCart[k].ispremium,
              //     },
              //   });
              //   dispatch({
              //     type: "removeDomainCart",
              //     payload: {
              //       name: domainSearchCart[k].name,
              //       domainName: domainSearchCart[k].domainName,
              //       price: domainSearchCart[k].price,
              //       ispremium: domainSearchCart[k].ispremium,
              //     },
              //   });
              //   dispatch({
              //     type: "removeCyleDomain",
              //     payload: {
              //       name: domainSearchCart[k].name,
              //       domainName: domainSearchCart[k].domainName,
              //       price: domainSearchCart[k].price,
              //       ispremium: domainSearchCart[k].ispremium,
              //     },
              //   });
              //   break;
              // }
              //  setTimeout(() => {

              //}, 4000);
            }
          }
        }
        tt();
        // localStorage.removeItem("f");
      });
    }

    let sss = packageCart?.map((ed) =>
      addingCycle?.filter((mad) => mad?.packageId === ed?.pid)
    );

    // let packagesPricing=[];
    let packagesPricing = addingCycle?.map((eds) =>
      packageCart
        .filter((sd) => eds?.tid === sd?.tid)
        .map((sd) =>
          currencyName.api_id === 3
            ? sd?.currency
                .find((el) => el.currency === currencyName?.api_id)
                [eds.packageCycle].split(".")[0] +
              "_" +
              eds?.packageCycle +
              "_" +
              eds?.packageId +
              "_" +
              getPackageMinPrice(
                sd?.currency.find((el) => el.currency === currencyName?.api_id)
              )
            : sd?.currency.find((el) => el.currency === currencyName?.api_id)[
                eds.packageCycle
              ] +
              "_" +
              eds?.packageCycle +
              "_" +
              eds?.packageId +
              "_" +
              getPackageMinPrice(
                sd?.currency.find((el) => el.currency === currencyName?.api_id)
              )
        )
    );

    let packagesAddonsPricing = addingCycle.map((eds) =>
      packageCart
        .filter((sd) => eds?.tid === sd.tid)
        .map((sd) =>
          sd.addons.map((asdwq) =>
            getAddonPriceForcart(
              eds.packageCycle,
              asdwq,
              currencyName.api_id,
              asdwq.pricing[currencyName.api_id][eds.packageCycle]
            )
          )
        )
    );
    let sum = 0;
    if (domainSearchCart?.length === 0) {
    } else {
      let domainPricingCheck = addingCycleDomain?.map((esd) =>
        domainSearchCart
          ?.filter((asds) => esd?.domainName === asds?.domainName)
          .map((asdds) => {
            let addPrice = [];
            let AddonPr = 0;
            if (asdds && asdds.domainAddon) {
              addPrice = asdds?.domainAddon?.filter(
                (elf) => elf?.currency == currencyName?.api_id
              );

              AddonPr = addPrice[0]?.ssetupfee;
            }

            if ((asdds && asdds.ispremium === "no") || !asdds.ispremium) {
              if (currencyName?.api_id === 3) {
                if (
                  // DomainTld(esd.domainName) === "pk" ||
                  // DomainTld(esd.domainName) === "com.pk" ||
                  asdds?.domainAddon?.length === 0
                ) {
                  return parseInt(
                    asdds?.price.find(
                      (el) => el.currency === currencyName?.api_id
                    )[esd.packageCycle]
                  );
                } else {
                  if (asdds && asdds.domainAddon) {
                    return (
                      parseInt(
                        asdds?.price.find(
                          (el) => el.currency === currencyName?.api_id
                        )[esd.packageCycle]
                      ) + parseInt(domainAdonPrice(esd.packageCycle, AddonPr))
                    );
                  } else {
                    return parseInt(
                      asdds.price.find(
                        (el) => el.currency === currencyName?.api_id
                      )[esd.packageCycle]
                    );
                  }
                }
              }
              if (currencyName?.api_id === 1) {
                if (
                  // DomainTld(esd.domainName) === "pk" ||
                  // DomainTld(esd.domainName) === "com.pk" ||
                  asdds?.domainAddon?.length === 0
                ) {
                  return parseInt(
                    asdds.price.find(
                      (el) => el.currency === currencyName?.api_id
                    )[esd.packageCycle]
                  );
                } else {
                  return (
                    parseFloat(
                      asdds.price.find(
                        (el) => el.currency === currencyName?.api_id
                      )[esd.packageCycle]
                    ) +
                    parseFloat(
                      domainAdonPrice(
                        esd.packageCycle,
                        AddonPr
                        //asdds.domainAddon[1].ssetupfee
                      )
                    )
                  );
                }
              }
            }
            if (asdds && asdds.ispremium === "yes") {
              if (currencyName?.api_id === 3) {
                if (
                  // DomainTld(esd.domainName) === "pk" ||
                  // DomainTld(esd.domainName) === "com.pk" ||
                  asdds?.domainAddon?.length === 0
                ) {
                  return parseInt(
                    asdds?.price.find(
                      (el) => el.currency === currencyName?.api_id
                    )[esd.packageCycle]
                  );
                } else {
                  if (asdds && asdds.domainAddon) {
                    return (
                      parseInt(
                        asdds?.price.find(
                          (el) => el.currency === currencyName?.api_id
                        )[esd.packageCycle]
                      ) + parseInt(domainAdonPrice(esd.packageCycle, AddonPr))
                    );
                  } else {
                    return parseInt(
                      asdds.price.find(
                        (el) => el.currency === currencyName?.api_id
                      )[esd.packageCycle]
                    );
                  }
                }
              }
              if (currencyName?.api_id === 1) {
                if (
                  // DomainTld(esd.domainName) === "pk" ||
                  // DomainTld(esd.domainName) === "com.pk" ||
                  asdds?.domainAddon?.length === 0
                ) {
                  return parseInt(
                    asdds.price.find(
                      (el) => el.currency === currencyName?.api_id
                    )[esd.packageCycle]
                  );
                } else {
                  return (
                    parseFloat(
                      asdds.price.find(
                        (el) => el.currency === currencyName?.api_id
                      )[esd.packageCycle]
                    ) +
                    parseFloat(
                      domainAdonPrice(
                        esd.packageCycle,
                        AddonPr
                        //asdds.domainAddon[1].ssetupfee
                      )
                    )
                  );
                }
              }
            }
          })
      );

      for (let i = 0; i < domainPricingCheck?.length; i++) {
        for (let k = 0; k < domainPricingCheck[i]?.length; k++) {
          sum = sum + parseFloat(domainPricingCheck[i][k]);
        }
      }
    }

    //now createing all plus

    //for domain pricing where the domain is priemum

    let sumP = 0;

    let sum2 = 0;

    for (let j = 0; j < packagesAddonsPricing?.length; j++) {
      for (let k = 0; k < packagesAddonsPricing[j]?.length; k++) {
        for (let p = 0; p < packagesAddonsPricing[j][k]?.length; p++) {
          sum2 = sum2 + parseFloat(packagesAddonsPricing[j][k][p]);
        }
      }
    }

    //for packages

    let sum3 = 0;

    for (let a = 0; a < packagesPricing?.length; a++) {
      if (packageCart.length !== 0) {
        if (packagesPricing[a][0] && packagesPricing[a][0]?.length > 0) {
          let sp = packagesPricing[a][0].split("_");

          let pr = getForCartPrice(
            sp[3],
            sp[2],
            sp[1],
            JSON.parse(localStorage.getItem("pr56m")),
            sp[0]
          );
          // console.log("prrr", pr);
          sum3 = sum3 + parseFloat(pr);
        }
      }
    }

    //for setupfee

    let sum4 = 0;
    let setupFee =
      packageCart &&
      packageCart.map((ed) =>
        ed?.addons?.map(
          (as) =>
            as?.pricing[currencyName.api_id][setUpFeeCycleSS].split(".")[0]
        )
      );

    for (let ss = 0; ss < setupFee?.length; ss++) {
      for (let vs = 0; vs < setupFee[ss]?.length; vs++) {
        sum4 += parseFloat(setupFee[ss][vs]);
      }
    }

    //for gst

    let subTotals = sumP + sum + sum2 + sum3 + sum4;

    //gst stripe hide starrts
    // let gstValue = 0;
    // if(selectPaymentMethod === "stripe"){
    //   gstValue = 0;
    // }else{
    //   gstValue = (subTotals * 5) / 100;
    // }
    //gst stripe hide ends

    let gstValue = (subTotals * 5) / 100;
    let totalsdasd = parseFloat(subTotals + gstValue);
    //getPackageBasePrice(packageCart,'130',3);

    setSetUpFeeCart(sum4);

    setDomainTotal(sum);
    setAddonTotal(sum2);
    setPackageTotal(sum3);
    setSubTotal(subTotals);
    setGstPrice(gstValue);
    setTotalCart(totalsdasd);
    localStorage.setItem("total", totalsdasd);

    if (packageCart && packageCart.length !== 0) {
      //handelUpdatePkDomainCycle();
      for (let i = 0; i < domainSearchCart?.length; i++) {
        if (domainSearchCart[i]?.domainName?.includes(".pk")) {
          for (let k = 0; k < packageCart?.length; k++) {
            if (packageCart[k]?.freedomain?.length === 0) {
            }
          }
        } else {
        }
      }
    }

    const timeIdd = setTimeout(() => {
      // After 3 seconds set the show value to false
      setAALoading(false);
    }, 2000);
    setY(tt());
    return () => {
      clearTimeout(timeIdd);
    };
  }, [
    currencyName,
    packageCart,
    domainSearchCart,
    addonCart,
    selectedYear,
    selectedDomainYear,
    addingCycleDomain,
    addingCycle,
    selectPremDomainYear,
    updatingCycle,
    y,
    checkDomainInPackage,
    myy,
    isCartOpen,
    selectPaymentMethod,
  ]);
  const tt = () => {
    ////////////////  my package free check for pk domain

    const pp = packageCart;
    var freePackage = 0;
    var f = 0;
    if (!localStorage.getItem("f")) {
      const pkg = [];
      for (var j = 0; j < pp.length; j++) {
        if (pp[j]?.freedomain?.length === 0) {
          freePackage++;
          pkg.push(pp[j]); // pkg.push.packageCart[j];
        }
      }

      var pkdomains = 0;
      const dmns = [];
      for (var d = 0; d < domainSearchCart?.length; d++) {
        pkdomains++;
        dmns.push(domainSearchCart[d]);
      }

      if (freePackage >= pkdomains) {
        for (var g = 0; g < pkg.length; g++) {
          if (f == 1) {
            // break;
          }
          for (var mn = 0; mn < dmns.length; mn++) {
            if (dmns[mn]?.domainName?.includes(".pk")) {
              dispatch({
                type: "removePackageAddingCycle",
                payload: { tid: pkg[g].tid },
              });

              if (currencyName.api_id === 3) {
                if (pkg[g].currency[0].biennially <= 0) {
                  dispatch({
                    type: "addingCycle",
                    payload: {
                      tid: pkg[g].tid,
                      packageId: pkg[g].pid,
                      packageCycle: "annually",
                    },
                  });
                } else {
                  dispatch({
                    type: "addingCycle",
                    payload: {
                      tid: pkg[g].tid,
                      packageId: pkg[g].pid,
                      packageCycle: "biennially",
                    },
                  });
                }
              } else {
                if (pkg[g].currency[1].biennially <= 0) {
                  dispatch({
                    type: "addingCycle",
                    payload: {
                      tid: pkg[g].tid,
                      packageId: pkg[g].pid,
                      packageCycle: "annually",
                    },
                  });
                } else {
                  dispatch({
                    type: "addingCycle",
                    payload: {
                      tid: pkg[g].tid,
                      packageId: pkg[g].pid,
                      packageCycle: "biennially",
                    },
                  });
                }
              }

              f = 1;
              localStorage.setItem("f", "1");

              break;
            } else {
              // dispatch({
              //   type: "removePackageAddingCycle",
              //   payload: pkg[g],
              // });
              dispatch({
                type: "addingCycle",
                payload: {
                  tid: pkg[g].tid,
                  packageId: pkg[g].pid,
                  packageCycle: "annually",
                },
              });
              f = 1;
              localStorage.setItem("f", "1");
              break;
            }
          }
        }
      }
    }
    /////////// end mine
  };
  const handelUpdatePkDomainCycle = () => {
    if (domainSearchCart?.length !== 0) {
      if (domainSearchCart[0]?.domainName?.includes(".pk")) {
        let domainFilter = domainSearchCart?.find((escape) =>
          escape?.domainName?.includes(".pk")
        );

        let domaincycleFilter = addingCycleDomain?.find(
          (asdaaa) => asdaaa?.domainName === domainFilter?.domainName
        );

        // `annually.pid`

        let packagecartFilter = packageCart;

        let concateName = `${domaincycleFilter?.packageCycle}.${packagecartFilter[0]?.pid}`;

        setSelectedYear(concateName);
        // dispatch({
        //   type: "updateubngsaCycker",
        //   payload: concateName,
        // });
      }
    }
  };

  const showFreedPrice = (adc, mapping, currency) => {
    var parr = [];
    console.log("adc.packageCycle:::", adc.packageCycle);
    GetPackageFreeDomains(adc?.packageId).then((response) => {
      console.log("ali response: ", response);
      if (mapping?.freedomain?.length > 0) {
        if (
          response[1]?.includes(adc?.packageCycle) &&
          mapping.freedomain[0]?.price?.find((el) => el.currency === currency)[
            adc.packageCycle
          ]
        ) {
          //setshowFreeDomainPrice(
          showFreeDomainPrice[adc?.packageId] =
            mapping.freedomain[0]?.price?.find(
              (el) => el.currency === currency
            )[adc.packageCycle];
          // );
        } else {
            console.log("Awawd", mapping?.freedomain[0].domainName.split(".")[1])
            if(mapping?.freedomain[0].domainName.split(".")[1] === "pk"){
              showFreeDomainPrice[adc?.packageId] =
              mapping?.freedomain[0]?.price?.find(
                (el) => el.currency === currency
              )["biennially"];
            }else{
          
           
          // setshowFreeDomainPrice(
          showFreeDomainPrice[adc?.packageId] =
            mapping?.freedomain[0]?.price?.find(
              (el) => el.currency === currency
            )["annually"];
          // );
        }
        }
      }
      //setshowFreeDomainPrice(parr)
    });

    return showFreeDomainPrice[adc?.packageId]
      ? showFreeDomainPrice[adc?.packageId]
      : 0.0;
  };

  const confirm = (e, action) => {
    console.log(e);

    localStorage.removeItem("f");
    dispatch({
      type: "removePackageCart",
      payload: action,
    });
    dispatch({
      type: "removePackageAddingCycle",
      payload: action,
    });
    toast.info("Package Removed Successfully", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const cancel = (e) => {
    console.log(e);
    // message.error("Click on No");
  };

  return (
    <>
      <div className="col-lg-5 col-xl-4 col-xxl-4 order-md-last d-none d-lg-block">
        <div className="box-border p-3">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="cart-heading">ORDER SUMMARY</span>
          </h4>
          {!aaloading ? (
            <>
              {packageCart &&
                packageCart.map((maping) => (
                  <>
                    <div className="list-group-item mb-3">
                      <li
                        className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                        style={{
                          fontWeight: 300,
                          border: "none",
                          margin: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <div
                          style={{ textTransform: "uppercase" }}
                          className="sm-w-50 maxwidth70"
                        >
                          {maping.name}
                        </div>

                        {currencyName.api_id === 3 ? (
                          <>
                            {addingCycle &&
                              addingCycle
                                .filter((elss) => elss.tid === maping.tid)
                                .map((adc) => (
                                  <>
                                    <span className="text-grey fw-light text-end ">
                                      {currencyName.prefix}
                                      {
                                        test2(
                                          getForCartPrice(
                                            getPackageBasePrice(
                                              packageCart,
                                              maping.pid,
                                              currencyName.api_id
                                            ),
                                            maping.pid,
                                            adc.packageCycle,
                                            getActivePromotions,
                                            maping.currency
                                              .filter(
                                                (fil) =>
                                                  fil.currency ===
                                                  currencyName.api_id
                                              )
                                              .map((pr) => pr[adc.packageCycle])
                                          )
                                        ).split(".")[0]
                                      }

                                      {maping.freedomain?.length === 0 ? (
                                        <button
                                          onClick={(e) =>
                                            handleDelete(e, maping)
                                          }
                                          className="cross-button"
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      ) : (
                                        <Popconfirm
                                          placement="topRight"
                                          title="Package Delete"
                                          description="This will remove the complete package (Hosting + attached Domain). Do you want to proceed?"
                                          onConfirm={(e) => confirm(e, maping)}
                                          onCancel={cancel}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <button
                                            // onClick={(e) =>
                                            //   handleDelete(e, maping)
                                            // }
                                            className="cross-button"
                                          >
                                            <i className="fa fa-times" />
                                          </button>
                                        </Popconfirm>
                                      )}
                                    </span>
                                  </>
                                ))}
                          </>
                        ) : (
                          <>
                            {addingCycle &&
                              addingCycle
                                .filter((elss) => elss.tid === maping.tid)
                                .map((adc) => (
                                  <span className="text-grey fw-light text-end">
                                    {currencyName.prefix}
                                    {test2(
                                      getForCartPrice(
                                        getPackageBasePrice(
                                          packageCart,
                                          maping.pid,
                                          currencyName.api_id
                                        ),
                                        maping.pid,
                                        adc.packageCycle,
                                        getActivePromotions,
                                        maping.currency
                                          .filter(
                                            (fil) =>
                                              fil.currency ===
                                              currencyName.api_id
                                          )
                                          .map((pr) => pr[adc.packageCycle])
                                      )
                                    )}
                                    {maping.freedomain?.length === 0 ? (
                                      <button
                                        onClick={(e) => handleDelete(e, maping)}
                                        className="cross-button"
                                      >
                                        <i className="fa fa-times" />
                                      </button>
                                    ) : (
                                      <Popconfirm
                                        placement="topRight"
                                        title="Package Delete"
                                        description="This will remove the complete package (Hosting + attached Domain). Do you want to proceed?"
                                        onConfirm={(e) => confirm(e, maping)}
                                        onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <button
                                          // onClick={(e) =>
                                          //   handleDelete(e, maping)
                                          // }
                                          className="cross-button"
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      </Popconfirm>
                                    )}
                                  </span>
                                ))}
                          </>
                        )}
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                        style={{ border: "none", paddingTop: 3 }}
                      >
                        <small>
                          <span className="text-muted">Hosting Category</span>
                        </small>
                      </li>

                      {maping &&
                        maping.addons.map((mapAddon) => (
                          <>
                            <li
                              className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                              style={{ border: "none" }}
                            >
                              <div className="sm-w-50">
                                <h4
                                  className="cart-heading6 my-0 addonss"
                                  dangerouslySetInnerHTML={{
                                    __html: mapAddon.name,
                                  }}
                                />
                                {/* {mapAddon.name}
                                </h4> */}
                              </div>
                              {addingCycle &&
                                addingCycle
                                  .filter((elss) => elss.tid === maping.tid)
                                  .map((adc) => (
                                    <span className="text-grey fw-light">
                                      {currencyName.prefix}
                                      {test2(
                                        getAddonPriceForcart(
                                          adc.packageCycle,
                                          mapAddon,
                                          currencyName.api_id,
                                          mapAddon.pricing[currencyName.api_id][
                                            adc.packageCycle
                                          ]
                                        )
                                      )}
                                      <button
                                        onClick={(e) =>
                                          handelRemoveAddon(e, mapAddon)
                                        }
                                        className="cross-button"
                                      >
                                        <i className="fa fa-times" />
                                      </button>
                                    </span>
                                  ))}
                            </li>
                          </>
                        ))}

                      {maping &&
                        maping.freedomain &&
                        maping.freedomain.map((mapfreedomain) => (
                          <>
                            <li
                              className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                              style={{
                                fontWeight: 300,
                                border: "none",
                                margin: 0,
                                paddingBottom: 0,
                                textTransform: "uppercase",
                              }}
                            >
                              <div>
                                {mapfreedomain.name === "domainregister"
                                  ? "Domain Registration"
                                  : "Domain Transfer"}
                              </div>

                              <h6
                                style={{
                                  color: "#005880",
                                  textTransform: "capitalize",
                                  width: "25%",
                                  textAlign: "right",
                                  fontWeight: 400,
                                }}
                                className="my-0"
                              >
                                Free
                                <button className="cross-button invisible">
                                  <i className="fa fa-times" />
                                </button>
                              </h6>
                            </li>
                            <li
                              className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                              style={{ border: "none", paddingTop: 3 }}
                            >
                              <div>
                                <small className="text-muted">
                                  {mapfreedomain.domainName}
                                </small>
                              </div>
                              <div>
                                <small className="text-grey fw-light">
                                  {maping &&
                                  addingCycle &&
                                  currencyName?.api_id === 3 ? (
                                    <>
                                      {addingCycle &&
                                        addingCycle
                                          .filter(
                                            (ast) => ast?.tid === maping?.tid
                                          )
                                          .map((adc) => (
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {currencyName.prefix}
                                              {
                                                // test2(
                                                //   mapfreedomain.price.find(
                                                //     (el) => el.currency === 3
                                                //   )[adc.packageCycle]
                                                // ).split(".")[0]

                                                test2(
                                                  showFreedPrice(adc, maping, 3)
                                                ).split(".")[0]
                                              }
                                            </span>
                                          ))}
                                    </>
                                  ) : (
                                    <>
                                      {addingCycle &&
                                        addingCycle
                                          .filter(
                                            (ast) => ast.tid === maping.tid
                                          )
                                          .map((adc) => (
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {currencyName.prefix}
                                              {
                                                // test2(
                                                //   mapfreedomain.price.find(
                                                //     (el) => el.currency === 1
                                                //   )[adc.packageCycle]
                                                // )
                                                test2(
                                                  showFreedPrice(adc, maping, 1)
                                                )
                                              }
                                            </span>
                                          ))}
                                    </>
                                  )}
                                  <button className="cross-button invisible">
                                    <i className="fa fa-times" />
                                  </button>
                                </small>
                              </div>
                            </li>
                          </>
                        ))}

                      <li
                        className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                        style={{ border: "none" }}
                      >
                        <select
                          className="form-select form-select-sm py-2"
                          aria-label=".form-select-sm example"
                          onChange={(e) => setSelectedYear(e.target.value)}
                          value={packageBillingcycle(addingCycle, maping.tid)}
                        >
                          {packageCart &&
                          packageCart
                            .filter((p) => p.tid === maping.tid)[0]
                            .currency.filter(
                              (c) => c.currency == currencyName.api_id
                            )[0].monthly > 0 ? (
                            <>
                              <option value={`monthly.${maping.tid}`}>
                                Monthly
                              </option>
                            </>
                          ) : null}
                          {packageCart &&
                          packageCart
                            .filter((p) => p.tid === maping.tid)[0]
                            .currency.filter(
                              (c) => c.currency == currencyName.api_id
                            )[0].quarterly > 0 ? (
                            <>
                              <option value={`quarterly.${maping.tid}`}>
                                Quarterly
                              </option>
                            </>
                          ) : null}
                          {packageCart &&
                          packageCart
                            .filter((p) => p.tid === maping.tid)[0]
                            .currency.filter(
                              (c) => c.currency == currencyName.api_id
                            )[0].semiannually > 0 ? (
                            <>
                              <option value={`semiannually.${maping.tid}`}>
                                Semiannually
                              </option>
                            </>
                          ) : null}

                          {packageCart &&
                          packageCart
                            .filter((p) => p.tid === maping.tid)[0]
                            .currency.filter(
                              (c) => c.currency == currencyName.api_id
                            )[0].annually > 0 ? (
                            <>
                              <option value={`annually.${maping.tid}`}>
                                1 Year
                              </option>
                            </>
                          ) : null}
                          {packageCart &&
                          packageCart
                            .filter((p) => p.tid === maping.tid)[0]
                            .currency.filter(
                              (c) => c.currency == currencyName.api_id
                            )[0].biennially > 0 ? (
                            <>
                              <option value={`biennially.${maping.tid}`}>
                                2 Year
                              </option>
                            </>
                          ) : null}
                          {packageCart &&
                          packageCart
                            .filter((p) => p.tid === maping.tid)[0]
                            .currency.filter(
                              (c) => c.currency == currencyName.api_id
                            )[0].triennially > 0 ? (
                            <>
                              <option value={`triennially.${maping.tid}`}>
                                3 Year
                              </option>
                            </>
                          ) : null}
                        </select>
                      </li>
                    </div>
                  </>
                ))}

              {domainSearchCart &&
                domainSearchCart.map((domaincart) => (
                  <div className="list-group-item mb-3">
                    <>
                      <>
                        <li
                          className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                          style={{
                            fontWeight: 300,
                            border: "none",
                            margin: 0,
                            paddingBottom: 0,
                            textTransform: "uppercase",
                          }}
                        >
                          {domaincart.name === "domainregister"
                            ? "Domain Registration"
                            : "Domain Transfer"}
                        </li>

                        <li
                          className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                          style={{ border: "none", paddingTop: 3 }}
                        >
                          <div>
                            <small className="text-muted">
                              {domaincart.domainName}
                            </small>
                          </div>
                          {addingCycleDomain &&
                            addingCycleDomain
                              .filter(
                                (eks) =>
                                  eks.domainName === domaincart.domainName
                              )
                              .map((adccc) => (
                                <span className="text-grey fw-light text-end">
                                  {currencyName.prefix}
                                  {currencyName.api_id === 3 ? (
                                    <>
                                      {
                                        test2(
                                          //domaincart.price[0][adccc.packageCycle]
                                          domaincart.price.find(
                                            (el) => el.currency === 3
                                          )[adccc.packageCycle]
                                        ).split(".")[0]
                                      }
                                      <button
                                        onClick={(e) =>
                                          handelDomainDelete(e, domaincart)
                                        }
                                        className="cross-button"
                                      >
                                        <i className="fa fa-times" />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {test2(
                                        // domaincart.price[1][adccc.packageCycle]
                                        domaincart.price.find(
                                          (el) => el.currency === 1
                                        )[adccc.packageCycle]
                                      )}
                                      <button
                                        onClick={(e) =>
                                          handelDomainDelete(e, domaincart)
                                        }
                                        className="cross-button"
                                      >
                                        <i className="fa fa-times" />
                                      </button>
                                    </>
                                  )}
                                </span>
                              ))}
                        </li>
                        {domainSearchCart &&
                        domainSearchCart.length === 0 ? null : (
                          <>
                            {domaincart.domainAddon === undefined ||
                            domaincart.domainAddon.length === 0 ? (
                              ""
                            ) : (
                              <li
                                className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                                style={{ border: "none" }}
                              >
                                <div>
                                  <h4 className="cart-heading6 my-0 addonss">
                                    {domaincart.domainAddonTitle}
                                  </h4>
                                </div>
                                {addingCycleDomain &&
                                  addingCycleDomain
                                    .filter(
                                      (eks) =>
                                        eks.domainName === domaincart.domainName
                                    )
                                    .map((adccc) => (
                                      <span className="text-grey fw-light">
                                        {currencyName.api_id === 3 ? (
                                          <>
                                            {currencyName.prefix}
                                            {
                                              test2(
                                                domainAdonPrice(
                                                  adccc.packageCycle,

                                                  domaincart.domainAddon[1]
                                                    .ssetupfee
                                                )
                                              ).split(".")[0]
                                            }
                                            <button
                                              className="cross-button"
                                              onClick={(e) =>
                                                handelIdDelete(e, domaincart)
                                              }
                                            >
                                              <i className="fa fa-times" />
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            {currencyName.prefix}
                                            {test2(
                                              domainAdonPrice(
                                                adccc.packageCycle,

                                                domaincart.domainAddon[0]
                                                  .ssetupfee
                                              )
                                            )}
                                            <button
                                              className="cross-button"
                                              onClick={(e) =>
                                                handelIdDelete(e, domaincart)
                                              }
                                            >
                                              <i className="fa fa-times" />
                                            </button>
                                          </>
                                        )}
                                      </span>
                                    ))}
                              </li>
                            )}
                          </>
                        )}

                        <li
                          className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                          style={{ border: "none" }}
                        >
                          <select
                            className="form-select form-select-sm py-2"
                            aria-label=".form-select-sm example"
                            value={domainBillingcycle(
                              addingCycleDomain,
                              domaincart.domainName
                            )}
                            onChange={(e) =>
                              setDomainSelectedYear(e.target.value)
                            }
                          >
                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).annually !== "" ? (
                              <option
                                value={`annually_${domaincart.domainName}`}
                              >
                                1 Year
                              </option>
                            ) : null}
                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).biennially !== "" ? (
                              <option
                                value={`biennially_${domaincart.domainName}`}
                              >
                                2 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).triennially !== "" ? (
                              <option
                                value={`triennially_${domaincart.domainName}`}
                              >
                                3 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).fourlyprice !== "" ? (
                              <option
                                value={`fourlyprice_${domaincart.domainName}`}
                              >
                                4 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).fivelyprice !== "" ? (
                              <option
                                value={`fivelyprice_${domaincart.domainName}`}
                              >
                                5 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).sixlyprice !== "" ? (
                              <option
                                value={`sixlyprice_${domaincart.domainName}`}
                              >
                                6 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).sevenlyprice !== "" ? (
                              <option
                                value={`sevenlyprice_${domaincart.domainName}`}
                              >
                                7 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).eightlyprice !== "" ? (
                              <option
                                value={`eightlyprice_${domaincart.domainName}`}
                              >
                                8 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).ninelyprice !== "" ? (
                              <option
                                value={`ninelyprice_${domaincart.domainName}`}
                              >
                                9 Years
                              </option>
                            ) : null}

                            {domaincart &&
                            domaincart.price &&
                            domaincart.price.find(
                              (el) => el.currency === currencyName.api_id
                            ).tenlyprice !== "" ? (
                              <option
                                value={`tenlyprice_${domaincart.domainName}`}
                              >
                                10 Years
                              </option>
                            ) : null}
                          </select>
                        </li>
                      </>
                    </>
                  </div>
                ))}

              <div className="d-flex justify-content-between px-4 px-4-fix">
                <small className="text-grey fw-light pt-2 text-uppercase font-16">
                  Setup Fee:
                </small>
                <small className="text-grey fw-light pt-2 font-16">
                  {currencyName.prefix}
                  {currencyName.api_id == 3
                    ? test2(formatter.format(setUpFeeCart)).split(".")[0]
                    : test2(formatter.format(setUpFeeCart))}
                  <button className="cross-button invisible">
                    <i className="fa fa-times" />
                  </button>
                </small>
              </div>
              <div className="d-flex justify-content-between px-4 px-4-fix">
                <small className="text-grey fw-light text-uppercase font-16">
                  Subtotal
                </small>
                <small className="text-grey fw-light font-16">
                  {currencyName.prefix}
                  {currencyName.api_id == 3
                    ? test2(formatter.format(subTotal)).split(".")[0]
                    : test2(formatter.format(subTotal))}
                  <button className="cross-button invisible">
                    <i className="fa fa-times" />
                  </button>
                </small>
              </div>
              {/* {selectPaymentMethod === "stripe" ? null : (
                <div className="d-flex justify-content-between px-4 px-4-fix">
                  <small className="text-grey fw-light pt-2 text-uppercase font-16">
                    GST / VAT 5%
                  </small>
                  <small className="text-grey fw-light pt-2 font-16">
                    {currencyName.prefix}
                    {currencyName.api_id == 3
                      ? test2(formatter.format(gstPrice)).split(".")[0]
                      : test2(formatter.format(gstPrice))}
                    <button className="cross-button invisible">
                      <i className="fa fa-times" />
                    </button>
                  </small>
                </div>
              )} */}

              <div className="d-flex justify-content-between px-4 px-4-fix">
                <small className="text-grey fw-light pt-2 text-uppercase font-16">
                  GST / VAT 5%
                </small>
                <small className="text-grey fw-light pt-2 font-16">
                  {currencyName.prefix}
                  {currencyName.api_id == 3
                    ? test2(formatter.format(gstPrice)).split(".")[0]
                    : test2(formatter.format(gstPrice))}
                  <button className="cross-button invisible">
                    <i className="fa fa-times" />
                  </button>
                </small>
              </div>

              <div className="d-flex justify-content-between px-4 px-4-fix pt-4">
                <h6 className="cart-total text-uppercase">Total</h6>
                <strong className="cart-total">
                  {currencyName.prefix}
                  {currencyName.api_id === 3
                    ? test2(formatter.format(totalCart)).split(".")[0]
                    : test2(formatter.format(totalCart))}
                  <button className="cross-button invisible">
                    <i className="fa fa-times" />
                  </button>
                </strong>
              </div>
            </>
          ) : (
            <>
              <div className="list-group-item mb-3">
                <li
                  className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                  style={{
                    fontWeight: 300,
                    border: "none",
                    margin: 0,
                    paddingBottom: 0,
                  }}
                >
                  <div
                    className="sm-w-50 maxwidth70"
                    style={{ textTransform: "uppercase" }}
                  >
                    {" "}
                    <Skeleton width={200} />
                  </div>
                  <span className="text-grey fw-light text-end">
                    <Skeleton width={100} />
                  </span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                  style={{ border: "none", paddingTop: 3 }}
                >
                  <small>
                    <span className="text-muted">
                      <Skeleton width={100} />
                    </span>
                  </small>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                  style={{ border: "none" }}
                >
                  <div className="sm-w-50">
                    <h4 className="cart-heading6 my-0">
                      <Skeleton width={200} />
                    </h4>
                  </div>
                  <span className="text-grey fw-light">
                    <Skeleton width={100} />
                  </span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                  style={{
                    fontWeight: 300,
                    border: "none",
                    margin: 0,
                    paddingBottom: 0,
                    textTransform: "uppercase",
                  }}
                >
                  <div>
                    <Skeleton width={200} />
                  </div>
                  <h6
                    className="my-0"
                    style={{
                      color: "rgb(0, 88, 128)",
                      textTransform: "capitalize",
                      width: "25%",
                      textAlign: "right",
                      fontWeight: 400,
                    }}
                  >
                    <Skeleton width={50} />
                    <button className="cross-button invisible">
                      <i className="fa fa-times" />
                    </button>
                  </h6>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                  style={{ border: "none", paddingTop: 3 }}
                >
                  <div>
                    <small className="text-muted">
                      <Skeleton width={200} />
                    </small>
                  </div>
                  <div>
                    <small className="text-grey fw-light">
                      <span style={{ textDecoration: "line-through" }}>
                        <Skeleton width={100} />
                      </span>
                      <button className="cross-button invisible">
                        <i className="fa fa-times" />
                      </button>
                    </small>
                  </div>
                </li>
              </div>
            </>
          )}
        </div>
        {currentUrl === "/domain" ? (
          <>
            {bypasschk === "bypass" && packageCart.length !== 0 ? (
              <Link
                style={{
                  color: "rgb(255, 255, 255)",
                  textDecoration: "auto",
                }}
                to="/get-addons"
              >
                <button type="submit" className="cart-botton text mb-0 mt-4">
                  CONTINUE
                </button>
              </Link>
            ) : (
              <>
                {(domainSearchCart && domainSearchCart.length !== 0) ||
                (packageCart && packageCart.length !== 0) ? (
                  <button
                    type="submit"
                    onClick={(e) => handelContinue(e)}
                    className="cart-botton text mb-0 mt-4"
                  >
                    CONTINUE
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="cart-botton text mb-0 mt-4 pacakgenotAllowed"
                  >
                    CONTINUE
                  </button>
                )}
              </>
            )}
          </>
        ) : null}
        {currentUrl === "/get-hosting" ? (
          <>
            {packageCart.length === 0 && domainSearchCart.length === 0 ? (
              <>
                <button
                  type="submit"
                  className="cart-botton text mb-0 sm-mt-1 mt-4 pacakgenotAllowed"
                  disabled
                >
                  CONTINUE
                </button>
              </>
            ) : (
              <>
                {packageCart.length !== 0 ? (
                  <>
                    {" "}
                    <Link
                      style={{
                        color: "rgb(255, 255, 255)",
                        textDecoration: "auto",
                      }}
                      to="/get-addons"
                    >
                      <button
                        type="submit"
                        className="cart-botton text mb-0 sm-mt-1 mt-4"
                      >
                        CONTINUE
                      </button>
                    </Link>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <Link
                      style={{
                        color: "rgb(255, 255, 255)",
                        textDecoration: "auto",
                      }}
                      to="/cart"
                    >
                      <button
                        type="submit"
                        className="cart-botton text mb-0 sm-mt-1 mt-4"
                      >
                        CONTINUE
                      </button>
                    </Link>
                  </>
                )}
              </>
            )}
          </>
        ) : null}
        {currentUrl === "/get-addons" ? (
          <>
            {packageCart &&
            packageCart.length === 0 &&
            domainSearchCart.length === 0 ? (
              <>
                {" "}
                <button
                  type="submit"
                  className="cart-botton text mb-0 sm-mt-1 mt-4 pacakgenotAllowed"
                  disabled
                >
                  CONTINUE
                </button>
              </>
            ) : (
              <button
                onClick={(e) => handelcontineAddons(e)}
                type="submit"
                className="cart-botton text mb-0 mt-4"
              >
                CONTINUE
              </button>
            )}
          </>
        ) : null}
        {currentUrl === "/dedicated-hostname" ? (
          <>
            <button
              id="mmm"
              type="submit"
              className={
                document?.getElementById("dedicated_check_search")?.value !==
                  "" && packageCart.length !== 0
                  ? "cart-botton text mb-0 mt-4"
                  : "cart-botton text mb-0 mt-4 pacakgenotAllowed"
              }
              onClick={(e) => handelExitingDomain(e)}
            >
              {/* <Link
            style={{
              color: "rgb(255, 255, 255)",
              textDecoration: "auto",
            }}
            to="/get-addons"
          > */}
              CONTINUE
              {/* </Link> */}
            </button>
          </>
        ) : null}

        {currentUrl === "/dedicated-ssl-hostname" ? (
          <>
            <button
              id="mmm"
              type="submit"
              className={
                document?.getElementById("dedicated_check_search")?.value !==
                  "" && packageCart.length !== 0
                  ? "cart-botton text mb-0 mt-4"
                  : "cart-botton text mb-0 mt-4 pacakgenotAllowed"
              }
              onClick={(e) => handelExitingSSLDomain(e)}
            >
              {/* <Link
            style={{
              color: "rgb(255, 255, 255)",
              textDecoration: "auto",
            }}
            to="/get-addons"
          > */}
              CONTINUE
              {/* </Link> */}
            </button>
          </>
        ) : null}

        {/* {currentUrl === "/free-domain" ? (
          <>
            {packageCart &&
            packageCart[0]?.freedomain?.length === 0 &&
            domainNameClient === "" ? (
              <button
                type="submit"
                className="cart-botton text mb-0 mt-4 pacakgenotAllowed"
              >
                CONTINUE
              </button>
            ) : (
              <button
                onClick={(e) => handelContinueWonFree(e)}
                type="submit"
                className="cart-botton text mb-0 mt-4"
              >
                CONTINUE
              </button>
            )}
          </>
        ) : null} */}
        {currentUrl === "/cart" ? (
          <>
            <button
              type="submit"
              onClick={() => navigate("/")}
              className="cart-button_secondary text mb-4 mt-4 invisible "
            >
              CONTINUE SHOPPING
            </button>
          </>
        ) : null}
      </div>

      <div className="d-block d-sm-block d-md-block d-lg-none">
        <div className="shopping-cart cart-container ">
          <div
            ref={domNode}
            className="panel panel-default cart-content-wrapper "
            style={{ padding: 0 }}
          >
            <div className="cart-header au-cart-header mobile clearfix">
              <div>
                <div
                  onClick={() => setisCartOpen(!isCartOpen)}
                  className="panel-heading cart-trigger au-cart-header-toggle"
                  role="button"
                >
                  {isCartOpen ? (
                    <>
                      <div className="label-and-price">
                        <h3 className="pull-left" aria-level={1}>
                          Shopping Cart
                        </h3>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="label-and-price">
                        <h3 className="pull-left" aria-level={1}>
                          Cart Total
                        </h3>
                        <span className="subtotal-price-label">
                          <span className="hidden-md hidden-lg">
                            {/* {currencyName.prefix} {totalCart} */}
                            {currencyName.api_id === 3
                              ? test2(formatter.format(totalCart)).split(".")[0]
                              : test2(formatter.format(totalCart))}
                          </span>
                        </span>
                      </div>
                    </>
                  )}

                  <i
                    onClick={() => setisCartOpen(!isCartOpen)}
                    className={
                      isCartOpen ? "fa fa-arrow-down" : "fa fa-arrow-up"
                    }
                    style={{ placeSelf: "center" }}
                  />
                </div>
                {isCartOpen ? null : (
                  <>
                    {" "}
                    {currentUrl === "/domain" ? (
                      <>
                        {bypasschk === "bypass" && packageCart.length !== 0 ? (
                          <Link
                            style={{
                              color: "rgb(255, 255, 255)",
                              textDecoration: "auto",
                            }}
                            to="/get-addons"
                          >
                            <button
                              type="submit"
                              className="cart-botton text mb-0 mt-1"
                            >
                              CONTINUE
                            </button>
                          </Link>
                        ) : (
                          <>
                            {(domainSearchCart &&
                              domainSearchCart.length !== 0) ||
                            (packageCart && packageCart.length !== 0) ? (
                              <button
                                type="submit"
                                onClick={(e) => handelContinue(e)}
                                className="cart-botton text mb-0 mt-1"
                              >
                                CONTINUE
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="cart-botton text pacakgenotAllowed mb-0 mt-1"
                              >
                                CONTINUE
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                    {currentUrl === "/get-hosting" ? (
                      <>
                        {packageCart.length === 0 &&
                        domainSearchCart.length === 0 ? (
                          <>
                            <button
                              type="submit"
                              className="cart-botton text mb-0 mt-1 pacakgenotAllowed"
                            >
                              CONTINUE
                            </button>
                          </>
                        ) : (
                          <>
                            {packageCart.length !== 0 ? (
                              <>
                                {" "}
                                <Link
                                  style={{
                                    color: "rgb(255, 255, 255)",
                                    textDecoration: "auto",
                                  }}
                                  to="/get-addons"
                                >
                                  <button
                                    type="submit"
                                    className="cart-botton text mb-0 mt-1"
                                  >
                                    CONTINUE
                                  </button>
                                </Link>{" "}
                              </>
                            ) : (
                              <>
                                <Link
                                  style={{
                                    color: "rgb(255, 255, 255)",
                                    textDecoration: "auto",
                                  }}
                                  to="/cart"
                                >
                                  <button
                                    type="submit"
                                    className="cart-botton text mb-0 mt-1"
                                  >
                                    CONTINUE
                                  </button>
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                    {currentUrl === "/get-addons" ? (
                      <>
                        {packageCart &&
                        packageCart.length === 0 &&
                        domainSearchCart.length === 0 ? (
                          <>
                            {" "}
                            <button
                              type="submit"
                              className="cart-botton pacakgenotAllowed text mb-0 mt-1"
                            >
                              CONTINUE
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={(e) => handelcontineAddons(e)}
                              type="submit"
                              className="cart-botton text mb-0 mt-1"
                            >
                              CONTINUE
                            </button>
                          </>
                        )}
                      </>
                    ) : null}
                    {currentUrl === "/dedicated-hostname" ? (
                      <>
                        <button
                          id="mmm"
                          type="submit"
                          className={
                            document?.getElementById("dedicated_check_search")
                              ?.value !== "" && packageCart.length !== 0
                              ? "cart-botton text mb-0 "
                              : "cart-botton text mb-0  pacakgenotAllowed"
                          }
                          onClick={(e) => handelExitingDomain(e)}
                        >
                          {/* <Link
            style={{
              color: "rgb(255, 255, 255)",
              textDecoration: "auto",
            }}
            to="/get-addons"
          > */}
                          CONTINUE
                          {/* </Link> */}
                        </button>
                      </>
                    ) : null}
                    {currentUrl === "/dedicated-ssl-hostname" ? (
                      <>
                        <button
                          type="submit"
                          className={
                            document?.getElementById("dedicated_check_search")
                              ?.value !== "" && packageCart.length !== 0
                              ? "cart-botton text mb-0 "
                              : "cart-botton text mb-0  pacakgenotAllowed"
                          }
                          onClick={(e) => handelExitingSSLDomain(e)}
                        >
                          {/* <Link
                            style={{
                              color: "rgb(255, 255, 255)",
                              textDecoration: "auto",
                            }}
                            to="/get-addons"
                          > */}
                          CONTINUE
                          {/* </Link> */}
                        </button>
                      </>
                    ) : null}
                    {/* {currentUrl === "/free-domain" ? (
                      <>
                        {packageCart &&
                        packageCart[0]?.freedomain?.length === 0 &&
                        domainNameClient === "" ? (
                          <button
                            type="submit"
                            className="cart-botton text pacakgenotAllowed mb-0"
                          >
                            CONTINUE
                          </button>
                        ) : (
                          <button
                            onClick={(e) => handelContinueWonFree(e)}
                            type="submit"
                            className="cart-botton text mb-0"
                          >
                            CONTINUE
                          </button>
                        )}
                      </>
                    ) : null} */}
                    {currentUrl === "/cart" ? (
                      <>
                        <button
                          type="submit"
                          onClick={() => navigate("/")}
                          className="cart-button_secondary text mb-1 mt-1 invisible "
                        >
                          CONTINUE SHOPPING
                        </button>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div
              className={
                isCartOpen
                  ? "cart-list-container clearfix cartmbile-open"
                  : "cart-list-container clearfix cartmbile-off"
              }
            >
              <div
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                className="ReactCollapse--content"
              >
                {packageCart &&
                  packageCart.map((maping) => (
                    <>
                      <div className="list-group-item mb-3">
                        <li
                          className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                          style={{
                            fontWeight: 300,
                            border: "none",
                            margin: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <div
                            style={{ textTransform: "uppercase" }}
                            className="sm-w-50 maxwidth70"
                          >
                            {" "}
                            {maping.title}
                          </div>

                          {currencyName.api_id === 3 ? (
                            <>
                              {addingCycle &&
                                addingCycle
                                  .filter(
                                    (elss) => elss.packageId === maping.pid
                                  )
                                  .map((adc) => (
                                    <>
                                      <span className="text-grey fw-light text-end">
                                        {currencyName.prefix}
                                        {
                                          test2(
                                            getForCartPrice(
                                              getPackageBasePrice(
                                                packageCart,
                                                maping.pid,
                                                currencyName.api_id
                                              ),
                                              maping.pid,
                                              adc.packageCycle,
                                              getActivePromotions,
                                              maping.currency
                                                .filter(
                                                  (fil) =>
                                                    fil.currency ===
                                                    currencyName.api_id
                                                )
                                                .map(
                                                  (pr) => pr[adc.packageCycle]
                                                )
                                            )
                                          ).split(".")[0]
                                        }
                                        <button
                                          onClick={(e) =>
                                            handleDelete(e, maping)
                                          }
                                          className="cross-button"
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      </span>
                                    </>
                                  ))}
                            </>
                          ) : (
                            <>
                              {addingCycle &&
                                addingCycle
                                  .filter(
                                    (elss) => elss.packageId === maping.pid
                                  )
                                  .map((adc) => (
                                    <span className="text-grey fw-light text-end">
                                      {currencyName.prefix}
                                      {test2(
                                        getForCartPrice(
                                          getPackageBasePrice(
                                            packageCart,
                                            maping.pid,
                                            currencyName.api_id
                                          ),
                                          maping.pid,
                                          adc.packageCycle,
                                          getActivePromotions,
                                          maping.currency
                                            .filter(
                                              (fil) =>
                                                fil.currency ===
                                                currencyName.api_id
                                            )
                                            .map((pr) => pr[adc.packageCycle])
                                        )
                                      )}
                                      <button className="cross-button">
                                        <i className="fa fa-times" />
                                      </button>
                                    </span>
                                  ))}
                            </>
                          )}
                        </li>
                        <li
                          className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                          style={{ border: "none", paddingTop: 3 }}
                        >
                          <small>
                            <span className="text-muted">Hosting Category</span>
                          </small>
                        </li>

                        {maping &&
                          maping.addons &&
                          maping.addons.map((mapAddon) => (
                            <>
                              <li
                                className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                                style={{ border: "none" }}
                              >
                                <div className="sm-w-50">
                                  <h4
                                    className="cart-heading6 my-0 addonss"
                                    dangerouslySetInnerHTML={{
                                      __html: mapAddon.name,
                                    }}
                                  />
                                </div>
                                {addingCycle &&
                                  addingCycle
                                    .filter(
                                      (elss) => elss.packageId === maping.pid
                                    )
                                    .map((adc) => (
                                      <span className="text-grey fw-light">
                                        {currencyName.prefix}
                                        {test2(
                                          getAddonPriceForcart(
                                            adc.packageCycle,
                                            mapAddon,
                                            currencyName.api_id,
                                            mapAddon.pricing[
                                              currencyName.api_id
                                            ][adc.packageCycle]
                                          )
                                        )}
                                        <button
                                          onClick={(e) =>
                                            handelRemoveAddon(e, mapAddon)
                                          }
                                          className="cross-button"
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      </span>
                                    ))}
                              </li>
                            </>
                          ))}

                        {/* mobile section */}
                        {maping &&
                          maping.freedomain &&
                          maping.freedomain.map((mapfreedomain) => (
                            <>
                              <li
                                className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                                style={{
                                  fontWeight: 300,
                                  border: "none",
                                  margin: 0,
                                  paddingBottom: 0,
                                  textTransform: "uppercase",
                                }}
                              >
                                <div>
                                  {mapfreedomain.name === "domainregister"
                                    ? "Domain Registration"
                                    : "Domain Transfer"}
                                </div>

                                <h6
                                  style={{
                                    color: "#005880",
                                    textTransform: "capitalize",
                                    width: "25%",
                                    textAlign: "right",
                                    fontWeight: 400,
                                  }}
                                  className="my-0"
                                >
                                  Free
                                  <button className="cross-button invisible">
                                    <i className="fa fa-times" />
                                  </button>
                                </h6>
                              </li>
                              <li
                                className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                                style={{ border: "none", paddingTop: 3 }}
                              >
                                <div>
                                  <small className="text-muted">
                                    {mapfreedomain.domainName}
                                  </small>
                                </div>
                                <div>
                                  <small className="text-grey fw-light">
                                    {maping && currencyName.api_id === 3 ? (
                                      <>
                                        {addingCycle &&
                                          addingCycle
                                            .filter(
                                              (ast) =>
                                                ast.packageId === maping.pid
                                            )
                                            .map((adc) => (
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {currencyName.prefix}
                                                {
                                                  test2(
                                                    showFreedPrice(
                                                      adc,
                                                      maping,
                                                      3
                                                    )
                                                  ).split(".")[0]
                                                }
                                              </span>
                                            ))}
                                      </>
                                    ) : (
                                      <>
                                        {addingCycle &&
                                          addingCycle
                                            .filter(
                                              (ast) =>
                                                ast.packageId === maping.pid
                                            )
                                            .map((adc) => (
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {currencyName.prefix}
                                                {test2(
                                                  showFreedPrice(adc, maping, 1)
                                                )}
                                              </span>
                                            ))}
                                      </>
                                    )}
                                    <button className="cross-button invisible">
                                      <i className="fa fa-times" />
                                    </button>
                                  </small>
                                </div>
                              </li>
                            </>
                          ))}

                        <li
                          className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                          style={{ border: "none" }}
                        >
                          <select
                            className="form-select form-select-sm py-2"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setSelectedYear(e.target.value)}
                            value={packageBillingcycle(addingCycle, maping.tid)}
                          >
                            {packageCart &&
                            packageCart
                              .filter((p) => p.tid === maping.tid)[0]
                              .currency.filter(
                                (c) => c.currency == currencyName.api_id
                              )[0].monthly > 0 ? (
                              <>
                                <option value={`monthly.${maping.tid}`}>
                                  Monthly
                                </option>
                              </>
                            ) : null}
                            {packageCart &&
                            packageCart
                              .filter((p) => p.tid === maping.tid)[0]
                              .currency.filter(
                                (c) => c.currency == currencyName.api_id
                              )[0].quarterly > 0 ? (
                              <>
                                <option value={`quarterly.${maping.tid}`}>
                                  Quarterly
                                </option>
                              </>
                            ) : null}
                            {packageCart &&
                            packageCart
                              .filter((p) => p.tid === maping.tid)[0]
                              .currency.filter(
                                (c) => c.currency == currencyName.api_id
                              )[0].semiannually > 0 ? (
                              <>
                                <option value={`semiannually.${maping.tid}`}>
                                  Semiannually
                                </option>
                              </>
                            ) : null}

                            {packageCart &&
                            packageCart
                              .filter((p) => p.tid === maping.tid)[0]
                              .currency.filter(
                                (c) => c.currency == currencyName.api_id
                              )[0].annually > 0 ? (
                              <>
                                <option value={`annually.${maping.tid}`}>
                                  1 Year
                                </option>
                              </>
                            ) : null}
                            {packageCart &&
                            packageCart
                              .filter((p) => p.tid === maping.tid)[0]
                              .currency.filter(
                                (c) => c.currency == currencyName.api_id
                              )[0].biennially > 0 ? (
                              <>
                                <option value={`biennially.${maping.tid}`}>
                                  2 Year
                                </option>
                              </>
                            ) : null}
                            {packageCart &&
                            packageCart
                              .filter((p) => p.tid === maping.tid)[0]
                              .currency.filter(
                                (c) => c.currency == currencyName.api_id
                              )[0].triennially > 0 ? (
                              <>
                                <option value={`triennially.${maping.tid}`}>
                                  3 Year
                                </option>
                              </>
                            ) : null}
                          </select>
                        </li>
                      </div>
                    </>
                  ))}

                {domainSearchCart &&
                  domainSearchCart.map((domaincart) => (
                    <div className="list-group-item mb-3">
                      <>
                        <>
                          <li
                            className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                            style={{
                              fontWeight: 300,
                              border: "none",
                              margin: 0,
                              paddingBottom: 0,
                              textTransform: "uppercase",
                            }}
                          >
                            {domaincart.name === "domainregister"
                              ? "Domain Registration"
                              : null}
                          </li>

                          <li
                            className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                            style={{ border: "none", paddingTop: 3 }}
                          >
                            <div>
                              <small className="text-muted">
                                {domaincart.domainName}
                              </small>
                            </div>
                            {addingCycleDomain &&
                              addingCycleDomain
                                .filter(
                                  (eks) =>
                                    eks.domainName === domaincart.domainName
                                )
                                .map((adccc) => (
                                  <span className="text-grey fw-light text-end">
                                    {currencyName.prefix}
                                    {currencyName.api_id === 3 ? (
                                      <>
                                        {
                                          test2(
                                            // domaincart.price[0][
                                            //   adccc.packageCycle
                                            // ]
                                            domaincart.price.find(
                                              (el) => el.currency === 3
                                            )[adccc.packageCycle]
                                          ).split(".")[0]
                                        }
                                        <button
                                          onClick={(e) =>
                                            handelDomainDelete(e, domaincart)
                                          }
                                          className="cross-button"
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        {test2(
                                          // domaincart.price[1][adccc.packageCycle]
                                          domaincart.price.find(
                                            (el) => el.currency === 1
                                          )[adccc.packageCycle]
                                        )}
                                        <button
                                          onClick={(e) =>
                                            handelDomainDelete(e, domaincart)
                                          }
                                          className="cross-button"
                                        >
                                          <i className="fa fa-times" />
                                        </button>
                                      </>
                                    )}
                                  </span>
                                ))}
                          </li>
                          {domainSearchCart &&
                          domainSearchCart.length === 0 ? null : (
                            <>
                              {domaincart.domainAddon === undefined ||
                              domaincart.domainAddon.length === 0 ? (
                                ""
                              ) : (
                                <li
                                  className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                                  style={{ border: "none" }}
                                >
                                  <div>
                                    <h4 className="cart-heading6 my-0 addonss">
                                      {domaincart.domainAddonTitle}
                                    </h4>
                                  </div>
                                  {addingCycleDomain &&
                                    addingCycleDomain
                                      .filter(
                                        (eks) =>
                                          eks.domainName ===
                                          domaincart.domainName
                                      )
                                      .map((adccc) => (
                                        <span className="text-grey fw-light">
                                          {currencyName.api_id === 3 ? (
                                            <>
                                              {currencyName.prefix}
                                              {
                                                test2(
                                                  domainAdonPrice(
                                                    adccc.packageCycle,

                                                    domaincart.domainAddon[1]
                                                      .ssetupfee
                                                  )
                                                ).split(".")[0]
                                              }
                                              <button
                                                className="cross-button"
                                                onClick={(e) =>
                                                  handelIdDelete(e, domaincart)
                                                }
                                              >
                                                <i className="fa fa-times" />
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {currencyName.prefix}
                                              {test2(
                                                domainAdonPrice(
                                                  adccc.packageCycle,

                                                  domaincart.domainAddon[0]
                                                    .ssetupfee
                                                )
                                              )}
                                              <button
                                                className="cross-button"
                                                onClick={(e) =>
                                                  handelIdDelete(e, domaincart)
                                                }
                                              >
                                                <i className="fa fa-times" />
                                              </button>
                                            </>
                                          )}
                                        </span>
                                      ))}
                                </li>
                              )}
                            </>
                          )}

                          <li
                            className="list-group-item d-flex justify-content-between lh-sm psetiingcart"
                            style={{ border: "none" }}
                          >
                            <select
                              className="form-select form-select-sm py-2"
                              aria-label=".form-select-sm example"
                              value={domainBillingcycle(
                                addingCycleDomain,
                                domaincart.domainName
                              )}
                              onChange={(e) =>
                                setDomainSelectedYear(e.target.value)
                              }
                            >
                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).annually !== "" ? (
                                <option
                                  value={`annually_${domaincart.domainName}`}
                                >
                                  1 Year
                                </option>
                              ) : null}
                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).biennially !== "" ? (
                                <option
                                  value={`biennially_${domaincart.domainName}`}
                                >
                                  2 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).triennially !== "" ? (
                                <option
                                  value={`triennially_${domaincart.domainName}`}
                                >
                                  3 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).fourlyprice !== "" ? (
                                <option
                                  value={`fourlyprice_${domaincart.domainName}`}
                                >
                                  4 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).fivelyprice !== "" ? (
                                <option
                                  value={`fivelyprice_${domaincart.domainName}`}
                                >
                                  5 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).sixlyprice !== "" ? (
                                <option
                                  value={`sixlyprice_${domaincart.domainName}`}
                                >
                                  6 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).sevenlyprice !== "" ? (
                                <option
                                  value={`sevenlyprice_${domaincart.domainName}`}
                                >
                                  7 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).eightlyprice !== "" ? (
                                <option
                                  value={`eightlyprice_${domaincart.domainName}`}
                                >
                                  8 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).ninelyprice !== "" ? (
                                <option
                                  value={`ninelyprice_${domaincart.domainName}`}
                                >
                                  9 Years
                                </option>
                              ) : null}

                              {domaincart &&
                              domaincart.price &&
                              domaincart.price.find(
                                (el) => el.currency === currencyName.api_id
                              ).tenlyprice !== "" ? (
                                <option
                                  value={`tenlyprice_${domaincart.domainName}`}
                                >
                                  10 Years
                                </option>
                              ) : null}
                            </select>
                          </li>
                        </>
                      </>
                    </div>
                  ))}
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "0.5rem 1rem",
                  alignContent: "space-around",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: 12 }}>
                  {" "}
                  Cart Total (including GST {currencyName.prefix}
                  {currencyName.api_id == 3
                    ? test2(formatter.format(gstPrice)).split(".")[0]
                    : test2(formatter.format(gstPrice))}
                  )
                </p>
                <p style={{ fontSize: 12 }}>
                  {currencyName.prefix}
                  {currencyName.api_id === 3
                    ? test2(formatter.format(totalCart)).split(".")[0]
                    : test2(formatter.format(totalCart))}{" "}
                </p>
              </div>

              {/* mobile cart buttons */}

              {currentUrl === "/domain" ? (
                <>
                  {bypasschk &&
                  bypasschk === "bypass" &&
                  packageCart.length !== 0 ? (
                    <Link
                      style={{
                        color: "rgb(255, 255, 255)",
                        textDecoration: "auto",
                      }}
                      to="/get-addons"
                    >
                      <button
                        type="submit"
                        className="cart-botton text mb-0 mt-1"
                      >
                        CONTINUE
                      </button>
                    </Link>
                  ) : (
                    <>
                      {(domainSearchCart && domainSearchCart.length !== 0) ||
                      (packageCart && packageCart.length !== 0) ? (
                        <button
                          type="submit"
                          onClick={(e) => handelContinue(e)}
                          className="cart-botton text mb-0 mt-1"
                        >
                          CONTINUE
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="cart-botton text pacakgenotAllowed mb-0 mt-1"
                        >
                          CONTINUE
                        </button>
                      )}
                    </>
                  )}
                </>
              ) : null}
              {currentUrl === "/get-hosting" ? (
                <>
                  {packageCart.length === 0 && domainSearchCart.length === 0 ? (
                    <>
                      <button
                        type="submit"
                        className="cart-botton pacakgenotAllowed text mb-0 mt-1"
                        disabled
                      >
                        CONTINUE
                      </button>
                    </>
                  ) : (
                    <>
                      {packageCart.length !== 0 ? (
                        <>
                          {" "}
                          <Link
                            style={{
                              color: "rgb(255, 255, 255)",
                              textDecoration: "auto",
                            }}
                            to="/get-addons"
                          >
                            <button
                              type="submit"
                              className="cart-botton text mb-0 mt-1"
                            >
                              CONTINUE
                            </button>
                          </Link>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <Link
                            style={{
                              color: "rgb(255, 255, 255)",
                              textDecoration: "auto",
                            }}
                            to="/cart"
                          >
                            <button
                              type="submit"
                              className="cart-botton text mb-0 mt-1"
                            >
                              CONTINUE
                            </button>
                          </Link>{" "}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : null}
              {/* {packageCart &&
              packageCart.length === 0 &&
              domainSearchCart.length === 0 ? (
                <>
                  {packageCart && packageCart.length === 0 ? (
                    <>
                      {" "}
                      <button
                        type="submit"
                        className="cart-botton pacakgenotAllowed text mb-0 mt-1"
                      >
                        CONTINUE
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={(e) => handelcontineAddons(e)}
                        type="submit"
                        className="cart-botton text mb-0 mt-1"
                      >
                        CONTINUE
                      </button>
                    </>
                  )}
                </>
              ) : null} */}
              {currentUrl === "/dedicated-hostname" ? (
                <>
                  <button
                    id="mmm"
                    type="submit"
                    className={
                      document?.getElementById("dedicated_check_search")
                        ?.value !== "" && packageCart.length !== 0
                        ? "cart-botton text mb-0 "
                        : "cart-botton text mb-0  pacakgenotAllowed"
                    }
                    onClick={(e) => handelExitingDomain(e)}
                  >
                    {/* <Link
            style={{
              color: "rgb(255, 255, 255)",
              textDecoration: "auto",
            }}
            to="/get-addons"
          > */}
                    CONTINUE
                    {/* </Link> */}
                  </button>
                </>
              ) : null}

              {currentUrl === "/dedicated-ssl-hostname" ? (
                <>
                  <button
                    type="submit"
                    className={
                      document?.getElementById("dedicated_check_search")
                        ?.value !== "" && packageCart.length !== 0
                        ? "cart-botton text mb-0 "
                        : "cart-botton text mb-0  pacakgenotAllowed"
                    }
                    onClick={(e) => handelExitingSSLDomain(e)}
                  >
                    {/* <Link
                      style={{
                        color: "rgb(255, 255, 255)",
                        textDecoration: "auto",
                      }}
                      to="/get-addons"
                    > */}
                    CONTINUE
                    {/* </Link> */}
                  </button>
                </>
              ) : null}

              {/* {currentUrl === "/free-domain" ? (
                <>
                  {packageCart &&
                  packageCart.length === 0 &&
                  domainNameClient === "" ? (
                    <button
                      type="submit"
                      className="cart-botton pacakgenotAllowed text mb-0"
                    >
                      CONTINUE
                    </button>
                  ) : (
                    <button
                      onClick={(e) => handelContinueWonFree(e)}
                      type="submit"
                      className="cart-botton text mb-0"
                    >
                      CONTINUE
                    </button>
                  )}
                </>
              ) : null} */}
              {currentUrl === "/cart" ? (
                <>
                  <button
                    type="submit"
                    onClick={() => navigate("/")}
                    className="cart-button_secondary text mb-1 mt-1 invisible "
                  >
                    CONTINUE SHOPPING
                  </button>
                </>
              ) : null}

              {/* mobile cart button ends */}
            </div>
            <div className="cart-footer hidden">
              <div className="price domain-cart-total">
                <p className="total clearfix">
                  <span className="total-label au-total-label">Total</span>
                  <span className="total pull-right au-total" id="cart-total">
                    {currencyName.prefix}
                    {totalCart}
                  </span>
                </p>
              </div>
              <div>
                <Link
                  to="#"
                  style={{ margin: 0 }}
                  className=" 
        proceed-to-billing-button btn btn-primary btn-block au-proceed-to-billing-button
         not-disabled
         hidden-xs hidden-sm
      "
                  role="button"
                  tabIndex={0}
                >
                  Proceed
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="domain-cart-overlay" />
      </div>
    </>
  );
};

export default CartOrder;
