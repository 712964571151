import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
import AllPlanIncludes from "./includes/AllPlanIncludes";
import BundelBooster from "./includes/BundelBooster";
import ComparePlans from "../../reuseable/ComparePlans";
import DomainTips from "./includes/DomainTips";
import GoingOnline from "./includes/GoingOnline";
import WhyChooseUsThird from "../../reuseable/WhyChooseUsThird";
import DomainMigration from "./includes/DomainMigration";
import InPakistan from "./includes/InPakistan";
import Faq from "../../reuseable/Faq";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Crousels from "../../reuseable/Crousels";

const ServerManagement = () => {
  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Effective Server Management that Keeps Stress Miles Away",
    Box: [
      {
        heading: "Regular Server Updates",
        subHeading: "",
        icon: "icon ws-updates",
      },
      {
        heading: "Security Monitoring",
        subHeading: "",
        icon: "icon ws-monitor-b",
      },
      {
        heading: "Hardware Fault Identification",
        subHeading: "",
        icon: "icon ws-fault",
      },
      {
        heading: "Automated Backups",
        subHeading: "",
        icon: "icon ws-backups",
      },
      {
        heading: "Downtime Response",
        subHeading: "",
        icon: "icon ws-time-b",
      },
      {
        heading: "Emails Deliverability",
        subHeading: "",
        icon: "icon ws-email",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        bundleHeading: "Advance Monitoring",
        bundleParagh:
          "We continuously monitor our clients’ server’s availability and performance in order to ensure 99.9% uptime and optimal performance of their server by tracking the server load with our proactive monitoring system. ",
        bundleImage: "assests/img/Server-Advance-Monitoring.png",
        bundleId: "AdvanceMonitoring-tab",
        bundleDataTarget: "#AdvanceMonitoring",
        bundleAriaControl: "AdvanceMonitoring",
        bundleParaghId: "AdvanceMonitoring",
        bundleParaghAriaLable: "AdvanceMonitoring-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        bundleHeading: "Quick Fixes",
        bundleParagh:
          "Offering to resolve our clients’ server related issues by providing quick fixes for all the common maintenance hitches, including complete server setup, load mitigation service, server migration, and spamming kills.",
        bundleImage: "assests/img/Quick-Fixes.png",
        bundleId: "Quick-Fixes-tab",
        bundleDataTarget: "#Quick-Fixes",
        bundleAriaControl: "Quick-Fixes",
        bundleParaghId: "Quick-Fixes",
        bundleParaghAriaLable: "Quick-Fixes-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title:
          "Can you manage and secure my servers if I’m not hosting them at WebSouls?",
        desc: "Of course, we can! We offer quality server management and security to all our clients even if they’re not hosting their website with us.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Which server management plan should I choose?",
        desc: "The server management plan that you choose may depend upon whether you want us to take primary or complete responsibility of your server. To be able to decide, have a look at our packages and the services they include, or simply contact our sales representatives for thorough guidance.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Do you provide Linux server management and security?",
        desc: "Yes! We provide management and security services for a variety of operating systems including Linux.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Do you provide Windows server management and security?",
        desc: "Yes, we do provide server management and security services for Windows and make sure to deliver the best at all times.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/server-management-willing-for-help.png",
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "Hosting Plans Fueled With the Latest Technology",
    box: [
      {
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        icon: "icon ws-cpanel",
        title: "cPanel Official Partner",
      },
      {
        icon: "icon ws-clients",
        title: "1000+ Corporate Clients",
      },
      {
        icon: "icon ws-scripts",
        title: "Bash Scripts",
      },
      {
        icon: "icon ws-email-c",
        title: "Smooth Email Service",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        icon: "icon ws-setup",
        title: "New Server Setup",
        peragraph:
          "All our server management plans come with a complete initial server setup that our expert team would perform without any additional charges.",
      },
      {
        icon: "icon ws-search",
        title: "Server Hardening",
        peragraph:
          "It’s a wild web out there, but we can keep you safe with our up-to-date security scripts and also by performing system tweaks whenever needed.",
      },
      {
        icon: "icon ws-optimize",
        title: "Server Optimization",
        peragraph:
          "A slow website can be the worst nightmare. We can help you keep your visitors happy by optimizing your server for speed and better performance.",
      },
      {
        icon: "icon ws-audit",
        title: "Server Security Audit",
        peragraph:
          "Our server support team can perform nightly scans on your server by configuring rkhunter and maldet malware scanner for fool proof security",
      },
      {
        icon: "icon ws-migrate",
        title: "Server Migration",
        peragraph:
          "We offer quick and easy server migration whenever you need it. Our experts can transfer all your server data and get you on board in no time.",
      },
      {
        icon: "icon ws-recycle",
        title: "Server Hack Recovery",
        peragraph:
          "We’d repair your hacked server to make it completely clean and free of malware. Our team would ensure that all your data is fully secure again.",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Server Management in Pakistan",
    subHeading: "",
    peragraph: [
      <>
        <p>
          We are Pakistan’s no. 1 web hosting company, providing our valued
          customers with server management services that are focused on their
          specific needs. Our packages are designed specifically to deliver
          everything our clients require to keep them completely stress-free.
        </p>
        <p>
          At Websouls, we offer managed web hosting and dedicated server
          management services that come with quality customer support, downtime
          response, security monitoring, and regular server updates.
        </p>
        <p>
          To avail our affordable yet up-to-the-mark server management services
          in Lahore, Karachi, Islamabad, or any other city in Pakistan, simply
          contact us through a phone call. You can also get in touch with us via
          email, live chat, or by visiting one of our offices for professional
          consultation. Helping our customers succeed is always on the top of
          our to-do list!
        </p>
      </>,
    ],
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading: "We Can Manage All Your Needs Effectively",
    box: [
      {
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        topTitle: "Need Something Simpler?  ",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };

  const [pidsss] = useState([1, 125, 126]);
  const [comparePlansState, setComparePlansState] = useState([]);
  const [packages, setPackages] = useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);

  useEffect(() => {
    GetPackages(pidsss).then((res) => {
      setComparePlansState(res);
      setPackages(res);
      GetActivePromotions().then((resp) => {
        setActivePromotions(resp);
      });
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Web Server Management | Web Management Service | WebSouls</title>
        <meta
          name="title"
          content="Web Server Management | Web Management Service | WebSouls"
        />
        <meta
          name="description"
          content="Experience best in class server management services by WebSouls team of technical professionals. Our active support available to monitor & manage your web servers that keeps your stress miles away."
        />
        <meta name="keywords" content="" />
      </Helmet>
      {/* <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">No More “Not Secure”</h2>
            <h5 className="second-heading">
              Win the Hearts of Your Customers with a Trustworthy Site
            </h5>
            <hr className="divider scnd_divider mx-auto  mb-5" />
            <Crousels
              packages={packages}
              getActivePromotions={getActivePromotions}
            />
          </div>
        </div>
      </section> */}
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <DomainTips Data={technicalSpecificationsData} />
      <ComparePlans comparePlansState={comparePlansState} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default ServerManagement;
