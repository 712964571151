import React from 'react'

const BundelBooster2 = ({Data}) => {
  return (
    <section className="v2-bundler-booster  pt-100">
    <div className="container">
      <h2 className="text-center first-heading">{Data?.Title}</h2>
      <h5 className="text-center second-heading">{Data?.subTitle}</h5>
      <hr className="divider scnd_divider mx-auto" />
      <div className="table mb-50">
        <div className="bg text-center">{Data?.Heading1}</div>
        <table className="table">
          <tbody>
            {Data?.LicensesItems?.map((el) => (
              <tr className="detial-order">
                <td>{el?.name}</td>
                <td className="text-center ">{el?.price}</td>
                <td className="text-end">
                  <a href={el?.Blink}>
                    <button>Contact Sales</button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table mb-50">
        <div className="bg text-center">{Data?.Heading2}</div>
        <table className="table">
          <tbody>
            {Data?.LicensesManagedSupport?.map((el) => (
              <tr className="detial-order">
                <td>{el?.name}</td>
                <td className="text-start ">{el?.price}</td>
                <td className="text-end">
                  <a href={el?.Blink}>
                    <button>Contact Sales</button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Table Licence Ends here */}
    </div>
  </section>
  )
}

export default React.memo(BundelBooster2)