import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { SSOToken } from "../../services/Network.js";
const Thankyou = () => {
  const { currencyName } = useSelector((state) => state.currencyName);

  const location = useLocation();
  const navigate = useNavigate();
  const [paymentmethod, setpaymentmethod] = useState("");
  const [order_id, setorder_id] = useState("");
  const [invoice_id, setinvoice_id] = useState("");
  const [fullName, setifullName] = useState("");
  const [order_total, setorderTotal] = useState("");
  const clientIDs = localStorage.getItem("client_id");
  useEffect(() => {

    window.dataLayer.push({
      event: "purchase_event"
  })

    // if (location.key === "default") {
    //   navigate("/");
    // }
    setpaymentmethod(location.state?.paymentmethod);
    setorder_id(location.state?.order_id);
    setinvoice_id(location.state?.invoice_id);
    setifullName(location.state?.fullNmae);
    setorderTotal(location.state?.order_total);
    if (
      location.state?.paymentmethod === "jazzcashgateway" ||
      location.state?.paymentmethod === "paypalcheckout"
    ) {
      setTimeout(() => {
        SSOToken(clientIDs, location.state?.invoice_id).then((resss) => {
          //console.log("result ",resss);
          //return;
          localStorage.setItem("ssco", resss.access_token);
          localStorage.removeItem("ssco");
          localStorage.removeItem("total");
          window.location.replace(resss.redirect_url);
        });
      }, 1000);
    }
  }, []);

  const GoBack = () => {
    navigate("/");
    window.location.reload();
  };
  let data = "";
  if (
    paymentmethod === "jazzcashgateway" ||
    paymentmethod === "paypalcheckout"
  ) {
    data = (
      <>
        <div className="row text-center justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <h2 className="first-heading mt-5">Thank you {fullName}!</h2>
            <p className="ordersub">Your order has been submitted.</p>
            <button className="submitammiunt mt-3">
              Total due amount is{" "}
              <span id="trackids">
                {currencyName.prefix}
                {order_total?.split(".")[0]}
              </span>
              /-
            </button>
            <p className="mt-3 mb-5 ordersub">
              Please wait, redirecting you to make payment...
            </p>
          </div>
        </div>
      </>
    );
  }

  if (
    paymentmethod === "cash" ||
    paymentmethod === "paymateau" ||
    paymentmethod === "banktransfer"
  ) {
    data = (
      <>
        <div className="row text-center justify-content-center">
          <div
            className="col-xs-12 col-sm-12 col-md-6"
            style={{ textAlign: "-webkit-center" }}
          >
            <h2 className="first-heading mt-5">Thank you {fullName}!</h2>
            <p className="ordersub">Your order has been submitted.</p>
            <p className="  pt-4">Your order number is {order_id}</p>
            <p className="">Your invoice number is {invoice_id}</p>
            <button className="submitammiunt mt-4">
              Total due amount is{" "}
              <span id="trackids">
                {currencyName.prefix}
                {order_total?.split(".")[0]}
              </span>
              /-
            </button>
            <h4
              style={{
                color: "#005880",
                fontWeight: "700",
                paddingTop: "35px",
              }}
            >
              Pay with Online Banking
            </h4>
            <p style={{ width: "54%" }} className="pt-3">
              HBL: WebSouls, Habib Bank Ltd., A/C No. 01957900259103, Cavalry
              Ground Branch, Lahore (Code 0195)
            </p>
            <p style={{ width: "70%" }} className="pt-3">
              MCB: WebSouls, Muslim Commercial Bank Ltd., A/C No.
              0140101010013446, Defence Branch, Lahore.
            </p>
            <p className="pt-3 mb-5">
              Note: Please send us transaction snapshot at{" "}
              <a href="mailto:sales@websouls.com">sales@websouls.com</a>
            </p>
          </div>
        </div>
      </>
    );
  }

  if (location.state?.paymentmethod === "stripe") {
    data = (
      <>
        <div className="row text-center justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <h2 className="first-heading mt-5">Thank you {fullName}!</h2>
            <p className="ordersub">
              Y​our payment has been processed successfully.
            </p>
            <p className="mt-3 mb-5 ordersub">
              Once the order is completed, you will receive a confirmation email
              with all details.
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Domain Registration in Pakistan | Buy Domain in Pakistan | WebSouls
        </title>


      </Helmet>

      <div className="container">
        
        {
          location.key === "default" ?  <div className="row text-center justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <h2 className="first-heading mt-5">Oops!!</h2>
            <p className="ordersub">
            It seems like you didn't processed an order. <br />
            To process an order, please click on "Continue Shopping"
            </p>
            <p className="mt-3 pt-3 mb-5 ordersub">
            <Link  to="/" className="we_are_hiring"><span style={{ padding: "15px 13px" }} className="celltext button_shape">Contine Shopping</span></Link>
            </p>
          </div>
        </div> : <>{data}</>
        }
        
        </div>
    </>
  );
};

export default Thankyou;
