import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
  getdedicatedPrice,
  delayTime,
  getPackageBaseCycle,
} from "../../../services/Helper";
import WhyChooseUs from "./includes/Vps/WhyChooseUs";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Faq from "../../reuseable/Faq";
import InPakistan from "./includes/Vps/InPakistan";
import Carousel from "react-elastic-carousel";
import WhyItWoth from "./includes/Vps/WhyItWoth";
import GoingOnline from "./includes/Vps/GoingOnline";
import DomainTips from "./includes/Vps/DomainTips";
import BundelBooster2 from "./includes/Vps/BundelBooster2";
import CustomizeServer from "./includes/Vps/CustomizeServer";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
const Vps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [linuxPids, setLiniuxPids] = useState([323, 324, 325, 326]);
  const [winodwsPids, setWindowsPids] = useState([327, 328, 329, 330]);

  const [statusOne, setStatusOne] = useState(true);
  const [statusTwo, setStatusTwo] = useState(true);

  const [vpsHostingLinuxList, setVpsHostingLinuxList] = useState([]);
  const [vpsHostingWindowsList, setVpsHostingWindowsList] = useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(false);

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title:
          "How is managed VPS different from unmanaged VPS? Which one should I get?",
        desc: "For unmanaged VPS, you’d need to have an advanced level of technical knowledge, as well as a basic understanding of Linux, since you’d be configuring and updating your VPS hosting yourself. On the other hand, managed hosting is a piece of cake for you even if you aren’t familiar with the tech stuff, because the WebSouls team would be doing all the heavy lifting for you.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Do I need to have prior technical knowledge for VPS hosting?",
        desc: "Yes, technical knowledge is required to manage a VPS server. Since this hosting plan offers more power and flexibility than the standard shared hosting, its server management is equally complex. It must be noted here that all our VPS hosting packages are unmanaged by default, however, you can go for a managed VPS hosting plan for more ease and simplicity.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Do you provide Windows VPS hosting?",
        desc: "Yes! we do offer Windows VPS hosting with 4 GB to 32 GB RAM. Also, when it comes to WordPress website management, you can also add Plesk Obsidian Webhost Edition to create and manage your websites with ease.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Does a VPS hosting plan include backups?",
        desc: "Yes, we offer automated weekly backup as one of our Bundle Boosters for VPS hosting plans. Plus, you can also create a backup on demand whenever needed.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "/assests/img/Team-Websouls-Gulraiz-Khan.png",
  };

  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
    delayTime(4000).then(() => {
      GetPackages(linuxPids).then((res) => {
        setVpsHostingLinuxList(res);
        setLoading(true);
        delayTime(5000).then(() => {
          GetPackages(winodwsPids).then((respo) => {
            setVpsHostingWindowsList(respo);
            setLoading(true);
            GetActivePromotions().then((ress) => {
              setActivePromotions(ress);
            });
          });
        });
      });
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: getPackageBaseCycle(item, currencyName.api_id),
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/dedicated-hostname");
  };

  const InPakistanData = {
    Heading: "VPS Hosting in Pakistan",
    subHeading: "Secure, Reliable, and Incredibly Fast VPS Servers",
    peragraph: [
      <>
        <p>
          Considering the fact that we are one of the leading 
          <Link to="/business-hosting">web hosting providers in Pakistan</Link>,
          our Virtual Private Servers offer the best security and are
          ultra-reliable, with an uptime that exceeds 99.9%. Buying VPS hosting
          from us and letting us host your website/s means you’d get undeniably
          remarkable power, high flexibility, and all the control needed to keep
          your website running at its best, and that too at cheap price rates!
        </p>
        <p>
          In addition to our top-quality windows and Linux VPS hosting services
          that come with flexible server configurations, we can also help in
          setting up and maintaining your server. For that, we recommend you to
          buy our managed
          <strong style={{ color: "grey" }}>VPS hosting package.</strong>
        </p>
        <p>
          Moreover, with the advanced functionality, powerful hardware, and
          automated weekly backups that our&nbsp;
          <strong style={{ color: "grey" }}>
            virtual server hosting provides,
          </strong>
          &nbsp; we help our clients enjoy optimal performance online, and a
          blazing-fast speed that allows unlimited web traffic for unhindered
          businesses growth.
        </p>
        <p>
          How to get in touch? Our exceptionally dedicated and professional 
          <Link to="/">WebSouls</Link> hosting experts can assist you in the
          best manner at our offices in Karachi and Lahore. Other than that, you
          can also reach out to us and send in your queries via live chat,
          email, or phone call. It won’t take long before we get back to you
          with low-cost cloud web hosting solutions focused on your needs.
        </p>
      </>,
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-performance",
        title: "Performance",
        peragraph:
          "Our VPS hosting plans are based on powerful hardware in combination with the latest technology to provide our clients with ultimate power and performance.",
      },
      {
        id: 1,
        icon: "icon ws-scalability",
        title: "Scalability",
        peragraph:
          "Enabling you to meet the needs of your growing business with highly scalable resources that can be easily added while your website continues handling traffic.",
      },
      {
        id: 2,
        icon: "icon ws-proactive",
        title: "Security",
        peragraph:
          "Our VPS hosting plans come with multiple layers of network security paired up with continuous monitoring in order to keep your business data safe at all times.",
      },
      {
        id: 3,
        icon: "icon ws-quick",
        title: "Easy-to-Use Control Panel",
        peragraph:
          "Being an official partner of cPanel, we know all the particulars of world’s largest control panel and prefer it due to reasons including its easy usability and unique features.",
      },
      {
        id: 4,
        icon: "icon ws-provision",
        title: "Quick Provisioning",
        peragraph:
          "Buying a dedicated server web hosting package from us means no to long days spent waiting to start. We’d provision your server the same day you purchase it from us! ",
      },
      {
        id: 5,
        icon: "icon ws-managed-service",
        title: "Optional Managed Services",
        peragraph:
          "All our hosting plans come with a free initial server setup. Our team can also provide you with server management services and fully managed support if you need us to.",
      },
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        id: 0,
        topTitle: "Need More Power?",
        Title: "Try Dedicated Server",
        buttonLink: "/dedicated-server",
      },
      {
        id: 1,
        topTitle: "Need Something Affordable?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
    ],
  };

  const BundelBooster2Data = {
    Title: "Bundle Boosters",
    subTitle: "Select Your Server, Configure It, and Move on!",
    Heading1: "Licenses",
    LicensesItems: [
      {
        id: 0,
        name: "R1Soft Agent License",
        price: "Rs 2870/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 2,
        name: "Softaculous for VPS",
        price: "Rs 615/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 3,
        name: "Softaculous for Dedicated Server",
        price: "Rs 1025/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 4,
        name: "cPanel Admin Cloud (Upto 5 Accounts)",
        price: "Rs 3485s/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 5,
        name: "cPanel BareMetal Server (Upto 100 Accounts)",
        price: "Rs 9225/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
    ],
    Heading2: "Managed Support",
    LicensesManagedSupport: [
      {
        id: 0,
        name: "Fully Managed Support - Dedicated",
        price: "Rs 15375/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 2,
        name: "Fully Managed Support - VPS",
        price: "Rs 6150/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
    ],
  };
  const WhyItWorthData = {
    Heading: "Why It’s Worth It?",
    subHeading: "Everything You Need to Handle Your Exploding Growth",
    box: [
      {
        id: 0,
        heading: "Faster Speed",
        subHeading: "Powerful CPU, High I/O Disks",
        icon: "icon ws-fast",
      },
      {
        id: 1,
        heading: "Enhanced Security",
        subHeading: "Proactive Server Monitoring",
        icon: "icon ws-secure-b",
      },
      {
        id: 3,
        heading: "Better Performance",
        subHeading: "Maximum Uptime Guarantee",
        icon: "icon ws-quick",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          VPS Hosting in Pakistan | Virtual Private Server Hosting | WebSouls
        </title>
        <meta
          name="title"
          content="VPS Hosting in Pakistan | Virtual Private Server Hosting | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls providing low cost VPS hosting in Pakistan. We designed virtual private server hosting packages in a way which can fulfill every customer’s needs."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "VPS Hosting - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "At WebSouls, we offer the best dedicated web hosting packages that come with a lightning fast load time and ultimate security. Not only do we provide the best and top-class hosting services, but getting a dedicated server in Pakistan from us would come with numerous additional benefits in the form of our amazing ‘bundle boosters’.",
  "brand": "WebSouls",
  "sku": "VPS Hosting",
  "mpn": "VPS Hosting",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/vps-hosting",
    "priceCurrency": "PKR",
    "lowPrice": "1199",
    "highPrice": "3499",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "worstRating": "4.3",
    "ratingCount": "2501"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How is managed VPS different from unmanaged VPS ? Which one should I get?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "For unmanaged VPS, you’d need to have an advanced level of technical knowledge, as well as a basic understanding of Linux, since you’d be configuring and updating your VPS hosting yourself. On the other hand, managed hosting is a piece of cake for you even if you aren’t familiar with the tech stuff, because the WebSouls team would be doing all the heavy lifting for you."
    }
  },{
    "@type": "Question",
    "name": "Do i need to have prior technical knowledge for VPS Hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, technical knowledge is required to manage a VPS server. Since this hosting plan offers more power and flexibility than the standard shared hosting, its server management is equally complex. It must be noted here that all our VPS hosting packages are unmanaged by default, however, you can go for a managed VPS hosting plan for more ease and simplicity."
    }
  },{
    "@type": "Question",
    "name": "Do you provide Windows VPS Hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes! we do offer Windows VPS hosting with 4 GB to 32 GB RAM. Also, when it comes to WordPress website management, you can also add Plesk Obsidian Webhost Edition to create and manage your websites with ease."
    }
  },{
    "@type": "Question",
    "name": "Does a VPS Hosting Plan includes backup?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, we offer automated weekly backup as one of our Bundle Boosters for VPS hosting plans. Plus, you can also create a backup on demand whenever needed."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/vps-hosting" />
      </Helmet>
      <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">Get Your Unlimited Traffic Going</h2>
            <h5 className="second-heading">
            Power, Flexibility, and All the Control for Your Website
            </h5>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item " role="presentation">
                <button
                  className="nav-link active"
                  id="linux-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#linux"
                  type="button"
                  role="tab"
                  aria-controls="linux"
                  aria-selected="true"
                >
                  <i className="fa fa-linux" />
                  Linux
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  // onClick={() => WindowsServer()}
                  className="nav-link"
                  id="windows-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#windows"
                  type="button"
                  role="tab"
                  aria-controls="windows"
                  aria-selected="false"
                >
                  <i className="fa fa-windows" />
                  Windows
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="linux"
                role="tabpanel"
                aria-labelledby="linux-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusOne}
                      >
                        {vpsHostingLinuxList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getdedicatedPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map(
                                      (pr) =>
                                        pr[
                                          getPackageBaseCycle(
                                            item,
                                            currencyName?.api_id
                                          )
                                        ]
                                    ),
                                  item?.pid,
                                  getPackageBaseCycle(
                                    item,
                                    currencyName?.api_id
                                  ),
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
              <div
                className="tab-pane fade show"
                id="windows"
                role="tabpanel"
                aria-labelledby="windows-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusTwo}
                      >
                        {vpsHostingWindowsList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getdedicatedPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map(
                                      (pr) =>
                                        pr[
                                          getPackageBaseCycle(
                                            item,
                                            currencyName?.api_id
                                          )
                                        ]
                                    ),
                                  item?.pid,
                                  getPackageBaseCycle(
                                    item,
                                    currencyName?.api_id
                                  ),
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                &nbsp;
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyItWoth Data={WhyItWorthData} />
      <DomainTips Data={technicalSpecificationsData} />
      <GoingOnline Data={GoingOnlineData} />
      <BundelBooster2 Data={BundelBooster2Data} />
      <CustomizeServer />
      <WhyChooseUs />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default Vps;
