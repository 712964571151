import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import {
  getPrice,
  delayTime,
  packagesFeatures,
  packagesFirstFeature,
} from "../../../services/Helper";
import AllPlanIncludes from "./includes/BusinessHosting/AllPlanIncludes";
import BundelBooster from "./includes/BusinessHosting/BundelBooster";
import DomainTips from "./includes/BusinessHosting/DomainTips";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Faq from "../../reuseable/Faq";
import InPakistan from "./includes/BusinessHosting/InPakistan";
import DomainMigration from "./includes/BusinessHosting/DomainMigration";
import ComparePlans from "../../reuseable/ComparePlans";
import Carousel from "react-elastic-carousel";
import WhyChooseUsThird from "../../reuseable/WhyChooseUsThird";
import GoingOnline from "./includes/BusinessHosting/GoingOnline";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
const BussinessHosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [linuxPids, setLiniuxPids] = useState([107, 154, 184]);
  const [winodwsPids, setWindowsPids] = useState([108, 155, 204]);
  const [statusOne, setStatusOne] = useState(true);
  const [statusTwo, setStatusTwo] = useState(true);

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Hosting Solutions Focused on Cost-Effective Reliability",
    Box: [
      {
        id: 0,
        heading: "1-Click Installation",
        subHeading: "(WordPress, Joomla, Drupal etc)",
        icon: "icon ws-1-click-install-script",
      },
      {
        id: 1,
        heading: "Proactive Support",
        subHeading: "(Phone, Chat, Tickets)",
        icon: "icon ws-proactive",
      },
      {
        id: 2,
        heading: "Personalized Email",
        subHeading: "(Example: info@yourdomain.com)",
        icon: "icon ws-email-b",
      },
      {
        id: 3,
        heading: "Easy To Use",
        subHeading: "(Hosting Panel)",
        icon: "icon ws-easy",
      },
      {
        id: 4,
        heading: "Easy Management",
        subHeading: "(Database management from cPanel)",
        icon: "icon ws-disk-b",
      },
      {
        id: 5,
        heading: "Swift Upgrading",
        subHeading: "(CPU, RAM, I/O etc.)",
        icon: "icon ws-chain",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        bundleHeading: "Backup & Restore",
        bundleParagh:
          "Website backups mean more than just peace of mind - it’s the difference between a business failing and a business succeeding, no matter what. With our daily automatic backups, we can help you bring your website back to life with just one click in case of any accidental data loss.",
        bundleImage: "assests/img/Backup-&-Restore-services.png",
        bundleId: "backupAndRestore-tab",
        bundleDataTarget: "#backupAndRestore",
        bundleAriaControl: "backupAndRestore",
        bundleParaghId: "backupAndRestore",
        bundleParaghAriaLable: "backupAndRestore-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "Keeping our clients’ data secure is essential, but keeping the data of their customers safe is what we really take pride in. By making SSL certification easy, we help businesses in building the trust of their customers, turning their website into a lead generating machine with higher rate of conversions, guaranteed.",
        bundleImage: "assests/img/Purchase-SSL-Certificate-in-Pakistan.png",
        bundleId: "ssl-certificate-tab",
        bundleDataTarget: "#ssl-tabs-certificate",
        bundleAriaControl: "ssl-tabs-certificates",
        bundleParaghId: "ssl-tabs-certificate",
        bundleParaghAriaLable: "ssl-certificate-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Dedicated IP",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Dedicated-IP-Price-In-Pakistan.png",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Website Security",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Website-Security-in-Pakistan.png",
        bundleId: "web-security-tab",
        bundleDataTarget: "#web-security",
        bundleAriaControl: "web-security",
        bundleParaghId: "web-security",
        bundleParaghAriaLable: "web-security-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/WebSouls-Net-Ops-Engineer-Muhammad-Rameez.png",
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is business hosting?",
        desc: "business hosting means that there won’t be any restrictions on amount of bandwidth, databases, FTP, and email IDs. This plan is perfect for you if you want to use your hosting account without worrying about overloading the server or affecting other users.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        title: "How can I manage my website with business web hosting?",
        desc: "Our business web hosting service comes with cPanel, so tasks such as managing your databases or adding/removing email accounts would be completely effortless.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        title: "How many websites can I host on business hosting plan?",
        desc: "On an business unlimited web hosting plan, you’d be able to host up to 5 domains. However, if you upgrade to Ultimate Supreme or Corporate, you can host multiple domain names and unlimited websites.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        title: "Can I subdivide and resell my business hosting package?",
        desc: "No. While you can host multiple websites or domain names on an unlimited hosting plan, you cannot divide it and resell. If you want to resell to your customers then we’d recommend you to opt for our Reseller Hosting plan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "Hosting Plans Fueled With the Latest Technology",
    box: [
      {
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        icon: "icon ws-cpanel",
        title: "cPanel Official Partner",
      },
      {
        icon: "icon ws-clients",
        title: "1000+ Corporate Clients",
      },
      {
        icon: "icon ws-scripts",
        title: "Bash Scripts",
      },
      {
        icon: "icon ws-email-c",
        title: "Smooth Email Service",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        icon: "icon ws-recycle",
        title: "Proactive Monitoring",
        peragraph:
          "We offer 24/7 security monitoring and DDos protection to keep all your business and personal data safe and secure from spam, malware, and hackers.",
      },
      {
        icon: "icon ws-rack",
        title: "Guaranteed Server Uptime",
        peragraph:
          "You can experience the real 99.99% uptime with us, paired up with our dedicated customer support and servers that offer blazing-fast speed at all times.",
      },
      {
        icon: "icon ws-scripts",
        title: "Languages & Performance",
        peragraph:
          "Our hosting plans come with high level performance and we also support numerous programming languages including PHP, CSS, HTML, and many more.",
      },
      {
        icon: "icon ws-processor",
        title: "Fast and High Core CPUs",
        peragraph:
          "We can keep your site online and always running smoothly with our high core CPU, Ram and Disk IO limits. Experience the best speed load times with us!",
      },
      {
        icon: "icon ws-1-click-install-script",
        title: "1-Click install Scripts",
        peragraph:
          "We offer 1-click app install so that you can easily build your website while using your favourite app. Our clients can install all the applications they love with just a single click!",
      },
      {
        icon: "icon ws-ftp",
        title: "Highly Secure FTP Access",
        peragraph:
          "We provide FTP (File Transfer Protocol) access so that you can upload your website files with ease while making sure all your site data is safe and secure.",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Unlimited Hosting in Pakistan",
    subHeading: "Hosting by the Experts, for the Experts",
    peragraph: [
      <>
        <p>
          At WebSouls we offer the best business hosting package in Pakistan,
          with more than enough disk space, unlimited email accounts, and
          bandwidth that is perfect for large business websites. Our packages
          come at the most affordable prices and are easy to upgrade whenever
          needed.
        </p>
        <p>
          In addition to this, as a&nbsp;
          <Link to="/">top web hosting company in Pakistan</Link>, WebSouls
          provides its customers with carefree domain registration &amp; web
          hosting solutions that include all website essentials, and more. With
          our dedicated team of hosting experts and support executives, there’s
          never a compromise on our services and we ensure complete security and
          protection of our client’s business data, as well as their personal
          information.  So, host your website with one of the leading Pakistani
          web hosting companies and go to sleep peacefully every night.
        </p>
        <p>
          While some plans may require you to attach an&nbsp;
          <Link to="/ssl-certificates">SSL certificate</Link>&nbsp;as an add-on,
          our premium business hosting plans come with a free SSL certificate.
          So, if you want the highest level of security for your website, get a
          premium SSL certificate from us today. Not only will it protect your
          website, its also great for showing visitors that your site is safe
          and drive up your overall website performance.
        </p>
        <span id="dots">...</span>
        <span id="more">
          <p>
            Moreover, team WebSouls has crafted the best hosting plans that you
            can purchase to create a powerful online presence without having to
            spend a fortune on
            <Link to="/dedicated-server">web hosting services in Pakistan</Link>
            . How to get your business website up and running? It’s a matter of
            just few clicks!
          </p>
          <p>
            With our unlimited hosting package, you won’t have to worry about
            ending up with less disk space. We can fulfil all your web space and
            bandwidth needs while keeping your site safe and guaranteeing 99.99%
            uptime with our support team always at your disposal. In case you
            need more control, we alsooffer 
            <Link to="/dedicated-server">dedicated server hosting plans</Link>
             and can allocate a whole server just to you. Buying web hosting in
            Pakistan has never been so easy before!
          </p>
          <p>
            As the premium web hosting company in Pakistan, we offer our
            business clients enough resources for their websites to grow. All
            our hosting servers offer the latest SSD hosting technology,
            providing you with more speed, better data transfer and high-level
            security protection. We also offer unlimited bandwidth, to ensure
            your site runs smoothly 24/7.
          </p>
          <p>
            We ensure to make it easy for Pakistani business owners to take
            their business online. With us, you can do it all, from registering
            your own domain name to finding the perfect affordable web hosting
            plan and launching your own powerful website! We’re here to help at
            every step of your digital journey!
          </p>
          <p>
            Our business hosting plans contain all the resources required for
            website optimization. With these plans you’ll get premium services
            like unlimited bandwidth, great storage capacity and dedicated
            support. If you choose out business hosting plans, you won’t have to
            worry about website security, slow website speed and other common
            hosting problems
          </p>
          <p>
            We’re also a provider of 
            <Link to="/seo-services">SEO services In Pakistan</Link> and can
            help you attract more customers with local SEO optimization if you
            own a local business in Islamabad, Karachi, or anywhere else around
            the globe.   With our web hosting services combined with SEO and
            social media management, we help businesses turn into brands, leads
            turn into sales, and dreams turn into reality.
          </p>
          <p>
            In addition to this, we provide 
            <Link to="/web-development">website development services</Link> to
            help you create the perfect business website. Our ecommerce solution
            includes e-commerce website development. We even give you a valuable
            training session so you can have complete control over your
            ecommerce store. If you are looking to create a new e- commerce
            store or improve your existing website, then our web development
            plans have everything you need!
          </p>
          <p>
            If you are looking for a hosting plan for expansion, then we’ve got
            exactly what you need. Our Unlimited web hosting plans allow you to
            host multiple domains so that you can manage different branches of
            your business. If you want to expand your business website, then our
            business hosting plans have all the resources you’ll need!
          </p>
          <p>
            We offer dedicated support for technical issues, which is why we
            guarantee 99.99% uptime. If you choose out managed hosting services
            for your business website, you can be assured of getting quality
            updates and 24/7 technical backend support, so you’ll never have to
            worry about website downtime disrupting your business again!
          </p>
          <p>
            Our major focus is customer satisfaction and we can go to all ends
            to make sure our clients are getting the best services!
          </p>
        </span>
        <a className="read-more-link">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        topTitle: "Need Higher Performance?",
        Title: "Try VPS Hosting",
        buttonLink: "/vps-hosting",
      },
      {
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };

  const [bussinessHostingLinuxList, setBussinesHostingLinuxList] = useState([]);
  const [bussinessHostingWindowsList, setBussinesHostingWindowsList] = useState(
    []
  );
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(false);

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const dots = document.getElementById("dots");
  const moreText = document.getElementById("more");
  const btnText = document.getElementById("myBtn");

  let widnowWidth = window.innerWidth;
  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots, widnowWidth]);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  useEffect(() => {
    if (linuxPids?.length <= 3) {
      setStatusOne(false);
    }
    if (winodwsPids?.length <= 3) {
      setStatusTwo(false);
    }
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
    GetActivePromotions().then((ress) => {
      setActivePromotions(ress);
    });
    GetPackages(linuxPids).then((res) => {
      setBussinesHostingLinuxList(res);
      setLoading(true);
     // delayTime(5000).then(() => {
        GetPackages(winodwsPids).then((respo) => {
          setBussinesHostingWindowsList(respo);

          setLoading(true);
         
        });
     // });
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  return (
    <>
      <Helmet>
        <title>
          Web Hosting in Pakistan | Best Pakistani Web Hosting | WebSouls
        </title>
        <meta
          name="title"
          content="Web Hosting in Pakistan | Best Pakistani Web Hosting | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls provides quality Web Hosting in Pakistan. We offer best, secured and powerful Pakistani web hosting at fastest servers. Experience the services of No.1 web hosting company in Pakistan."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Business Web Hosting - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "At WebSouls we offer the best business hosting package in Pakistan, with more than enough disk space and bandwidth that is perfect for large business websites. Our packages come at the most affordable prices and are easy to upgrade whenever needed.",
  "brand": "WebSouls",
  "sku": "Business Web Hosting",
  "mpn": "Business Web Hosting",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/business-hosting",
    "priceCurrency": "PKR",
    "lowPrice": "5449",
    "highPrice": "13300",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "worstRating": "4",
    "ratingCount": "2843"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is unlimited hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Unlimited hosting means that there won’t be any restrictions on amount of bandwidth, databases, FTP, and email IDs. This plan is perfect for you if you want to use your hosting account without worrying about overloading the server or affecting other users."
    }
  },{
    "@type": "Question",
    "name": "How can i manage my website with unlimited web hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our unlimited web hosting service comes with cPanel, so tasks such as managing your databases or adding/removing email accounts would be completely effortless."
    }
  },{
    "@type": "Question",
    "name": "How many websites can I host on unlimited hosting plan?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "On an Ultimate unlimited web hosting plan, you’d be able to host up to 5 domains. However, if you upgrade to Ultimate Supreme or Corporate, you can host multiple domain names and unlimited websites."
    }
  },{
    "@type": "Question",
    "name": "Can I subdivide and resell my unlimited hosting package?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No. While you can host multiple websites or domain names on an unlimited hosting plan, you cannot divide it and resell. If you want to resell to your customers then we’d recommend you to opt for our Reseller Hosting plan."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/business-hosting" />
      </Helmet>
      <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">Go Unlimited. Grow Unlimited</h2>
            <h5 className="second-heading">
            More Space, Higher Bandwidth, and Better Performance, Combined
            </h5>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item " role="presentation">
                <button
                  className="nav-link active"
                  id="linux-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#linux"
                  type="button"
                  role="tab"
                  aria-controls="linux"
                  aria-selected="true"
                >
                  <i className="fa fa-linux" />
                  Linux
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="windows-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#windows"
                  type="button"
                  role="tab"
                  aria-controls="windows"
                  aria-selected="false"
                >
                  <i className="fa fa-windows" />
                  Windows
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="linux"
                role="tabpanel"
                aria-labelledby="linux-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusOne}
                      >
                        {bussinessHostingLinuxList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map((pr) => pr[billingCycleHosting]),
                                  item?.pid,
                                  billingCycleHosting,
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
              <div
                className="tab-pane fade show"
                id="windows"
                role="tabpanel"
                aria-labelledby="windows-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusTwo}
                      >
                        {bussinessHostingWindowsList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map((pr) => pr[billingCycleHosting]),
                                  item?.pid,
                                  billingCycleHosting,
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text"
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <DomainTips Data={technicalSpecificationsData} />
      <ComparePlans comparePlansState={bussinessHostingLinuxList} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default BussinessHosting;
