import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import AllPlanInclude from "./includes/SharedHosting/AllPlanInclude";
import BundelBooster from "./includes/SharedHosting/BundelBooster";
import DomainMigration from "./includes/SharedHosting/DomainMigration";
import Faq from "../../reuseable/Faq";
import GoingOnline from "./includes/SharedHosting/GoingOnline";
import InPakistan from "./includes/SharedHosting/InPakistan";
import WhyChooseUsThird from "./includes/SharedHosting/WhyChooseUsThird";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Skeleton from "react-loading-skeleton";
import ComparePlans from "./includes/SharedHosting/ComparePlans";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import DomainTips from "./includes/SharedHosting/DomainTips";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
  delayTime,
} from "../../../services/Helper";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
import shortid from "shortid";

const SharedHosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Hosting Solutions Focused on Cost-Effective Reliability",
    Box: [
      {
        id: 0,
        heading: "1-Click Installation",
        subHeading: "(WordPress, Joomla, Drupal etc)",
        icon: "icon ws-1-click-install-script",
      },
      {
        id: 1,
        heading: "Proactive Support",
        subHeading: "(Phone, Chat, Tickets)",
        icon: "icon ws-proactive",
      },
      {
        id: 2,
        heading: "Personalized Email",
        subHeading: "(Example: info@yourdomain.com)",
        icon: "icon ws-email-b",
      },
      {
        id: 3,
        heading: "Easy To Use",
        subHeading: "(Hosting Panel)",
        icon: "icon ws-easy",
      },
      {
        id: 4,
        heading: "Easy Management",
        subHeading: "(Database management from cPanel)",
        icon: "icon ws-disk-b",
      },
      {
        id: 5,
        heading: "Swift Upgrading",
        subHeading: "(CPU, RAM, I/O etc.)",
        icon: "icon ws-chain",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        bundleHeading: "Backup & Restore",
        bundleParagh:
          "Website backups mean more than just peace of mind - it’s the difference between a business failing and a business succeeding, no matter what. With our daily automatic backups, we can help you bring your website back to life with just one click in case of any accidental data loss.",
        bundleImage: "assests/img/Backup-&-Restore-services.png",
        bundleId: "backupAndRestore-tab",
        bundleDataTarget: "#backupAndRestore",
        bundleAriaControl: "backupAndRestore",
        bundleParaghId: "backupAndRestore",
        bundleParaghAriaLable: "backupAndRestore-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "Keeping our clients’ data secure is essential, but keeping the data of their customers safe is what we really take pride in. By making SSL certification easy, we help businesses in building the trust of their customers, turning their website into a lead generating machine with higher rate of conversions, guaranteed.",
        bundleImage: "assests/img/Purchase-SSL-Certificate-in-Pakistan.png",
        bundleId: "ssl-certificate-tab",
        bundleDataTarget: "#ssl-tabs-certificate",
        bundleAriaControl: "ssl-tabs-certificates",
        bundleParaghId: "ssl-tabs-certificate",
        bundleParaghAriaLable: "ssl-certificate-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Dedicated IP",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Dedicated-IP-Price-In-Pakistan.png",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Website Security",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Website-Security-in-Pakistan.png",
        bundleId: "web-security-tab",
        bundleDataTarget: "#web-security",
        bundleAriaControl: "web-security",
        bundleParaghId: "web-security",
        bundleParaghAriaLable: "web-security-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is Shared Hosting?",
        desc: "Shared web hosting is an affordable hosting plan that’s perfect for small businesses and start-ups. Multiple website owners share resources of a single server to make their sites available on the internet. WebSouls offers various shared hosting packages that are light on the wallet and can fulfil all your website needs.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "Do I need to have prior technical knowledge for shared hosting?",
        desc: "No prior technical knowledge is required for shared hosting. Just after you’ve ordered your service, we’d send you your login details as well as all the necessary information required to start your hosting journey.  In addition to this, we also offer quality support through live chat, email, and phone, so you can always hit us up in case of any questions.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "How does shared hosting differ from business hosting?",
        desc: "The key difference between shared and businesses hosting is the number of clients per server. Apart from this, there are also certain limitations, such as the hourly emails. However, your website won’t be affected in any other way if you decide to go for shared server space.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Would I be able to upgrade my package later?",
        desc: "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package from Premium to Standard and to Professional hosting plan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Net-Ops-Engineer-Haris-Mukhtar.png",
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "Hosting Plans Fueled With the Latest Technology",
    box: [
      {
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        icon: "icon ws-cpanel",
        title: "cPanel Official Partner",
      },
      {
        icon: "icon ws-clients",
        title: "1000+ Corporate Clients",
      },
      {
        icon: "icon ws-scripts",
        title: "Bash Scripts",
      },
      {
        icon: "icon ws-email-c",
        title: "Smooth Email Service",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-recycle",
        title: "Proactive Monitoring",
        peragraph:
          "We offer 24/7 security monitoring and DDos protection to keep all your business and personal data safe and secure from spam, malware, and hackers.",
      },
      {
        id: 1,
        icon: "icon ws-rack",
        title: "Guaranteed Server Uptime",
        peragraph:
          "You can experience the real 99.99% uptime with us, paired up with our dedicated customer support and servers that offer blazing-fast speed at all times.",
      },
      {
        id: 2,
        icon: "icon ws-scripts",
        title: "Languages & Performance",
        peragraph:
          "Our hosting plans come with high level performance and we also support numerous programming languages including PHP, CSS, HTML, and many more.",
      },
      {
        id: 3,
        icon: "icon ws-processor",
        title: "Fast and High Core CPUs",
        peragraph:
          "We can keep your site online and always running smoothly with our high core CPU, Ram and Disk IO limits. Experience the best speed load times with us!",
      },
      {
        id: 4,
        icon: "icon ws-1-click-install-script",
        title: "1-Click install Scripts",
        peragraph:
          "We offer 1-click app install so that you can easily build your website while using your favourite app. Our clients can install all the applications they love with just a single click!",
      },
      {
        id: 5,
        icon: "icon ws-ftp",
        title: "Highly Secure FTP Access",
        peragraph:
          "We provide FTP (File Transfer Protocol) access so that you can upload your website files with ease while making sure all your site data is safe and secure.",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Shared Hosting in Pakistan",
    subHeading: "Hosting by the Experts, for the Experts",
    peragraph: [
      <>
        <p>
          WebSouls brings you highly affordable shared&nbsp;
          <strong>web hosting solutions in Pakistan</strong>&nbsp;that’re
          exceedingly secure, with unprecedented customer support and a
          guarantee of 99.99% uptime, all at the best possible price!
        </p>
        <p>
          We take pride in being a leading local provider of the best website
          hosting services in Pakistan. To our clients, we offer all the
          webhosting essentials and outstanding services, alongside domain
          registration at remarkably cheap prices. Our experts have crafted
          solutions that are perfect for small businesses and start-ups.
        </p>
        <p>
          Our shared hosting plans are affordable and make it easy for anyone to
          start a website. Whether you are looking to start a blog or need a
          portfolio website, our shared hosting plans have everything you
          require. With dedicated support and excellent resources, our shared
          hosting plans are the best in Pakistan!
        </p>
        <p>
          The best part is, with us, you won’t have to worry about your site’s
          security being compromised due to sharing server space. Buy
          WebSouls&nbsp;
          <Link to="/business-hosting">
            <strong>web hosting in Pakistan</strong>
          </Link>
          &nbsp;and stop worrying about the safety of your personal and business
          information. In case of any issue, our support experts are always up
          for providing you with the best assistance in Lahore, Karachi,
          Islamabad and throughout the nation.
        </p>
        <span id="dots">...</span>
        <span id="more">
          <p>
            Apart from this, you can also contact us for&nbsp;
            <Link to="/vps-hosting">
              <strong>cloud hosting in Pakistan</strong>
            </Link>
            , or any other affordable web hosting plan via phone call or
            <a
              target="_blank"
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=2"
            >
              <strong>email</strong>
            </a>
            , our support team is committed to getting back to our customers
            faster than ever, and with the perfect domain hosting solutions in
            Pakistan!
          </p>
          <p>
            Buy from us, or purchase a plan of shared web hosting in Pakistan
            from WebSouls, and you’d get a free .com domain with it! So, if you
            don’t have a website yet, hurry now and get affordable web hosting
            services in Karachi yet secure shared hosting in Pakistan from
            WebSouls.
          </p>
          <p>
            In case you’re not sure about investing in our web hosting services,
            we offer a free 7-day trial as well. You won’t have to pay a single
            penny and purchase only when you’re fully satisfied with our shared
            hosting plans!
          </p>
          <p>
            Plus, all our&nbsp;
            <Link to="/">
              <strong>web hosting</strong>
            </Link>
            &nbsp;plans are easy to upgrade. So, if you own a startup business
            and your website can rely on a basic plan for now, don’t worry about
            the days when your business needs would increase. All you’d have to
            do in order to keep your website running smoothly is upgrade your
            hosting plan!
          </p>
          <p>
            Our&nbsp;<strong>web hosting plans</strong>&nbsp;also offer 1 click
            installations of many popular applications such as Joomla or
            WordPress. You also get better website management options through
            cPanel and a personalized email for your business. With so many
            tools to help you smoothly run your website, there’s no reason not
            to choose our shared hosting plans today!
          </p>
          <p>
            Unlike many other shared hosting packages, our shared hosting has
            everything you need for a faster, smoother website. With some of our
            plans, you’ll even get up to 8GB storage space, which is more than
            enough for a blogging or large portfolio website! So, when it comes
            to affordable shared hosting plans with amazing benefits, our plans
            are the best in Pakistan!
          </p>
          <p>
            Unlike many other shared hosting plans that you’ll find online, our
            shared hosting packages contain everything you need to keep your
            website running smoothly. So, if you want affordable shared hosting
            that can be used for any website, then check out our wide range of
            hosting plans. Whether you have a small portfolio site or a large
            e-commerce store, we have a&nbsp;
            <strong>shared hosting plan</strong>&nbsp;for you.
          </p>
          <p>
            Our proactive support team would never leave you alone in the hour
            of need. We make sure that all our clients are not just provided
            with the right and affordable web hosting solution, but also
            assisted whenever they need our help. We offer support on live chat,
            phone calls, and email. You can also visit our offices in Lahore or
            Karachi for detailed guidance from our technical experts.
          </p>
          <p>
            We know that every business is unique, which means that your website
            will need a plan that matches your specific needs. Our hosting
            experts will look at your website hosting requirements and provide
            you with the perfect plan that includes enough RAM, bandwidth,
            storage space, security and other premium features.
          </p>
          <p>
            Want to discuss your project with our hosting experts in-person?
            Visit us at our office in Lahore or Karachi and get ready to receive
            the best and remarkably
            <Link to="/business-hosting">
              <strong>affordable web hosting packages</strong>
            </Link>
            that cater to all your needs!
          </p>
          <p>
            If you want to grow your website, then we’ve got everything you
            need. From hosting to web development and SEO, our team of experts
            have you covered. If you want more organic traffic, conversions and
            sales, then contact us today. Our SEO team will evaluate your
            requirements and come up with the best solution to grow your
            business online.
          </p>
          ,
          <p>
            Our high-quality
            <Link to="/seo-services">
              <strong>SEO services</strong>
            </Link>
            are just what you need to establish a powerful brand image and grow
            your business digitally. We target only the top-notch keywords that
            will get you more visitors and increase your business’s revenue. So,
            if you are looking for the best SEO plan with a great ROI, contact
            out SEO team today!
          </p>
          <p>
            If you want to register a domain name, have a look at our online
            domain checker and find a domain name that resonates with your
            business idea as soon as possible. Apart from the .com domains
            that’re offered without any cost with all our web hosting plans, you
            can also register domains with various other popular TLDs!
          </p>
          <p>
            Moreover, we also provide&nbsp;
            <Link to="/web-development">
              <strong>website development services in Pakistan</strong>
            </Link>
            &nbsp;and are proud to have helped numerous small- and large-scale
            business in their journey of digitalization.
          </p>
        </span>
        <a className="read-more-link ">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        id: 0,
        topTitle: "Need More Power?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?  ",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };

  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const [pidsss, setPid] = useState([125, 130, 320]);
  const [linuxPids, setLiniuxPids] = useState([125, 130, 320]);
  const [winodwsPids, setWindowsPids] = useState([126, 131, 322]);
  const [bussinessHostingLinuxList, setBussinesHostingLinuxList] = useState([]);
  const [bussinessHostingWindowsList, setBussinesHostingWindowsList] = useState(
    []
  );
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [statusOne, setStatusOne] = useState(true);
  const [statusTwo, setStatusTwo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [comparePlansState, setComparePlansState] = useState([]);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots]);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  useEffect(() => {
    if (linuxPids.length <= 3) {
      setStatusOne(false);
    }
    if (winodwsPids.length <= 3) {
      setStatusTwo(false);
    }
    GetActivePromotions().then((ress) => {
      setActivePromotions(ress);
    });
    GetPackages(linuxPids).then((res) => {
      setBussinesHostingLinuxList(res);
      setLoading(true);
     // delayTime(5000).then(() => {
        GetPackages(winodwsPids).then((respo) => {
          setBussinesHostingWindowsList(respo);
          setComparePlansState(respo);
          setLoading(true);
        
        });
     // });
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  return (
    <>
      <Helmet>
      <title>Website Hosting Pakistan | Best Web Hosting in Lahore & Karachi</title>
        <meta
          name="title"
          content="Website Hosting Pakistan | Best Web Hosting in Lahore & Karachi"
        />
        <meta
          name="description"
          content="WebSouls provides best website hosting in Pakistan. We offer quality, affordable and powerful web hosting in Lahore, Islamabad, Karachi and other cities of Pakistan."
        />
        <meta name="keywords" content="" />

        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "WebSouls",
          "alternateName": "Web Hosting Pakistan",
          "url": "https://www.websouls.com/",
          "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+92 321 407 7991",
            "contactType": "technical support",
            "areaServed": "PK",
            "availableLanguage": ["en","Urdu"]
          },{
            "@type": "ContactPoint",
            "telephone": "+92 322 525 2352",
            "contactType": "sales",
            "areaServed": "PK",
            "availableLanguage": ["en","Urdu"]
          }],
          "sameAs": [
            "https://www.facebook.com/WebSouls/",
            "https://www.twitter.com/futuresouls",
            "https://www.instagram.com/websouls.pk/",
            "https://www.linkedin.com/company/websouls/",
            "https://github.com/websouls",
            "https://www.websouls.com/"
          ]
        }
    `}</script>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Shared Hosting Pakistan - WebSouls",
          "image": "https://websouls.com/public/uploads/images/footer_logo_1589438878.png",
          "description": "WebSouls brings you highly affordable web hosting solutions in Pakistan that’re exceedingly secure, with unprecedented customer support and a guarantee of 99.99% up-time, all at the best possible price!",
          "brand": "WebSouls",
          "sku": "Pakistani Web Hosting",
          "mpn": "Pakistani Web Hosting",
          "offers": {
            "@type": "AggregateOffer",
            "url": "https://www.websouls.com/shared-hosting",
            "priceCurrency": "PKR",
            "lowPrice": "2699",
            "highPrice": "3900",
            "offerCount": "3"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "4",
            "ratingCount": "2343"
          }
        }
    `}</script>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What is Shared Hosting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Shared web hosting is an affordable hosting plan that’s perfect for small businesses and start-ups. Multiple website owners share resources of a single server to make their sites available on the internet. WebSouls offers various shared hosting packages that are light on the wallet and can fulfil all your website needs."
            }
          },{
            "@type": "Question",
            "name": "Do I need to have prior technical knowledge for shared hosting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No prior technical knowledge is required for shared hosting. Just after you’ve ordered your service, we’d send you your login details as well as all the necessary information required to start your hosting journey.  In addition to this, we also offer quality support through live chat, email, and phone, so you can always hit us up in case of any questions."
            }
          },{
            "@type": "Question",
            "name": "How does shared hosting differ from business hosting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The key difference between shared and businesses hosting is the number of clients per server. Apart from this, there are also certain limitations, such as the hourly emails. However, your website won’t be affected in any other way if you decide to go for shared server space."
            }
          },{
            "@type": "Question",
            "name": "Would I be able to upgrade my package later?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package from Premium to Standard and to Professional hosting plan."
            }
          }]
        }
    `}</script>
        <link rel="canonical" href="https://websouls.com/shared-hosting" />
      </Helmet>
      <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">Inexpensive, But Safe</h2>
            <h5 className="second-heading">
            Everything You Need on a Budget, and More.
            </h5>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item " role="presentation">
                <button
                  className="nav-link active"
                  id="linux-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#linux"
                  type="button"
                  role="tab"
                  aria-controls="linux"
                  aria-selected="true"
                >
                  <i className="fa fa-linux" />
                  Linux
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="windows-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#windows"
                  type="button"
                  role="tab"
                  aria-controls="windows"
                  aria-selected="false"
                >
                  <i className="fa fa-windows" />
                  Windows
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="linux"
                role="tabpanel"
                aria-labelledby="linux-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusOne}
                      >
                        {bussinessHostingLinuxList?.map((item) => (
                          <React.Fragment key={shortid.generate()}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    .map((pr) => pr[billingCycleHosting]),
                                  item?.pid,
                                  billingCycleHosting,
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li key={shortid.generate()}>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
              <div
                className="tab-pane fade show"
                id="windows"
                role="tabpanel"
                aria-labelledby="windows-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusTwo}
                      >
                        {bussinessHostingWindowsList?.map((item) => (
                          <React.Fragment key={shortid.generate()}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map((pr) => pr[billingCycleHosting]),
                                  item?.pid,
                                  billingCycleHosting,
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li key={shortid.generate()}>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text"
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllPlanInclude plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <DomainTips Data={technicalSpecificationsData} />
      <ComparePlans comparePlansState={bussinessHostingLinuxList} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default SharedHosting;
