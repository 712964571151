import React from "react";
import ScrollToTop from "./services/ScrollToTop";
import TagManager from 'react-gtm-module'
import "./assests/css/bootstrap.css";
import "./assests/css/styles.css";
import "./assests/css/responsive.css";
import "./assests/css/spantex.css";

import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./services/Store.js";

const tagManagerArgs = {
  gtmId: 'GTM-WTTDN864'
} 
TagManager.initialize(tagManagerArgs)


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
);
