import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPrice,
  delayTime,
  packagesFeatures,
  packagesFirstFeature,
} from "../../../services/Helper";
import AllPlanIncludes from "./includes/WordPressHosting/AllPlanIncludes";
import BundelBooster from "./../../reuseable/BundelBooster";
import DomainTips from "./includes/WordPressHosting/DomainTips";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Faq from "../../reuseable/Faq";
import InPakistan from "./includes/WordPressHosting/InPakistan";
import DomainMigration from "./includes/WordPressHosting/DomainMigration";
import ComparePlans from "./includes/WordPressHosting/ComparePlans";
import Carousel from "react-elastic-carousel";
import WhyChooseUsThird from "../../reuseable/WhyChooseUsThird";
import GoingOnline from "./includes/WordPressHosting/GoingOnline";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";

const WordpressHosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [Pids, setPids] = useState([279, 280, 281, 321]);
  const [status, setStatus] = useState(true);

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Hosting Solutions Focused on Cost-Effective Reliability",
    Box: [
      {
        id: 0,
        heading: "1-Click Installation",
        subHeading: "(WordPress, Joomla, Drupal etc)",
        icon: "icon ws-1-click-install-script",
      },
      {
        id: 1,
        heading: "Proactive Support",
        subHeading: "(Phone, Chat, Tickets)",
        icon: "icon ws-proactive",
      },
      {
        id: 2,
        heading: "Personalized Email",
        subHeading: "(Example: info@yourdomain.com)",
        icon: "icon ws-email-b",
      },
      {
        id: 3,
        heading: "Easy To Use",
        subHeading: "(Hosting Panel)",
        icon: "icon ws-easy",
      },
      {
        id: 4,
        heading: "Easy Management",
        subHeading: "(Database management from cPanel)",
        icon: "icon ws-disk-b",
      },
      {
        id: 5,
        heading: "Swift Upgrading",
        subHeading: "(CPU, RAM, I/O etc.)",
        icon: "icon ws-chain",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        id: 0,
        bundleHeading: "Backup & Restore",
        bundleParagh:
          "Website backups mean more than just peace of mind - it’s the difference between a business failing and a business succeeding, no matter what. With our daily automatic backups, we can help you bring your website back to life with just one click in case of any accidental data loss.",
        bundleImage: "assests/img/Backup-&-Restore-services.png",
        bundleId: "backupAndRestore-tab",
        bundleDataTarget: "#backupAndRestore",
        bundleAriaControl: "backupAndRestore",
        bundleParaghId: "backupAndRestore",
        bundleParaghAriaLable: "backupAndRestore-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "Keeping our clients’ data secure is essential, but keeping the data of their customers safe is what we really take pride in. By making SSL certification easy, we help businesses in building the trust of their customers, turning their website into a lead generating machine with higher rate of conversions, guaranteed.",
        bundleImage: "assests/img/Purchase-SSL-Certificate-in-Pakistan.png",
        bundleId: "ssl-certificate-tab",
        bundleDataTarget: "#ssl-tabs-certificate",
        bundleAriaControl: "ssl-tabs-certificates",
        bundleParaghId: "ssl-tabs-certificate",
        bundleParaghAriaLable: "ssl-certificate-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        bundleHeading: "Dedicated IP",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Dedicated-IP-Price-In-Pakistan.png",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        bundleHeading: "Website Security",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Website-Security-in-Pakistan.png",
        bundleId: "web-security-tab",
        bundleDataTarget: "#web-security",
        bundleAriaControl: "web-security",
        bundleParaghId: "web-security",
        bundleParaghAriaLable: "web-security-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is WordPress hosting?",
        desc: "A website creation software that powers up to 25% of the internet, WordPress provides easy to use templates for websites that are both beautiful and powerful. WordPress hosting is often the best bid for those who need a hosting solution that’s completely hassle-free and doesn’t require one to have much technical expertise.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        title: "Can I transfer my WordPress hosting to WebSouls?",
        desc: "Yes. Our team of experts can migrate your site free of charge and get you ready to go live instantly.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        title:
          "How do I get started after purchasing a WordPress hosting package?",
        desc: "We provide hosting plans that are connected to the latest version of WordPress. So, once you’ve purchased a WordPress hosting package from us, you can just log in and start creating a site from your control panel. In case of any questions or queries, don’t hesitate to contact our team at any hour during the day.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        title: "Would I be required to set up my own database?",
        desc: "No, it’s a part of process. After purchasing a WordPress hosting package from us you can start creating your site right away while we manage the technical stuff.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };

  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/manager-Web-Hosting-Division-Websouls.png",
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "Hosting Plans Fueled With the Latest Technology",
    box: [
      {
        id: 0,
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        id: 1,
        icon: "icon ws-cpanel",
        title: "cPanel Official Partner",
      },
      {
        id: 2,
        icon: "icon ws-clients",
        title: "1000+ Corporate Clients",
      },
      {
        id: 3,
        icon: "icon ws-scripts",
        title: "Bash Scripts",
      },
      {
        id: 4,
        icon: "icon ws-email-c",
        title: "Smooth Email Service",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-recycle",
        title: "Proactive Monitoring",
        peragraph:
          "We offer 24/7 security monitoring and DDos protection to keep all your business and personal data safe and secure from spam, malware, and hackers.",
      },
      {
        id: 1,
        icon: "icon ws-rack",
        title: "Guaranteed Server Uptime",
        peragraph:
          "You can experience the real 99.99% uptime with us, paired up with our dedicated customer support and servers that offer blazing-fast speed at all times.",
      },
      {
        id: 2,
        icon: "icon ws-scripts",
        title: "Languages & Performance",
        peragraph:
          "Our hosting plans come with high level performance and we also support numerous programming languages including PHP, CSS, HTML, and many more.",
      },
      {
        id: 3,
        icon: "icon ws-processor",
        title: "Fast and High Core CPUs",
        peragraph:
          "We can keep your site online and always running smoothly with our high core CPU, Ram and Disk IO limits. Experience the best speed load times with us!",
      },
      {
        id: 4,
        icon: "icon ws-1-click-install-script",
        title: "1-Click install Scripts",
        peragraph:
          "We offer 1-click app install so that you can easily build your website while using your favourite app. Our clients can install all the applications they love with just a single click!",
      },
      {
        id: 5,
        icon: "icon ws-ftp",
        title: "Highly Secure FTP Access",
        peragraph:
          "We provide FTP (File Transfer Protocol) access so that you can upload your website files with ease while making sure all your site data is safe and secure.",
      },
    ],
  };
  const InPakistanData = {
    Heading: "WordPress Hosting in Pakistan",
    subHeading: "Hosting by the Experts, for the Experts",
    peragraph: [
      <>
        <p>
          WebSouls brings to its customers easy to manage hosting plans for
          unhindered, fast growth with a WordPress Cloud Interface that
          minimizes the hassle of traditional web hosting through its increased
          usability.
        </p>
        <p>
          With a huge number of satisfied clients,&nbsp;
          <Link to="/" target="_blank">
            <strong>WebSouls</strong>
          </Link>
          &nbsp;offers, without any doubt, one of the best WordPress hosting
          services in Pakistan. Our team of professional hosting experts and
          dedicated support executives ensure that the sites of our customers
          continue running smoothly on our servers for WordPress. However if you
          are looking for more power &amp; support our&nbsp;
          <Link to="/vps-hosting" target="_blank">
            <strong>VPS Hosting In Pakistan</strong>
          </Link>
          &nbsp;can offer you the best value &amp; better control.
        </p>
        <p>
          The best part about our affordable WP hosting packages, however, isn’t
          just the unmatched speed that we offer, the technical support and
          assistance of our team, or the incredibly easy to use WordPress
          interface, rather it’s the amazing additional hosting features that we
          provide which make our customers come back for more.
        </p>
        <span id="dots">...</span>
        <span id="more">
          <p>
            If you buy one of our cheap WordPress hosting plans, you can make it
            even better with our “Bundle Boosters” that include automatic backup
            and restore to keep you stress-free, high-end security to keep your
            business data and personal information safe with an&nbsp;
            <Link to="/ssl-certificates" target="_blank">
              <strong>SSL certificate</strong>
            </Link>
            , as well as dedicated IP, domain privacy, and DNS registration.
          </p>
          <p>
            Apart from this, as one of the leading WordPress hosting providers
            in Pakistan, WebSouls is ever ready to help our clients succeed. You
            can contact us through email, live chat, and phone, or walk-in for a
            detailed discussion at one of our offices in Karachi and Lahore.
          </p>
        </span>
        <a className="read-more-link d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        id: 0,
        topTitle: "Need More Power?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Higher Performance?",
        Title: "Try VPS Hosting",
        buttonLink: "/vps-hosting",
      },
    ],
  };

  const [wordpressHostingList, setWordpressHostingList] = useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(false);

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots]);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
    let widnowWidth = window.innerWidth;
    if (widnowWidth < 500) {
      if (dots.style.display === "inline") {
        dots.style.display = "none";
        btnText.innerHTML = "Read More";
        moreText.style.display = "none";
      } else {
        dots.style.display = "inline";
        btnText.innerHTML = "Read Less";
        moreText.style.display = "inline";
      }
    } else {
      moreText.style.display = "inline";
    }
  }

  useEffect(() => {
    if (Pids?.length <= 3) {
      setStatus(false);
    }
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
   // delayTime(5000).then(() => {
      GetActivePromotions().then((ress) => {
        setActivePromotions(ress);
      });
      GetPackages(Pids).then((res) => {
        setWordpressHostingList(res);
        setLoading(true);
      });
    //});
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  return (
    <>
      <Helmet>
        <title>
          WordPress Hosting Pakistan | Managed WordPress Hosting in Pakistan
        </title>
        <meta
          name="title"
          content="WordPress Hosting Pakistan | Managed WordPress Hosting in Pakistan"
        />
        <meta
          name="description"
          content="WebSouls provides WordPress hosting in Pakistan. We offer best Optimized and Managed WordPress Hosting in Lahore, Karachi, Islamabad & other cities."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "WordPress Web Hosting - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "WebSouls brings to its customers easy to manage hosting plans for unhindered, fast growth with a WordPress Cloud Interface that minimizes the hassle of traditional web hosting through its increased usability.",
  "brand": "WebSouls",
  "sku": "WordPress Web Hosting",
  "mpn": "WordPress Web Hosting",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/wordpress-hosting-in-pakistan",
    "priceCurrency": "PKR",
    "lowPrice": "5299",
    "highPrice": "11299",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "4.5",
    "ratingCount": "3182"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is WordPress hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A website creation software that powers up to 25% of the internet, WordPress provides easy to use templates for websites that are both beautiful and powerful. WordPress hosting is often the best bid for those who need a hosting solution that’s completely hassle-free and doesn’t require one to have much technical expertise."
    }
  },{
    "@type": "Question",
    "name": "Can i transfer my WordPress hosting to WebSouls?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes. Our team of experts can migrate your site free of charge and get you ready to go live instantly."
    }
  },{
    "@type": "Question",
    "name": "How do i get started after purchasing a WordPress hosting package?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We provide hosting plans that are connected to the latest version of WordPress. So, once you’ve purchased a WordPress hosting package from us, you can just log in and start creating a site from your control panel. In case of any questions or queries, don’t hesitate to contact our team at any hour during the day."
    }
  },{
    "@type": "Question",
    "name": "Would I be required to set up my own database?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, it’s a part of the process. After purchasing a WordPress hosting package from us you can start creating your site right away while we manage the technical stuff."
    }
  }]
}`}
        </script>
        <link
          rel="canonical"
          href="https://websouls.com/wordpress-hosting-in-pakistan"
        />
      </Helmet>
      <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">Making WordPress Easier For You</h2>
            <h5 className="second-heading" style={{ marginBottom: 60 }}>
              Support Your Business While We Support Your WordPress!
            </h5>

            <>
              {loading ? (
                <>
                  <Carousel
                    className="animate__animated animate__fadeIn"
                    itemPadding={[8, 10, 8, 10]}
                    breakPoints={breakPoints}
                    pagination={true}
                    showArrows={status}
                  >
                    {wordpressHostingList?.map((item) => (
                      <React.Fragment key={item?.id}>
                        <div
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid #D2D2D2",
                            borderRadius: 5,
                          }}
                          className="col-lg-4 pacakge-one text-start"
                        >
                          <div className="package-name ">
                            <h3>{item?.name}</h3>
                            <p className="package-namepaaaa"> {item.fname}</p>
                          </div>
                          <div
                            style={{ marginBottom: 0 }}
                            className="package-one-price checkss"
                          >
                            {getPrice(
                              item?.currency
                                ?.filter(
                                  (fil) =>
                                    fil?.currency === currencyName?.api_id
                                )
                                ?.map((pr) => pr[billingCycleHosting]),
                              item?.pid,
                              billingCycleHosting,
                              getActivePromotions,
                              currencyName?.prefix
                            )}
                          </div>
                          <ul>
                            {item.packageFeatures &&
                              item.packageFeatures[0]?.features.map((elss) => (
                                <li>
                                  <font>
                                    <strong
                                      style={{
                                        color: "rgb(0, 88, 128)",
                                        fontSize: 20,
                                        fontWeight: 400,
                                        paddingRight: 8,
                                      }}
                                    >
                                      ✓{" "}
                                    </strong>
                                  </font>

                                  {elss.includes("_") ? (
                                    <>
                                      {elss.split("_")[0]}
                                      {
                                        <i className="fa fa-info-circle">
                                          <span
                                            className="litooltip"
                                            dangerouslySetInnerHTML={{
                                              __html: elss.split("_")[1],
                                            }}
                                          />
                                        </i>
                                      }
                                    </>
                                  ) : (
                                    elss
                                  )}
                                </li>
                              ))}
                          </ul>

                          <button
                            onClick={(e) => buttonAddToCart(e, item)}
                            className="text "
                          >
                            Add To Cart
                          </button>
                        </div>
                      </React.Fragment>
                    ))}
                  </Carousel>
                </>
              ) : (
                <>
                  <Carousel
                    itemPadding={[8, 10, 8, 10]}
                    breakPoints={breakPoints}
                    pagination={true}
                  >
                    <div
                      className="col-lg-4 pacakge-one text-start "
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid rgb(210, 210, 210)",
                        borderRadius: 5,
                      }}
                    >
                      <div className="package-name ">
                        <h3>
                          <Skeleton width={200} />
                        </h3>
                        <p>
                          <Skeleton width={100} />
                        </p>
                      </div>
                      <div
                        className="package-one-price checkss"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="discount">
                          <h4 className="">
                            <b>
                              <Skeleton width={300} />
                            </b>
                          </h4>
                        </div>
                        <div className="package-one-price">
                          <span className="amount">
                            {" "}
                            <Skeleton width={190} />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                      </ul>
                      <Skeleton
                        width={200}
                        style={{ paddingTop: 20, marginTop: 10 }}
                      />
                    </div>
                    <div
                      className="col-lg-4 pacakge-one text-start "
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid rgb(210, 210, 210)",
                        borderRadius: 5,
                      }}
                    >
                      <div className="package-name ">
                        <h3>
                          <Skeleton width={200} />
                        </h3>
                        <p>
                          <Skeleton width={100} />
                        </p>
                      </div>
                      <div
                        className="package-one-price checkss"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="discount">
                          <h4 className="">
                            <b>
                              <Skeleton width={300} />
                            </b>
                          </h4>
                        </div>
                        <div className="package-one-price">
                          <span className="amount">
                            {" "}
                            <Skeleton width={190} />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                      </ul>
                      <Skeleton
                        width={200}
                        style={{ paddingTop: 20, marginTop: 10 }}
                      />
                    </div>
                    <div
                      className="col-lg-4 pacakge-one text-start "
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid rgb(210, 210, 210)",
                        borderRadius: 5,
                      }}
                    >
                      <div className="package-name ">
                        <h3>
                          <Skeleton width={200} />
                        </h3>
                        <p>
                          <Skeleton width={100} />
                        </p>
                      </div>
                      <div
                        className="package-one-price checkss"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="discount">
                          <h4 className="">
                            <b>
                              <Skeleton width={300} />
                            </b>
                          </h4>
                        </div>
                        <div className="package-one-price">
                          <span className="amount">
                            {" "}
                            <Skeleton width={190} />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                      </ul>
                      <Skeleton
                        width={200}
                        style={{ paddingTop: 20, marginTop: 10 }}
                      />
                    </div>
                  </Carousel>
                </>
              )}
            </>
          </div>
        </div>
      </section>
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <DomainTips Data={technicalSpecificationsData} />
      <ComparePlans comparePlansState={wordpressHostingList} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default WordpressHosting;
