/* eslint-disable no-unused-vars */
import { Routes, Route, useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useNavigate } from "react-router-dom";
import { GetPackages, DomainSearchApi } from "./services/Network";
import { ToastContainer, toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Home from "./components/pages/Home";
import SharedHosting from "./components/pages/Hosting/SharedHosting";
import DomainSearch from "./components/reuseable/DomainSearch";
import WonFreeDomain from "./components/reuseable/WonFreeDomain";
import DedicatedHostname from "./components/reuseable/DedicatedHostname";
import Thankyou from "./components/reuseable/Thankyou";
import Thankyou1 from "./components/reuseable/Thankyou1";
import ThankyouNew from "./components/reuseable/ThankyouNew";
import GetHosting from "./components/reuseable/GetHosting";
import GetAddons from "./components/reuseable/GetAddons";
import Cart from "./components/pages/Cart/Cart";
import SslCertificate from "./components/pages/SslCertificate/SslCertificate";
import BussinessWebsite from "./components/pages/WebDevelopment/BussinessWebsite";
import DomainPricing from "./components/pages/Domian/DomainPricing";
import BussinessHosting from "./components/pages/Hosting/BussinessHosting";
import ResellerHosting from "./components/pages/Hosting/ResellerHosting";
import WordpressHosting from "./components/pages/Hosting/WordpressHosting";
import DedicatedServers from "./components/pages/Servers/DedicatedServers";
import PkBasedVps from "./components/pages/Servers/PkBasedVps";
import SSDVps from "./components/pages/Servers/SSDVps";
import Vps from "./components/pages/Servers/Vps";
import EcommerSolution from "./components/pages/WebDevelopment/EcommerSolution";
import ServerManagement from "./components/pages/ServerManagement/ServerManagement";
import About from "./components/pages/About";
import PaymentPage from "./components/pages/PaymentPage";
import Seo from "./components/pages/Seo";
import Policy from "./components/pages/Policy";

import BuyPkDomain from "./components/pages/Domian/BuyPkDomain";
import Privacy from "./components/pages/Privacy";
import WhyUs from "./components/pages/WhyUs";
import Team from "./components/pages/Team";
import AEDomains from "./components/pages/Domian/AEDomains";
import DomainTransfer from "./components/pages/Domian/DomainTransfer";
import WebHostingBestDeals from "./components/pages/LandingPages/WebHostingBestDeals";
import BestWebHosting from "./components/pages/LandingPages/BestWebHosting";
import BestWebHostingKarachi from "./components/pages/LandingPages/BestWebHostingKarachi";
import WebHostingDiscount from "./components/pages/LandingPages/WebHostingDiscount";
import CheapWebHosting from "./components/pages/LandingPages/CheapWebHosting";
import WordpressHostingOffer from "./components/pages/LandingPages/WordpressHostingOffer";
import CheapestWebHosting from "./components/pages/LandingPages/CheapestWebHosting";
import FastWebHosting from "./components/pages/LandingPages/FastWebHosting";
import BestSeoServices from "./components/pages/LandingPages/BestSeoServices";
import BestWebHostingDeal from "./components/pages/LandingPages/BestWebHostingDeal";
import WebHostingFreeDomain from "./components/pages/LandingPages/WebHostingFreeDomain";
import ContactUs from "./components/pages/ContactUs";
import Blog from "./components/pages/BlogPages/Blog";
import BlogSingle from "./components/pages/BlogPages/BlogSingle";

/////////////// My cart presistent
import React, { useEffect, useLayoutEffect, useState } from "react";
import AddDomain from "./components/pages/AddDomain/AddDomain";
import Faq from "./components/pages/FAQ/Faq";
import DedicatedSSLHostname from "./components/reuseable/DedicatedSSLHostname";
import { useDispatch, useSelector } from "react-redux";
import ThankyouSample from "./components/reuseable/ThankyouSample";
import Clients from "./components/pages/Clients";
import PkHosting from "./components/pages/Hosting/PkHosting";
//////////

function ErrorFallback({ error, resetErrorBoundary }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentURL = location.pathname;

  dispatch({
    type: "isError",
    payload: true,
  });
  console.log("ERror: ", error)
  return (
    <div style={{ overflowX: "clip" }} className="">
      <div className="row" style={{ placeContent: "center", paddingTop: "10%" }}>
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Oops! Something went wrong</h2>
          {/* <h4>
            {" "}
            Your Error is: <pre>{error}</pre>
          </h4> */}
          <Link onClick={_handelReset} to="/" className="we_are_hiring"><span style={{ padding: "15px 13px" }} className="celltext button_shape">Return to Home Page</span></Link>
          {/* <p>
            Please Try again{" "}
            <Link onClick={_handelReset} to="/">
              Return to homepages
            </Link>
          </p> */}
        </div>

        {/* <div className="col-md-8 col-sm-12 col-xs-12 mt-5 mx-auto">
          <p>Something went wrong:</p>
          <pre>{error.message}</pre>
          <button >Try again</button>
        </div> */}
      </div>
    </div>
  );
}


function Page404() {
  const location = useLocation();
  const currentURL = location.pathname;
 

  return (
    <div className="container animate__animated animate__fadeIn pt-5 mb-5">
      <div className="row" style={{ placeContent: "center" }}>
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Oops! Page Not Found</h2>
          <p>
            <Link to="/">Return to homepage</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

const _handelReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.clear();
  dispatch({
    type: "resetError",
  });
  navigate("/");
  //  window.history.go(-1);
  setTimeout(() => {
    window.location.reload();
  }, 4000);
  //history.goBack();
};

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_CLIENT_KEY}`
);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const routes = [
    { path: "/sample", element: <ThankyouSample /> },
    { path: "/clients", element: <Clients /> },
    { path: "/", element: <Home /> },
    { path: "/search", element: <Home /> },
    { path: "*", element: Page404() },
    { path: "/domain", element: <DomainSearch /> },
    { path: "/free-domain", element: <WonFreeDomain /> },
    { path: "/thankyou", element: <Thankyou /> },
    { path: "/preload", element: <Thankyou1 /> },
    { path: "/preload_new", element: <ThankyouNew /> },
    { path: "/dedicated-hostname", element: <DedicatedHostname /> },
    { path: "/dedicated-ssl-hostname", element: <DedicatedSSLHostname /> },
    { path: "/get-hosting", element: <GetHosting /> },
    { path: "/get-addons", element: <GetAddons /> },
    { path: "/about", element: <About /> },
    { path: "/contactus", element: <ContactUs /> },
    { path: "/payment-methods", element: <PaymentPage /> },
    { path: "/seo-services", element: <Seo /> },
    { path: "/policy", element: <Policy /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/whyus", element: <WhyUs /> },
    { path: "/team", element: <Team /> },
    { path: "/ssl-certificates", element: <SslCertificate /> },
    { path: "/server-management", element: <ServerManagement /> },
    { path: "/domain-registration", element: <DomainPricing /> },
    { path: "/addDomain", element: <AddDomain /> },
    { path: "/web-hosting-best-deals", element: <WebHostingBestDeals /> },
    { path: "/Best-Web-Hosting-Lahore", element: <BestWebHosting /> },
    { path: "/Best-Web-Hosting-Karachi", element: <BestWebHostingKarachi /> },
    { path: "/Web-Hosting-Discount", element: <WebHostingDiscount /> },
    { path: "/Cheap-Web-Hosting", element: <CheapWebHosting /> },
    { path: "/Wordpress-Hosting-Offer", element: <WordpressHostingOffer /> },
    { path: "/Cheapest-Web-Hosting", element: <CheapestWebHosting /> },
    { path: "/Fast-Web-Hosting", element: <FastWebHosting /> },
    { path: "/best-seo-services", element: <BestSeoServices /> },
    { path: "/best-web-hosting-deal", element: <BestWebHostingDeal /> },
    { path: "/Web-Hosting-Free-Domain", element: <WebHostingFreeDomain /> },
    { path: "web-hosting-with-free-domain-1", element: <WebHostingFreeDomain /> },
    { path: "/buy-pk-domain", element: <BuyPkDomain /> },
    { path: "/buy-ae-domains", element: <AEDomains /> },
    { path: "/domain-transfer", element: <DomainTransfer /> },
    { path: "/shared-hosting", element: <SharedHosting /> },
    { path: "/business-hosting", element: <BussinessHosting /> },
    { path: "/reseller-hosting", element: <ResellerHosting /> },
    { path: "/wordpress-hosting-in-pakistan", element: <WordpressHosting /> },
    { path: "/dedicated-server", element: <DedicatedServers /> },
    { path: "/pk-vps", element: <PkBasedVps /> },
    { path: "/ssd-vps", element: <SSDVps /> },
    { path: "/vps-hosting", element: <Vps /> },
    { path: "/web-development", element: <BussinessWebsite /> },
    { path: "/ecommerce-solution", element: <EcommerSolution /> },
    { path: "/thanku", element: <Thankyou /> },
    { path: "/faq", element: <Faq /> },
    { path: "/blog", element: <Blog /> },
  ];



  //redux states

  const { currencyName } = useSelector((state) => state.currencyName);
  const { packageCart } = useSelector((state) => state.packageCart);
  const { addingCycle } = useSelector((state) => state.addingCycle);
  const { addingCycleDomain } = useSelector((state) => state.addingCycleDomain);
  const { addonCart } = useSelector((state) => state.addonCart);
  const { domainSearchCart } = useSelector((state) => state.domainSearchCart);

  //////// my changhes
  //let localCart = localStorage.getItem("addingCycle");
  // var hours = 0.2; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  // var now = new Date().getTime();
  // var setupTime = localStorage.getItem("setupTime");
  // if (setupTime == null) {
  //   localStorage.setItem("setupTime", now);
  // } else {
  //   if (now - setupTime > hours * 60 * 60 * 1000) {
  //     localStorage.clear();
  //     localStorage.setItem("setupTime", now);
  //   }
  // }
  let pkcart = localStorage.getItem("packageCart");
  let adp = localStorage.getItem("addingCycle");

  let dcart = localStorage.getItem("domainSearchCart");
  let adddp = localStorage.getItem("addingCycleDomain");

  let client = localStorage.getItem("clientDetails");

  useEffect(() => {
    if (window.performance.navigation.type === 0) {
      //////////////  tab sync

      if (localStorage.getItem("packageCart")) {
        let cp = localStorage.getItem("packageCart");
        cp = JSON.parse(cp);

        for (var i = 0; i < cp.length; i++) {
          let newobj = {
            id: cp[i].id,
            addonids: cp[i].addonids,
            addons: cp[i].addons,
            currency: cp[i].currency,
            fname: cp[i].fname,
            freedomain: cp[i].freedomain,
            name: cp[i].name,
            packageFeatures: cp[i].packageFeatures,
            pid: cp[i].pid,
            title: cp[i].title,
            tid: cp[i].tid,
          };
          dispatch({
            type: "packageCart",
            payload: newobj,
          });
        }
      }

      //         if (localStorage.getItem("addingCycle").length == 0) {
      //           let ad = localStorage.getItem("addingCycle");
      //           ad = JSON.parse(ad);

      //           const adc = ad.find((o) => o.tid === cp[i].tid);

      //           if (adc) {
      //             dispatch({
      //               type: "addingCycle",
      //               payload: {
      //                 tid: cp[i].tid,
      //                 packageId: cp[i].pid,
      //                 packageCycle: adc.packageCycle,
      //               },
      //             });
      //           }
      //         }
      //       }
      //     }

      if (localStorage.getItem("domainSearchCart")) {
        let adserch = localStorage.getItem("domainSearchCart");
        adserch = JSON.parse(adserch);

        for (var pp = 0; pp < adserch.length; pp++) {
          if (adserch[pp].domainName.includes(".pk")) {
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: adserch[pp].domainName,
                price: adserch[pp].price,
                ispremium: adserch[pp].ispremium,
                value: 1,
                // domainAddonTitle: "Privacy Protection",
                //  domainAddon: response.data.domainAddon[0],
              },
            });
          } else {
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: adserch[pp].domainName,
                price: adserch[pp].price,
                ispremium: adserch[pp].ispremium,
                value: 1,
                domainAddonTitle: "Privacy Protection",
                domainAddon: adserch[pp].domainAddon,
              },
            });
          }
        }
      }

      //////
    } else {
      if (localStorage.getItem("packageCart")) {
        let packagecartLocal = localStorage.getItem("packageCart");
        packagecartLocal = JSON.parse(packagecartLocal);

        for (var i = 0; i < packagecartLocal.length; i++) {
          GetPackages([packagecartLocal[i].pid]).then((res) => {
            let newobj = {
              id: res[0].id,
              addonids: res[0].addonids,
              addons: res[0].addons,
              currency: res[0].currency,
              fname: res[0].fname,
              freedomain: [],
              name: res[0].name,
              packageFeatures: res[0].packageFeatures,
              pid: res[0].pid,
              title: res[0].title,
              tid: res[0].tid,
            };

            dispatch({
              type: "packageCart",
              payload: newobj,
            });
          });

          // if(cp[i].addons.length>0){

          //   dispatch({
          //     type: "addonsExrra",
          //     payload: cp[i].addons,
          //   });
          // }

          //hrere uncomment

          if (localStorage.getItem("addingCycle").length == 0) {
            let ad = localStorage.getItem("addingCycle");
            ad = JSON.parse(ad);

            const adc = ad.find((o) => o.tid === packagecartLocal[i].tid);

            if (adc) {
              dispatch({
                type: "addingCycle",
                payload: {
                  tid: packagecartLocal[i].tid,
                  packageId: packagecartLocal[i].pid,
                  packageCycle: adc.packageCycle,
                },
              });
            }
          }
        }
      }

      if (localStorage.getItem("domainSearchCart")) {
        let domainSearchcartLocal = localStorage.getItem("domainSearchCart");
        domainSearchcartLocal = JSON.parse(domainSearchcartLocal);
        let domainSearchcartCuycleLocal =
          localStorage.getItem("addingCycleDomain");

        for (let pp = 0; pp < domainSearchcartLocal.length; pp++) {
          DomainSearchApi(
            domainSearchcartLocal[pp].domainName,
            "com",
            domainSearchcartLocal[pp].name
          ).then((res) => {
            if (res.searchedDomain.includes(".pk")) {
              dispatch({
                type: "domainSearchCart",
                payload: {
                  name: "domainregister",
                  domainName: res.searchedDomain,
                  price: res.price,
                  ispremium: res.ispremium,
                  value: 1,
                  // domainAddonTitle: "Privacy Protection",
                  //  domainAddon: response.data.domainAddon[0],
                },
              });
              const isExits = domainSearchcartCuycleLocal.filter(
                (elsss) => elsss.domainName === res.searchedDomain
              );
              if (isExits) {
                dispatch({
                  type: "addingCycleDomain",
                  payload: {
                    domainName: res.searchedDomain,
                    packageCycle: "annually",
                  },
                });
              }
            } else {
              dispatch({
                type: "domainSearchCart",
                payload: {
                  name: "domainregister",
                  domainName: res.searchedDomain,
                  price: res.price,
                  ispremium: res.ispremium,
                  value: 1,
                  domainAddonTitle: "Privacy Protection",
                  domainAddon: res.domainAddon[0],
                },
              });
              const isExits = domainSearchcartCuycleLocal.filter(
                (elsss) => elsss.domainName === res.searchedDomain
              );
              if (isExits) {
                dispatch({
                  type: "addingCycleDomain",
                  payload: {
                    domainName: res.searchedDomain,
                    packageCycle: "annually",
                  },
                });
              }
            }
          });
        }
      }
    }
  }, [window.performance.navigation.type]);

  useEffect(() => {
    if (localStorage.getItem("clientDetails")) {
      let adsss = localStorage.getItem("clientDetails");
      adsss = JSON.parse(adsss);
      dispatch({
        type: "clientDetails",
        payload: adsss,
      });
    }

    if (
      localStorage.getItem("packageCart") &&
      localStorage.getItem("addingCycle")
    ) {
      let pk = localStorage.getItem("packageCart");
      pk = JSON.parse(pk);
      let ad = localStorage.getItem("addingCycle");
      ad = JSON.parse(ad);
      let pary = [];
      let ary = [];
      for (let p = 0; p < pk.length; p++) {
        pary.push(pk[p].tid);
      }
      for (let a = 0; a < ad.length; a++) {
        ary.push(ad[a].tid);
      }

      for (let b = 0; b < ary.length; b++) {
        if (pary.includes(ary[b])) {
        } else {
          dispatch({
            type: "removePackageAddingCycleNew",
            payload: ary[b],
          });
        }
      }
    }

    if (localStorage.getItem("addingCycle")) {
      let ad = localStorage.getItem("addingCycle");
      ad = JSON.parse(ad);

      for (var p = 0; p < ad.length; p++) {
        dispatch({
          type: "addingCycle",
          payload: {
            tid: ad[p].tid,
            packageId: ad[p].packageId,
            packageCycle: ad[p].packageCycle,
          },
        });
      }
    }

    //     if (localStorage.getItem("packageCart")) {
    //       let cp = localStorage.getItem("packageCart");
    //       cp = JSON.parse(cp);

    //       for (var i = 0; i < cp.length; i++) {
    //         dispatch({
    //           type: "packageCart",
    //           payload: cp[i],
    //         });

    //         if (localStorage.getItem("addingCycle").length == 0) {
    //           let ad = localStorage.getItem("addingCycle");
    //           ad = JSON.parse(ad);

    //           const adc = ad.find((o) => o.tid === cp[i].tid);

    //           if (adc) {
    //             dispatch({
    //               type: "addingCycle",
    //               payload: {
    //                 tid: cp[i].tid,
    //                 packageId: cp[i].pid,
    //                 packageCycle: adc.packageCycle,
    //               },
    //             });
    //           }
    //         }
    //       }
    //     }

    // if (localStorage.getItem("domainSearchCart")) {
    //   let adserch = localStorage.getItem("domainSearchCart");
    //   adserch = JSON.parse(adserch);

    //   for (var pp = 0; pp < adserch.length; pp++) {
    //     if (adserch[pp].domainName.includes(".pk")) {
    //       dispatch({
    //         type: "domainSearchCart",
    //         payload: {
    //           name: "domainregister",
    //           domainName: adserch[pp].domainName,
    //           price: adserch[pp].price,
    //           ispremium: adserch[pp].ispremium,
    //           value: 1,
    //           // domainAddonTitle: "Privacy Protection",
    //           //  domainAddon: response.data.domainAddon[0],
    //         },
    //       });
    //     } else {
    //       dispatch({
    //         type: "domainSearchCart",
    //         payload: {
    //           name: "domainregister",
    //           domainName: adserch[pp].domainName,
    //           price: adserch[pp].price,
    //           ispremium: adserch[pp].ispremium,
    //           value: 1,
    //           domainAddonTitle: "Privacy Protection",
    //           domainAddon: adserch[pp].domainAddon,
    //         },
    //       });
    //     }
    //   }
    // }

    if (localStorage.getItem("addingCycleDomain")) {
      let adcyc = localStorage.getItem("addingCycleDomain");
      adcyc = JSON.parse(adcyc);

      // return;
      for (var p = 0; p < adcyc.length; p++) {
        if (adcyc[p].domainName != "") {
          dispatch({
            type: "addingCycleDomain",
            payload: {
              domainName: adcyc[p].domainName,
              packageCycle: adcyc[p].packageCycle,
            },
          });
        }
      }
    }
  }, [pkcart, adp, dcart, adddp]);

  useEffect(() => {
    const currentTimestamp = Date.now();
    const previousTimestamp = localStorage.getItem('timestamp');

    if (previousTimestamp) {
      const elapsedTime = currentTimestamp - previousTimestamp;
      if (elapsedTime >= 15 * 60 * 1000) {
        localStorage.clear();
        dispatch({
          type: "resetdomainSearchCart",
        });
        dispatch({
          type: "resetaddonCart",
        });
        dispatch({
          type: "resetclientDetails",
        });
        dispatch({
          type: "resetcartPayment",
        });
        dispatch({
          type: "resetpackageCart",
        });
        dispatch({
          type: "resetclientDomainName",
        });
      } else {
        localStorage.setItem('timestamp', currentTimestamp);
      }
    } else {
      localStorage.setItem('timestamp', currentTimestamp);
    }
  }, []);


  //don't touch these

  const [route, setRoute] = useState(null);

  useLayoutEffect(() => {
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const queryParams = parsedUrl.searchParams;

    if (queryParams.has('q')) {
      const cacheUrl = queryParams.get('q');
      const parsedCacheUrl = new URL(cacheUrl);

      const pathSegments = parsedCacheUrl.pathname.split('/');
      const extractedRoute = pathSegments[pathSegments.length - 1];
      console.log("extractedRoute: ", extractedRoute)
      console.log("extractedRoute type: ",typeof extractedRoute)
      setRoute(extractedRoute);
    }
  }, []);


  return (
    <>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          _handelReset();
        }}
      >
        <ToastContainer />
        <Routes>
          {/* 
          {
            route === null ? <Route exact path="/search" element={<Home />} /> : null
          }
          {
            route === "shared-hosting" ? <Route exact path="/search" element={<SharedHosting />} /> : null
          }
          {
            route === "business-hosting" ? <Route exact path="/search" element={<BussinessHosting />} /> : null
          }
          {
            route === "reseller-hosting" ? <Route exact path="/search" element={<ResellerHosting />} /> : null
          }
          {
            route === "wordpress-hosting-in-pakistan" ? <Route exact path="/search" element={<WordpressHosting />} /> : null
          } */}

          <Route exact path="/search" element={<Home />} />
          <Route path="/sample" element={<ThankyouSample />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={Page404()} />
          <Route path="/domain" element={<DomainSearch />} />
          <Route path="/free-domain" element={<WonFreeDomain />} />
          <Route path="/thankyou" element={<Thankyou />} />

          <Route path="/preload" element={<Thankyou1 />} />

          <Route path="/preload_new" element={<ThankyouNew />} />

          <Route path="/dedicated-hostname" element={<DedicatedHostname />} />
          <Route
            path="/dedicated-ssl-hostname"
            element={<DedicatedSSLHostname />}
          />

          <Route path="/get-hosting" element={<GetHosting />} />
          <Route path="/get-addons" element={<GetAddons />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/payment-methods" element={<PaymentPage />} />
          <Route path="/seo-services" element={<Seo />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/whyus" element={<WhyUs />} />
          <Route path="/team" element={<Team />} />

          <Route path="/ssl-certificates" element={<SslCertificate />} />
          <Route path="/server-management" element={<ServerManagement />} />

          <Route path="/cart" element={<Elements stripe={stripePromise}><Cart /></Elements>} />

          <Route path="/domain-registration" element={<DomainPricing />} />
          <Route path="/addDomain" element={<AddDomain />} />

          {/* Landing Pages */}
          <Route
            path="/web-hosting-best-deals"
            element={<WebHostingBestDeals />}
          />
          <Route path="/Best-Web-Hosting-Lahore" element={<BestWebHosting />} />
          <Route
            path="/Best-Web-Hosting-Karachi"
            element={<BestWebHostingKarachi />}
          />
          <Route
            path="/Web-Hosting-Discount"
            element={<WebHostingDiscount />}
          />
          <Route path="/Cheap-Web-Hosting" element={<CheapWebHosting />} />
          <Route
            path="/Wordpress-Hosting-Offer"
            element={<WordpressHostingOffer />}
          />
          <Route
            path="/Cheapest-Web-Hosting"
            element={<CheapestWebHosting />}
          />
          <Route path="/Fast-Web-Hosting" element={<FastWebHosting />} />
          <Route path="/best-seo-services" element={<BestSeoServices />} />
          <Route
            path="/best-web-hosting-deal"
            element={<BestWebHostingDeal />}
          />
          <Route
            path="/Web-Hosting-Free-Domain"
            element={<WebHostingFreeDomain />}
          />
          <Route
            path="web-hosting-with-free-domain-1"
            element={<WebHostingFreeDomain />}
          />

          <Route path="/buy-pk-domain" element={<BuyPkDomain />} />
          <Route path="/buy-ae-domains" element={<AEDomains />} />
          <Route path="/domain-transfer" element={<DomainTransfer />} />

          {/* Hosting Routes */}

          <Route path="/shared-hosting" element={<SharedHosting />} />
          <Route path="/business-hosting" element={<BussinessHosting />} />
          <Route path="/reseller-hosting" element={<ResellerHosting />} />
          <Route
            path="/wordpress-hosting-in-pakistan"
            element={<WordpressHosting />}
          />
          <Route path="/pk-hosting" element={<PkHosting />} />

          {/* Server Routes */}

          <Route path="/dedicated-server" element={<DedicatedServers />} />
          <Route path="/pk-vps" element={<PkBasedVps />} />
          <Route path="/ssd-vps" element={<SSDVps />} />
          <Route path="/vps-hosting" element={<Vps />} />

          {/* Web Development Routes */}
          <Route path="/web-development" element={<BussinessWebsite />} />
          <Route path="/ecommerce-solution" element={<EcommerSolution />} />
          <Route path="/thanku" element={<Thankyou />} />

          <Route path="/faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogSingle />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </>
  );
}

export default App;