import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

const BlogSingle = () => {
  const location = useLocation();
  const params = useParams();

  const slug = params.slug;

  const [data, setData] = useState(null);

  const getData = () => {
    console.log("slug", slug);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/posts/slug/${slug}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("data", data);

  const PF = `${process.env.REACT_APP_BASE_URL}images`;

  function dateFormat(inputDate, format) {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  }

  return (
    <>
      <Helmet>
        <title>{data?.title}</title>
        <meta name="title" content={data?.meta_title} />
        <meta name="description" content={data?.meta_description} />
        <meta name="keywords" content="" />
      </Helmet>
      <section
        className="banner-size d-flex align-items-center height-adjust bg-overlay"
        style={{
          backgroundColor: "#ebf2f5",
          height: "400px",
          backgroundImage: `url(${PF + data?.image})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              <div className="w-100 text-center">
                <h2 className="first-heading color-b">{data?.title}</h2>
                <p className="second-heading">{data?.excrept}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog_detail_sction">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div>
                <div
                  className="padding_both_sides"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                />
                <p>
                  <span>
                    <span className="post_author">{data?.author} - </span>
                    {dateFormat(data?.createdAt, "dd-MM-yyyy")}
                  </span>
                </p>
                {/* <div className="row mt-4">
                  <div className="col-md-6 col-sm-6 col-xs-6">
                    <a
                      href="web-hosting-vs-domain-hosting-everything-you-need-to-know"
                      className="Previous_btn"
                    >
                      Previous Article
                    </a>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-6"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSingle;

