import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCurrencies,
  GetustomerIp,
  GetustomerCityNew,
  GetustomerCity,
  GetCustomerCityFromNode,
  clientAreaSSOToken,
} from "../../services/Network.js";

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode?.current?.contains(event?.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Topbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currencyList, setCurrencyList] = useState([]);
  const [currencyDrowpDown, setCurrencyDrowpDown] = useState();
  const [myAccountDrowpDown, setMyAccountDropDown] = useState(false);

  const hostingPackages = [
    125, 130, 320, 126, 131, 322, 107, 154, 284, 108, 155, 204, 110, 112, 297,
    279, 280, 281, 321,
  ];

  const [myCity, setMyCity] = useState("");
  const [myIp, setMyIp] = useState("");
  const [phone, setphone] = useState("");

  const [cartBypass, setCartByPass] = useState(true);

  const [myCountry, setmyCountry] = useState("");

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { domainSearchCart } = useSelector((state) => state.domainSearchCart);
  const { packageCart } = useSelector((state) => state.packageCart);
  const { clientDetails } = useSelector((state) => state.clientDetails);
  const { currentUrl } = useSelector((state) => state.currentUrl);

  function checkPackageCart(hostingPackages, packageCart) {
    for (const cartItem of packageCart) {
      const { pid, freedomain } = cartItem;
      if (hostingPackages.includes(pid) && freedomain.length === 0) {
        return false;
      }
    }
    return true;
  }

  useLayoutEffect(() => {
    // console.log("packageCart : ", packageCart);
    // const pids = packageCart.map((item) => item.pid);
    // console.log("pids: ", pids);
    // const containsAllPids =
    //   packageCart.length === 0 ||
    //   pids.every((pid) => hostingPackages.includes(pid));
    // console.log("containsAllPids: ", containsAllPids);

    //console.log("result ::: ", result); // Output: false

    console.log("currentUrl:   ", currentUrl);
    if (currentUrl === "/free-domain") {
      const result = checkPackageCart(hostingPackages, packageCart);
      setCartByPass(result);
    } else {
      setCartByPass(true);
    }
  }, [packageCart, currentUrl]);

  useEffect(() => {
    if (localStorage.getItem("currencyName")) {
      let ad = localStorage.getItem("currencyName");
      ad = JSON.parse(ad);
      //return
      dispatch({
        type: "addCurrency",
        payload: {
          _id: ad._id,
          api_id: ad.api_id,
          code: ad.code,
          prefix: ad.prefix,
          suffix: ad.suffix,
          format: ad.format,
          rate: ad.rate,
        },
      });
    }
    GetCurrencies().then((res) => {
      setCurrencyList(res);
      dispatch({
        type: "currencySetting",
        payload: res,
      });
      // GetustomerCity().then((resCountry) => {

      //   if (resCountry.country_name !== "Pakistan") {
      //     dispatch({
      //       type: "addCurrency",
      //       payload: {
      //         _id: "62de39cc68eb820a745477f1",
      //         api_id: 1,
      //         code: "USD",
      //         prefix: "$",
      //         suffix: "",
      //         format: 1,
      //         rate: "1.00000",
      //         createdAt: "2022-07-25T06:35:56.624Z",
      //         updatedAt: "2022-07-25T06:35:56.624Z",
      //         __v: 0,
      //       },
      //     });
      //   }
      // });

      GetustomerIp().then((res) => {
        setMyIp(res.IPv4);
        setTimeout(() => {
          GetCustomerCityFromNode(res).then((ress) => {
            setMyCity(ress.city);
            setphone(ress.phone);
            if (ress.country !== "Pakistan") {
              dispatch({
                type: "addCurrency",
                payload: {
                  _id: "62de39cc68eb820a745477f1",
                  api_id: 1,
                  code: "USD",
                  prefix: "$",
                  suffix: "",
                  format: 1,
                  rate: "1.00000",
                  createdAt: "2022-07-25T06:35:56.624Z",
                  updatedAt: "2022-07-25T06:35:56.624Z",
                  __v: 0,
                },
              });
            }
          });
        }, 2000);
      });
    });
  }, []);

  const RedirectToClientPortal = (e) => {
    e.preventDefault();
    let clientId = localStorage.getItem("client_id");
    clientAreaSSOToken(clientId)
      .then((ress) => {
        window.open(ress.redirect_url);
        console.log("Res: ", ress);
      })
      .catch((err) => {
        console.log("Error");
      });
  };

  const handelLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const totalCartLength = domainSearchCart.length + packageCart.length;

  let domNode = useClickOutside(() => {
    setCurrencyDrowpDown(false);
  });

  let domNodes = useClickOutside(() => {
    setMyAccountDropDown(false);
  });

  //changing the currency drowp down hande

  const handelCurrencyDropDown = (e) => {
    e.preventDefault();
    setCurrencyDrowpDown(!currencyDrowpDown);
  };

  //onClick Show MyAccount DropDown

  const handelMyAccountDropDown = (e) => {
    e.preventDefault();
    setMyAccountDropDown(!myAccountDrowpDown);
  };

  //handling price changes

  const handelPriceChnage = (e, item) => {
    e.preventDefault();

    dispatch({
      type: "addCurrency",
      payload: item,
    });
    setCurrencyDrowpDown(false);
  };

  return (
    <div id="top_bar_scroll" className="top_bar">
      <div className="container">
        <div className="languages">
          <ul>
            <li className="click_languages">
              <Link to="#" onClick={(e) => handelCurrencyDropDown(e)}>
                <i className="fa fa-globe" />
                {currencyName.code}
                <i className="fa fa-caret-down" />
              </Link>
              {clientDetails.length === 0 ? (
                <>
                  {currencyDrowpDown ? (
                    <ul
                      ref={domNode}
                      className="dropdown"
                      tabIndex="0"
                      style={{ display: "block" }}
                    >
                      {currencyList.map((cur) => (
                        <React.Fragment key={cur.api_id}>
                          <li>
                            <Link
                              to="#"
                              onClick={(e, item) => handelPriceChnage(e, cur)}
                              className="currency_switcher"
                            >
                              <img
                                src={`assests/img/${cur.code}.png`}
                                className="max-width"
                                alt={cur.code}
                              />
                              {cur.code} ({cur.prefix})
                            </Link>
                          </li>
                        </React.Fragment>
                      ))}
                    </ul>
                  ) : null}
                </>
              ) : null}
            </li>
          </ul>
        </div>
        <div className="contact_info">
          <span className="ticket d-none d-md-block">
            <Link to="/payment-methods">
              <span className="ticketimg ">
                <i style={{ fontSize: 14 }} className="fa fa-credit-card"></i>
              </span>
              <span className="tikttxt">Payment Method</span>
            </Link>
          </span>
          {/* <span className="cell">
            <Link to="#">
              <span className="cellimg">
                <i style={{ fontSize: 16 }} className="fa fa-phone-square"></i>
              </span>
              {myCity !== "Lahore" ? (
                <span className="celltext">+92 321 4776996</span>
              ) : (
                <span className="celltext">+92 300 2002122</span>
              )}
            </Link>
          </span> */}
          <span className="cell">
            <Link to="#">
              <span className="cellimg">
                <i style={{ fontSize: 16 }} className="fa fa-phone-square"></i>
              </span>

              <span className="celltext">{phone}</span>
            </Link>
          </span>
          {localStorage.getItem("client_id") !== null ? (
            <>
              <span className="ticket">
                <Link onClick={(e) => handelLogout(e)} to="/">
                  <span className="tikttxt">Logout</span>
                </Link>
              </span>
              <span className="my_account">
                <a
                  onClick={(e) => RedirectToClientPortal(e)}
                  target="_blank"
                  style={{ cursor: "pointer", padding: 0 }}
                  href="https://billing.websouls.com/clientarea.php"
                >
                  <span className="accounttxt">Client Area</span>
                </a>
              </span>
            </>
          ) : (
            <>
              <span className="my_account">
                {/* <Link to="#" onClick={(e) => handelMyAccountDropDown(e)}> */}
                <a
                  target="_blank"
                  style={{ cursor: "pointer", padding: 0 }}
                  href="https://billing.websouls.com/clientarea.php"
                >
                  <span className="accounttxt">
                    Client Area
                    {/* <i className="fa fa-caret-down"></i> */}
                  </span>
                </a>
                {/* </Link> */}
                {myAccountDrowpDown
                  ? ""
                  : // <div
                    //   ref={domNodes}
                    //   className="dropdown_content"
                    //   style={{ display: "block" }}
                    // >
                    //   <div className="container">
                    //     <ul className="row list-unstyled">
                    //       <li className="col-md-6 col-sm-12 col-xs-12">
                    //         <div className="sign_up">
                    //           <h3>New to WebSouls?</h3>
                    //           <p>Create an account to get started today.</p>
                    //           <a
                    //             target="_blank"
                    //             style={{ cursor: "pointer" }}
                    //             href="https://billing.websouls.com/register.php"
                    //           >
                    //             sign up
                    //           </a>
                    //         </div>
                    //       </li>
                    //       <li className="col-md-6 col-sm-12 col-xs-12">
                    //         <div className="sign_up sign_in">
                    //           <h3>Registered Users</h3>
                    //           <p>Have an account? Sign in now.</p>
                    //           <a
                    //             target="_blank"
                    //             style={{ cursor: "pointer" }}
                    //             href="https://billing.websouls.com/clientarea.php"
                    //           >
                    //             sign in
                    //           </a>
                    //         </div>
                    //       </li>
                    //     </ul>
                    //   </div>
                    // </div>
                    null}
              </span>
            </>
          )}

          <span className="ticket d-block">
            {cartBypass ? (
              <Link to="/cart">
                <i className="fa fa-shopping-cart">
                  <span id="circle_count_id" className="circle_count">
                    {totalCartLength}
                  </span>
                </i>
              </Link>
            ) : (
              <Link to="#">
                <i className="fa fa-shopping-cart">
                  <span id="circle_count_id" className="circle_count">
                    {totalCartLength}
                  </span>
                </i>
              </Link>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
