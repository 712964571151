import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  stripe,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { GetClienSevert, SSOToken } from "../../../services/Network";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const Stripee = ({ stripeData }) => {
  // console.log("stripeData::: ", stripeData);
  const strip = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [clientSerect, setClientSecert] = useState("");
  const [errorState, setErrorState] = useState("");
  const [onClick, setOnClick] = useState(false);
  const [anotherClick, setAnotherClick] = useState(false);

  const [dessss, setDesss] = useState("");
  const [stDessss, setStrDesss] = useState("");

  const clientIDs = localStorage.getItem("client_id");
  const clientEmail = localStorage.getItem("email");
  const clientFirstName = localStorage.getItem("first_name");
  const clientILastName = localStorage.getItem("last_name");
  const clientPhone = localStorage.getItem("phonenumber");
  const clientFullName = `${clientFirstName} ${clientILastName}`;


  const { currencyName } = useSelector((state) => state.currencyName);

  const cardStyle = {
    style: {
      base: {
        color: "#414042",
        padding: ".375rem .75rem",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontWeight: "400",
        fontSize: "16px",
        "::placeholder": {
          color: "#757D85",
        },
        ":focus::placeholder": {
          color: "transparent",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#414042",
        iconColor: "#414042",
      },
    },
  };

  const baseStyle = {
    base: {
      color: "#414042",
      padding: ".375rem .75rem",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontWeight: "400",
      fontSize: "16px",
      "::placeholder": {
        color: "#757D85",
      },
      ":focus::placeholder": {
        color: "transparent",
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#414042",
      iconColor: "#414042",
    },
  };

  useEffect(() => {


    setStrDesss(`WEBSOULS* INVOICE ${stripeData.invoice_id}`);
    setDesss(`Invoice ${stripeData.invoice_id}`);

    var cardNumberElement = elements.create("cardNumber", {
      style: baseStyle,
      placeholder: "ENTER YOUR CARD NUMBER",
    });
    cardNumberElement.mount("#card-number-element");
    const fetchClientSecert = async () => {
      if (currencyName.code.toLowerCase() === "usd") {
        GetClienSevert(
          parseFloat(stripeData.order_total),
          currencyName.code.toLowerCase(),
          clientEmail,
          clientFullName,
          clientPhone,
          `Invoice ${stripeData.invoice_id}`,
          `WEBSOULS* INVOICE ${stripeData.invoice_id}`.substring(0, 22),
          `${stripeData.invoice_id}`

        ).then((res) => {
          // console.log("res:", res.clientSecret);
          setClientSecert(res.clientSecret);
        });
      } else {
        GetClienSevert(
          parseInt(stripeData.order_total),
          currencyName.code.toLowerCase(),
          clientEmail,
          clientFullName,
          clientPhone,
          `Invoice ${stripeData.invoice_id}`,
          `WEBSOULS* INVOICE ${stripeData.invoice_id}`.substring(0, 22),
          `${stripeData.invoice_id}`
        ).then((res) => {
          // console.log("res:", res.clientSecret);
          setClientSecert(res.clientSecret);
        });
      }
    };
    fetchClientSecert();
  }, []);

  const Paynows = (e) => {
    setOnClick(true);
    // if (!stripe || !elements) {
    //   // Stripe.js has not loaded yet. Make sure to disable
    //   // form submission until Stripe.js has loaded.
    //   return;
    // }
    // e.preventDefault();
    // stripe.paymentMethods.create({
    //   type: 'card',
    //   card: {
    //     number: '4242424242424242',
    //     exp_month: 8,
    //     exp_year: 2023,
    //     cvc: '314',
    //   },
    // });
    strip
      .confirmCardPayment(clientSerect, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      })
      .then((res) => {
        console.log("res stripeee:", res);
        if (res.error) {
          setErrorState(res.error.message);
          setOnClick(false);
        }
        if (res.paymentIntent) {
          axios
          .post(
            `${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`,
            null,
            {
              params: {
                action: "AddTransaction",
                // username: `${process.env.REACT_APP_User}`,
                // password: `${process.env.REACT_APP_Pass}`,
                paymentmethod: stripeData.paymentmethod,
                invoiceid:stripeData.invoice_id,
                userid: clientIDs,
                transid: Date.now() + Math.floor(Math.random() * 100),
                date: moment(stripeData.invoice_date).format('DD/MM/YYYY'),
                description: dessss,
                amountin: stripeData.order_total,
                fees: "",
                rate: currencyName.rate,
                responsetype: "json",
              },
            }
          )
          .then((res) => {
            setOnClick(false);
            navigate("/thankyou", {
              state: stripeData,
            });
          });
        }
      })
      .catch((err) => console.warn(err));
  };

  const tryMethod = (e) => {
    e.preventDefault();
    setAnotherClick(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "UpdateInvoice",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          invoiceid: stripeData.invoice_id,
          date: moment().format("YYYY-MM-DD"),
          paymentmethod: "jazzcashgateway",
          responsetype: "json",
        },
      })
      .then((res) => {
        setTimeout(() => {
          SSOToken(clientIDs, stripeData.invoice_id).then((resss) => {
            //console.log("result ",resss);
            //return;
            localStorage.setItem("ssco", resss.access_token);
            localStorage.removeItem("ssco");
            localStorage.removeItem("total");
            setAnotherClick(false);
            window.location.replace(resss.redirect_url);
          });
        }, 1000);
      });
  };
  return (
    <>
      <div className="chose_payment_method mb-2">
        <h6 className="result-message p-4">
          You order has been received, please pay now to
          complete this order!
        </h6>
        {/* <span
          style={{
            padding: "0.5rem 0.5rem",
            marginLeft: 15,
            marginRight: 15,
            fontSize: "14px",
            color: "red",
          }}
        >
          {errorState}
        </span> */}

        {errorState === "" ? null : (
          <div
            className="px-3  d-flex"
            style={{
              marginBottom: "1rem",
              alignItems: "center",
              paddingTop: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                marginBottom: 0,
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              <p style={{ color: "#414042" }}>{errorState}</p>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              {anotherClick ? (
                <button
                  className="ml-0"
                  style={{
                    padding: 0,
                    cursor: "not-allowed",
                    color: "#8f8f8f",
                    backgroundColor: "transparent",
                    border: "none"
                  }}
                  disabled={true}
                >
                  <i
                    style={{ fontSize: "20px" }}
                    className="fa fa-spinner fa-spin"
                  ></i>
                </button>
              ) : (
                <button
                  style={{ background: "transparent", border: "none" }}
                  className=""
                >
                  <Link
                    className="asd123asdasd1"
                    onClick={(e) => tryMethod(e)}
                    to="#"
                  >
                    <u style={{ color: "#005880" }}>
                      Try another payment method
                    </u>
                  </Link>
                </button>
              )}
            </div>
          </div>
        )}

        <div className={errorState === "" ? "asrwqsadasdasd" : ""}>
          {/* <label htmlFor="cardNumber">Card Number</label>
          <CardNumberElement id="cardNumber" />
          <label htmlFor="expiry">Card Expiration</label>
          <CardExpiryElement id="expiry" />
          <label htmlFor="cvc">CVC</label>
          <CardCvcElement id="cvc" /> */}
          <div className="">
            <div
              className=" align-items-center justify-content-between card-atm border rounded"
              style={{
                backgroundColor: "#e9ecef",
                padding: "0.5rem 0.5rem",
                marginLeft: 15,
                marginRight: 15,
              }}
            >
              <div id="card-number-element" className="field"></div>
              {/* <CardNumberElement options={cardStyle} id="cardNumber" placeholder='Custom card number placeholder' /> */}
            </div>
            <div className="row">
              <div className="col-md-6 col-ms-12 col-xs-12">
                {" "}
                <div
                  className=" align-items-center justify-content-between card-atm border rounded"
                  style={{
                    backgroundColor: "#e9ecef",
                    padding: "0.5rem 0.5rem",
                    marginLeft: 15,
                    marginTop: 15,
                  }}
                >
                  <CardExpiryElement options={cardStyle} id="expiry" />
                </div>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div
                  className=" align-items-center justify-content-between card-atm border rounded"
                  style={{
                    backgroundColor: "#e9ecef",
                    padding: "0.5rem 0.5rem",
                    marginRight: 15,
                    marginTop: 15,
                  }}
                >
                  <CardCvcElement options={cardStyle} id="cvc" />
                </div>
              </div>
            </div>
          </div>
          {onClick ? (
            <>
              <button
                className="button-sigup pacakgenotAllowedsubmit mt-3 ml-0 mx-3"
                disabled={true}
              >
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-spinner fa-spin"
                ></i>
              </button>
            </>
          ) : (
            <button
              onClick={(e) => Paynows(e)}
              className="button-sigup mt-3 ml-0 mx-3"
            >
              Pay Now!
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Stripee;
