import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  tested,
  getPackageBaseCycle,
} from "../../../services/Helper";
import { GetActivePromotions, GetPackages } from "../../../services/Network";

const RightHosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const [packageRecomended, setPackageRecomended] = useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [rightHostingPid] = useState([184, 107, 324, 280]);

  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
  }, []);

  useEffect(() => {
    GetActivePromotions().then((ress) => {
      localStorage.setItem("pr56m", JSON.stringify(ress));
      setActivePromotions(ress);
    });
    GetPackages(rightHostingPid).then((res) => {
      setPackageRecomended(res);
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  const buttonAddToCartVps = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: getPackageBaseCycle(item, currencyName.api_id),
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/dedicated-hostname");
  };

  const getPriceRightHosting = (normalPrice, pid, duration, activePromotions, currencyCode, year) => {
    var price = [];
    if (activePromotions[0]?.appliesto?.includes(pid)) {
      const promoDuration = activePromotions[0]?.cycles
        ?.toLowerCase()
        ?.split(",");
  
      var discount = activePromotions[0]?.value;
      for (var i = 0; i < promoDuration?.length; i++) {
        if (duration == promoDuration[i]) {
          var pd = promoDuration[i];
          break;
        }
      }
      var promo_duration;
      if (pd == "Monthly" || pd == "monthly") {
        promo_duration = 1;
      }
      if (pd == "Quarterly" || pd == "quarterly") {
        promo_duration = 3;
      }
      if (pd == "Semi-Annually" || pd == "semiannually") {
        promo_duration = 6;
      }
      if (pd == "Annually" || pd == "annually") {
        promo_duration = 12;
      }
      if (pd == "Biennially" || pd == "biennially") {
        promo_duration = 24;
      }
      if (pd == "Triennially" || pd == "triennially") {
        promo_duration = 36;
      }
  
      var factor;
      if (duration == "Monthly" || duration == "monthly") {
        factor = 1;
      }
      if (duration == "Quarterly" || duration == "quarterly") {
        factor = 3;
      }
      if (duration == "Semi-Annually" || duration == "semiannually") {
        factor = 6;
      }
      if (duration == "Annually" || duration == "annually") {
        factor = 12;
      }
      if (duration == "Biennially" || duration == "biennially") {
        factor = 24;
      }
      if (duration == "Triennially" || duration == "triennially") {
        factor = 36;
      }
      //// formula
      var factordiscount = normalPrice - normalPrice * (discount / 100);
      if (factor <= 12) {
        var d = factor - promo_duration;
      }
      if (factor >= 12) {
        var d = factor / 12 - promo_duration / 12;
      }
      var p = (factordiscount + d * normalPrice).toFixed(2);
      var dc = p?.split(".");
      price[0] = 1; // discount check
      var np = normalPrice?.split(".")
      console.log("np: ", np[1])
      price[1] = normalPrice; // normal pprice
      price[2] = p; // dis. pprice full
      price[3] = dc[0]; // dis. pprice ist
      price[4] = dc[1]; // dis. pprice 2nd
  
      return (
        <>
          <div style={{ paddingTop: '0px' }} className="discount">
            <h4 style={{ fontSize: '20px' }} className="p-2">
              <b>Save {discount}% + Free Domain</b>
            </h4>
          </div>
          <div style={{ marginBottom: 10, marginTop: 15 }} className="package-one-price">
            <span className="amount">
              {currencyCode}{price[3]}{currencyCode === '$' ? '.' + price[4] : ''}{year} &nbsp;
              <span className="amount_cut">
                <s>
                  {" "}
                  {currencyCode}
                  {np[0]}{currencyCode === '$' ? '.' + np[1] : ''}
                </s>
              </span>
            </span>
          </div>
        </>
      );
    } else {
  
      var dc = normalPrice?.split(".");
      // price[0] = 0; // discount check
      // price[1] = normalPrice.split(".")[0]; // normal pprice
      // price[2] = normalPrice; // nor pprice full
      // price[3] = dc[0]; // nor. pprice is
      // price[4] = dc[1]; // nor. pprice 2nd
  
      console.log("dc", dc)
      return (
        <>
             {currencyCode}
             {
              dc === undefined ? null : <>{currencyCode === "$" ? normalPrice : dc[0]}{year} &nbsp;</>
             }
        </>
      );
    }
  };

  return (
    <>
      <section
        style={{ paddingTop: 50 }}
        className="bg-wave plan-section text-center premove"
      >
        <h2 className="first-heading mt-2">Find The Right Hosting Plan</h2>
        <h1 className="second-heading">Secure, Reliable, and Blazing-Fast</h1>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <blockquote className="blockquote">
                <p className="block_text mb-0">
                  I need a complete web hosting plan with email and domain name
                  for my business
                </p>

                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="block_recommendation popopsee my-1"
                >
                  <span className="back_color ">
                    <mark>
                      See Our {""} <br /> Recommendation
                    </mark>
                  </span>
                </Link>
              </blockquote>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <blockquote className="blockquote">
                <p className="block_text mb-0">
                  I need web hosting for my online store
                </p>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#seconfexampleModal"
                  className="block_recommendation popopsee my-1"
                >
                  <span className="back_color ">
                    <mark>
                      See Our {""} <br /> Recommendation
                    </mark>
                  </span>
                </Link>
              </blockquote>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <blockquote className="blockquote">
                <p className="block_text mb-0">
                  I need an affordable VPS plan with dedicated resources
                </p>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#thirdexampleModal"
                  className="block_recommendation popopsee my-1"
                >
                  <span className="back_color ">
                    <mark>
                      See Our {""} <br /> Recommendation
                    </mark>
                  </span>
                </Link>
              </blockquote>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3">
              <blockquote className="blockquote">
                <p className="block_text mb-0">
                  I need a web hosting plan for my WordPress website
                </p>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#forthexampleModal"
                  className="block_recommendation popopsee my-1"
                >
                  <span className="back_color ">
                    <mark>
                      See Our {""} <br /> Recommendation
                    </mark>
                  </span>
                </Link>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      {/* Modal First */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="recomend">Our Recommendation</p>
              <h3 className="popup-fetaure">
                {packageRecomended && packageRecomended[0]?.name}
              </h3>
              <p className="popup-featuresub">
                Ideal for the business users, to host a professional website &
                run emails.
              </p>
              <div className="mt-4 mb-5">
                <ul className="popUpUl">
                  <li>
                    <i className="fa fa-check popupcheck"></i>100GB SSD Disk
                    Space
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>Unlimited Email
                    Accounts
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>Unlimited
                    Databases
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>FREE Domain
                    Included{" "}
                    <i className="fa fa-info-circle">
                      <span className="litooltip">
                        One FREE .COM domain with purchase of a new 1-year
                        hosting plan, will renew at the then-current renewal
                        price.
                      </span>
                    </i>
                  </li>
                </ul>
              </div>
              <p className="poppamount">
                {getPriceRightHosting(
                  packageRecomended &&
                    packageRecomended[0]?.currency?.find(
                      (el) => el.currency === currencyName.api_id
                    )["annually"],
                  packageRecomended[0]?.pid,
                  "annually",
                  getActivePromotions,
                  currencyName?.prefix,
                  "/yr"
                )}
              </p>

              <button
                onClick={(e) => buttonAddToCart(e, packageRecomended[0])}
                className="popupButton "
                data-bs-dismiss="modal"
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Second */}

      <div
        className="modal fade"
        id="seconfexampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="recomend">Our Recommendation</p>
              <h3 className="popup-fetaure">
                {packageRecomended[1] && packageRecomended[1].name}
              </h3>
              <p className="popup-featuresub">
                Best to start an online store, comes in a budget but still have
                powerful specs.
              </p>
              <div className="mt-4 mb-5">
                <ul className="popUpUl">
                  <li>
                    <i className="fa fa-check popupcheck"></i>50GB SSD Disk
                    Space
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>Unlimited Email
                    Accounts
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>Unlimited
                    Bandwidth
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>FREE Domain
                    Included{" "}
                    <i className="fa fa-info-circle">
                      <span className="litooltip">
                        One FREE .COM domain with purchase of a new 1-year
                        hosting plan, will renew at the then-current renewal
                        price.
                      </span>
                    </i>
                  </li>
                </ul>
              </div>
              <p className="poppamount">
                {getPriceRightHosting(
                  packageRecomended &&
                    packageRecomended[1]?.currency?.find(
                      (el) => el.currency === currencyName.api_id
                    )["annually"],
                  packageRecomended[1]?.pid,
                  "annually",
                  getActivePromotions,
                  currencyName?.prefix,
                  "/yr"
                )}
                {/* {currencyName.api_id === 3 ? (
                  <>
                    {currencyName.prefix}
                    {packageRecomended[1] &&
                      tested(
                        packageRecomended[1]?.currency?.find(
                          (el) => el.currency === currencyName.api_id
                        )["annually"]
                      ).split(".")[0]}
                  </>
                ) : (
                  <>
                    {currencyName.prefix}
                    {packageRecomended &&
                      tested(
                        packageRecomended[1]?.currency?.find(
                          (el) => el.currency === currencyName.api_id
                        )["annually"]
                      )}
                  </>
                )}
                /yr */}
              </p>

              <button
                onClick={(e) => buttonAddToCart(e, packageRecomended[1])}
                className="popupButton "
                data-bs-dismiss="modal"
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Third */}

      <div
        className="modal fade"
        id="thirdexampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="recomend">Our Recommendation</p>
              <h3 className="popup-fetaure">
                {packageRecomended[2] && packageRecomended[2].name}
              </h3>
              <p className="popup-featuresub">
                Best-suited for a growing online store that requires powerful
                dedicated resources.
              </p>
              <div className="mt-4 mb-5">
                <ul className="popUpUl">
                  <li>
                    <i className="fa fa-check popupcheck"></i>2 vCores CPU
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>4 GB RAM
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>100 GB Disk Space
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>600 GB Bandwidth
                  </li>
                </ul>
              </div>
              <p className="poppamount">
                {getPriceRightHosting(
                  packageRecomended &&
                    packageRecomended[2]?.currency?.find(
                      (el) => el.currency === currencyName.api_id
                    )["monthly"],
                  packageRecomended[2]?.pid,
                  "monthly",
                  getActivePromotions,
                  currencyName?.prefix,
                  "/mon"
                )}
              </p>

              <button
                onClick={(e) => buttonAddToCartVps(e, packageRecomended[2])}
                className="popupButton "
                data-bs-dismiss="modal"
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Forth */}

      <div
        className="modal fade"
        id="forthexampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="recomend">Our Recommendation</p>
              <h3 className="popup-fetaure">
                {packageRecomended[3] && packageRecomended[3].name}
              </h3>
              <p className="popup-featuresub">
                Ideal hosting plan to run a standard WordPress website.
              </p>
              <div className="mt-4 mb-5">
                <ul className="popUpUl">
                  <li>
                    <i className="fa fa-check popupcheck"></i>15GB SSD Disk
                    Space
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>30 Email Accounts
                  </li>

                  <li>
                    <i className="fa fa-check popupcheck"></i>300GB Bandwidth
                  </li>
                  <li>
                    <i className="fa fa-check popupcheck"></i>FREE Domain
                    Included{" "}
                    <i className="fa fa-info-circle">
                      <span className="litooltip">
                        One FREE .COM domain with purchase of a new 1-year
                        hosting plan, will renew at the then-current renewal
                        price.
                      </span>
                    </i>
                  </li>
                </ul>
              </div>
              <p className="poppamount">
                {getPriceRightHosting(
                  packageRecomended &&
                    packageRecomended[3]?.currency?.find(
                      (el) => el.currency === currencyName.api_id
                    )["annually"],
                  packageRecomended[3]?.pid,
                  "annually",
                  getActivePromotions,
                  currencyName?.prefix,
                  "/yr"
                )}
              </p>

              <button
                onClick={(e) => buttonAddToCart(e, packageRecomended[3])}
                className="popupButton "
                data-bs-dismiss="modal"
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightHosting;
