import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GetPackages } from "../../services/Network";
import BundelBooster from "./Seo/BundelBooster";
import ComparePlans from "../reuseable/ComparePlans";
import GoingOnline from "./Seo/GoingOnline";
import PopupSlider from "../reuseable/includes/PopupSlider";
import WhyChooseUsThird from "../reuseable/WhyChooseUsThird";
import DomainMigration from "./Seo/DomainMigration";
import InPakistan from "./Seo/InPakistan";
import Faq from "../reuseable/Faq";
import DomainTips from "./Seo/DomainTips";
import AllPlanIncludes from "./Seo/AllPlanIncludes";

const Seo = () => {
  const [comparePlansState, setComparePlansState] = useState([]);
  const [pidsss, setPid] = useState([181, 125, 181]);

  const dataPlans = {
    heading1: "Target The Customers You Want!",
    heading2: "Making You More Visible, Findable, and Accessible",
    OnCall: "Get Quote on Call",
    pakageArray: [
      {
        pid: 181,
        package_name: "Regional",
        package_plans: " ",
        planList: [
          "Competitive Analysis ",
          "5 Targeted Keywords ",
          "Keyword Research ",
          "Website In-Depth Analysis ",
          " Google Analytics Setup ",
          " Google Search Console Setup ",
          " Link Building ",
          " Monthly Reporting ",
          "  4-Months Guarantee ",
        ],
      },
      {
        pid: 125,
        package_name: "National",
        package_plans: " ",
        planList: [
          "Competitive Analysis ",
          "15+ Targeted Keywords ",
          "Keyword Research ",
          "Website In-Depth Analysis ",
          " Google Analytics Setup ",
          " Google Search Console Setup ",
          "  Link Building  ",
          " Monthly Reporting ",
          "  4-Months Guarantee  ",
        ],
      },
      {
        pid: 181,
        package_name: "International",
        package_plans: "",
        planList: [
          "Competitive Analysis ",
          "Unlimited Targeted Keywords ",
          "Keyword Research ",
          "Website In-Depth Analysis ",
          "  Google Analytics Setup ",
          "  Google Search Console Setup ",
          "  Link Building  ",
          "  Monthly Reporting  ",
          "  4-Months Guarantee  ",
        ],
      },
    ],
  };

  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Optimizing Websites into Places Where Sales Grow",
    Box: [
      {
        heading: "SEO Audit",
        subHeading: "",
        icon: "icon wp-seo-audit",
      },
      {
        heading: "Keyword Analysis",
        subHeading: "",
        icon: "icon wp-keyword-analysis",
      },
      {
        heading: "Link Building",
        subHeading: "",
        icon: "icon wp-link-building",
      },
      {
        heading: "Content Optimization",
        subHeading: "",
        icon: "icon wp-content-optimization",
      },
      {
        heading: "Page Speed",
        subHeading: "",
        icon: "icon wp-page-speed",
      },
      {
        heading: "Local SEO",
        subHeading: "",
        icon: "icon wp-local-seo",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        bundleHeading: "Content Writing",
        bundleParagh:
          "Our team of professional content writers is adept in creating just the right kind of content that can help improve your search engine rankings, attract more customers, and resonate across your audience’s minds.",
        bundleImage: "assests/img/content-writing.png",
        bundleId: "seo-tab",
        bundleDataTarget: "#seo",
        bundleAriaControl: "seo",
        bundleParaghId: "seo",
        bundleParaghAriaLable: "seo-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        bundleHeading: "Social Media",
        bundleParagh:
          "We know how important it is to stay active on social media nowadays. Our certified social media marketers can target your customers across various social media platforms and persuade them to buy from you.",
        bundleImage: "assests/img/social-media.png",
        bundleId: "content-Writing-tab",
        bundleDataTarget: "#content",
        bundleAriaControl: "content",
        bundleParaghId: "content",
        bundleParaghAriaLable: "content-Writing-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "We provide our clients with SSL certificates to help them keep their site, and the data of their customers safe from hackers. An SSL is just what you need to build your customers’ trust and get those sales going!",
        bundleImage: "assests/img/ssl-certificate.png",
        bundleId: "social-media-tab",
        bundleDataTarget: "#social",
        bundleAriaControl: "social",
        bundleParaghId: "social",
        bundleParaghAriaLable: "social-media-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const GoingOnlineData = {
    Heading: "Got The Traffic? Let’s Convert It!",
    subHeading:
      "Fast Loading Speed & a Cutting-Edge Web Design Can Work Wonders",
    box: [
      {
        topTitle: "Want Blazing-Fast Site Speed?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        topTitle: "Need Visitor Engagement?",
        Title: "Refurbish Your Site",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "We Talk Results. Nothing Else Counts.",
    box: [
      {
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        icon: "icon ws-clients",
        title: "1000s of Trusted Clients",
      },
      {
        icon: "icon wp-certified-expert",
        title: "Certified Experts",
      },
      {
        icon: "icon wp-proven-techniques",
        title: "Proven Techniques",
      },
      {
        icon: "icon wp-advanced-tool",
        title: "Advanced Tools",
      },
    ],
  };
  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const InPakistanData = {
    Heading: "SEO Services in Pakistan",
    subHeading: "Helping Businesses Outrank Competition",
    peragraph: [
      <>
        <p>
          <Link to="/">
            <strong>WebSouls</strong>
          </Link>
          &nbsp;is proud to have helped numerous small and large-scale
          businesses in improving their rankings on Search Engine Result Pages,
          attracting more potential customers, increasing lead generation, and
          boosting their revenue with the help of our SEO services in Pakistan.
        </p>
        <p>
          Our top-class search engine optimization services are available in
          Lahore, Karachi, Islamabad, and anywhere else around the Nation. You
          can simply visit us at one of our offices or contact us via live chat,
          phone call, or email, and we’d help you become more visible to those
          searching for your products/services online.
        </p>
        <p>
          The in-house team of SEO experts and SEO writers at WebSouls is
          experienced in creating and executing SEO strategies that work. We
          know getting your website ranked on the top of Google’s search results
          can be a tricky task, but it’s not impossible with consistent effort
          and white-hat SEO techniques.
        </p>
        <p>
          As one of the&nbsp;<strong>leading SEO companies in Karachi</strong>,
          we have helped numerous brands in improving their visibility on giant
          search engines. Multiple large-scale brands and organizations have put
          their trust in us over the years for SEO services in Karachi and
          Lahore.
        </p>
        <p>
          Or professionals always make sure to develop a deep understanding of
          our clients’ business needs before diving into the strategy making
          process. All our SEO strategies are hence custom-made for each client
          and executed efficiently to ensure the best results. 100% client
          satisfaction is our top-most priority!
        </p>
        <p>
          Apart from the efforts of our professional SEO experts, you can also
          avail our content writing services, social media marketing, or get
          an&nbsp;
          <Link to="/ssl-certificates">
            <b>SSL certificate</b>
          </Link>
          &nbsp;from us at cheap rates. In case you’re wondering about the
          quality of our services, know that we’re a trusted&nbsp;
          <Link to="/seo-services">
            <b>SEO company in Pakistan</b>
          </Link>
          , and also the top choice of a huge number of businesses whom we’ve
          helped grow over the past 18 years.
        </p>
        <p>
          Moreover, we don’t just do SEO, we can also help you steer clear of
          any damage to your site’s rankings with&nbsp;
          <Link to="/business-hosting">
            <strong>ultra-fast web hosting</strong>
          </Link>
          &nbsp;and&nbsp;
          <Link to="/web-development">
            <strong>web development services</strong>
          </Link>
          &nbsp;that can help improve visitor engagement, decrease the bounce
          rate, and increase your rate of conversions.
        </p>
        <p>
          With us, you won’t just be enjoying the best SEO services in Karachi,
          or anywhere else in Pakistan, but you’d also be availing amazing&nbsp;
          <strong>best SEO services in Lahore</strong>&nbsp;packages at
          affordable prices from a leading agency.
        </p>
        <p>
          Visit our today and let our certified SEO consultants become an
          extension of your SEO Company in Lahore or Karachi team to help you
          achieve goals, increase revenue, and stay one step ahead of the
          competition!
        </p>
        <p>
          If you own a local business and are providing services within a
          certain region, our SEO experts can help bring more customers to your
          doorstep by optimizing your site and social media profiles for&nbsp;
          <b>local SEO</b>.
        </p>
        <p>
          We can handle all aspects of local SEO, including improving your
          Google Listings, helping you dominate in your local area. Our SEO
          services are scalable, so you get the same benefits even if you choose
          to expand in the future. So, whether you want to target a single city
          such as Lahore or Karachi, or you want to target the whole country,
          we’ve got an SEO plan for you!
        </p>
        <p>
          When it comes to SEO, we believe in uplifting all our clients. Whether
          you operate a small local business or an international company, we
          have an SEO strategy designed to grow your business. We’ll make sure
          that your website is ranked for only the best competitive keywords
          that will get you both more traffic and more customers.
        </p>
        <p>
          We also help you improve your website ranking on Google’s local
          listings and get you high quality organic backlinks to boost your
          site’s rankings. Our SEO team stay up to date with the latest SEO
          techniques, helping you stay in line with Google’s guidelines so you
          can retain your high rankings regardless of Google’s new requirements.
        </p>
        <p>
          If your website is not getting organic traffic, then we’ll conduct an
          SEO audit to see where your website stands. Our SEO audits are carried
          out by qualitied SEO professionals who will then create a plan for
          your website’s optimization. No matter how many keywords you want your
          website to be optimized for, we’ve got the perfect plan for you.
        </p>
        <p>
          We carefully craft customized SEO plans according to the needs of your
          business. Whether you are looking to improve your local SEO ranking or
          get more organic traffic from a global audience, we have the perfect
          package for you. So, if your goals are to get more organic traffic,
          improve website conversions or increase your brand visibility, our SEO
          services have everything you need.
        </p>
        <p>
          As the <b>leading SEO company in Pakistan</b>,&nbsp;
          <a href="/">
            <b>Websouls</b>
          </a>
          &nbsp;has the best SEO experts in the country. We work to satisfy you
          in the long-run, which is why our team only uses the latest Google
          guidelines to get your business on the first page, and most
          importantly, to keep its high rank.
        </p>
        <p>
          We know that SEO is one of the best ways to build your business
          online, which is why we work with long-term goals in mind. Whether you
          want to reach millions of potential customers worldwide, or want to
          dominate the local market, our SEO plans will help you grow
          exponentially online.
        </p>
        <p>
          The best thing about our&nbsp;<b>SEO services</b>&nbsp;is that we talk
          numbers! With our monthly reports, you can see the improvement in your
          business’s online presence yourself and watch the visibility of your
          brand increase over web. In case of any confusions, questions, or
          queries, our team is always ready to assist you!
        </p>
        <p>
          For those of our customers who haven’t begun their online journey yet,
          we aim to provide the best and most suitable web solutions for
          beginners and can make your transition into the digital realm easy,
          effortless, and utterly smooth.
        </p>
        <p>&nbsp;</p>
      </>,
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What type of SEO techniques do you employ?",
        desc: "Our team of certified SEO experts have the updated knowledge of Google and its ever-changing algorithms. They conduct a detailed competitor and keyword research, perform site audits, and employ proven white-hat techniques to optimize your site for on-page SEO, off-page SEO, local SEO, and more.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "How long does the optimization process take?",
        desc: "The speed at which your site’s ranking improves varies depending on numerous factors. If your niche isn’t overly competitive, you may see the results within weeks. On the other hand, if you have a very competitive market, it can take months before you see significant improvement. However, in any case, our experts are dedicated to putting in their best efforts in order to develop SEO strategies that’re suited for your business.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Who makes the content for SEO?",
        desc: "All on-page and off-page articles, as well as any kind of web content (if needed) is written by our team of creative content writers who know how to include the right keywords while avoiding any sort of unnecessary ‘stuffing’.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Would you provide any guarantee?",
        desc: "No SEO agency can guarantee its client’s no. 1 position on Google’s SERPs, or any specific ranking for a given keyword. That’s because the placement of your site on Search Engine Result Pages is determined my numerous variables. However, we do guarantee to provide all our clients with the best SEO services, and are committed to putting in our maximum efforts in order to help you obtain higher rankings on Google.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "How Can SEO Help?",
    subHeading: "More Leads, More Conversions, More Revenue",
    box: [
      {
        icon: "icon wp-outrank",
        title: "Outrank Your Competition",
        peragraph:
          "Our team of certified experts conducts a detailed competitor research and puts in all their best efforts to help you cut through the noise online.",
      },
      {
        icon: "icon wp-ogranic-traffic",
        title: "Increased Organic Traffic",
        peragraph:
          "Our SEO services can help you attract more organic traffic by making you rank higher on giant search engines and become relevant in search results.",
      },
      {
        icon: "icon wp-potential-customer",
        title: "Attract Potential Customers",
        peragraph:
          "With our consistent SEO efforts, your site would be visible to those looking for your services or products. Hence, you’d be attracting more of your potential customers.",
      },
      {
        icon: "icon wp-sell-to-customer",
        title: "Sell to Local Consumers",
        peragraph:
          "Our local SEO services can help you become a part of Google’s Local Pack and grow your local business online by attracting new local customers every day!",
      },
      {
        icon: "icon wp-revenue-generation",
        title: "Higher Revenue Generation",
        peragraph:
          "With a site that ranks on the first page of Google’s SERPs, you’d be able to generate more leads, make more sales, and spark up your revenue within months!",
      },
      {
        icon: "icon wp-brand-generatin",
        title: "Better Brand Awareness",
        peragraph:
          "Your website can be your sales representative, and we make sure it works hard. With us, your customers would know you, and your products, and your business better.",
      },
    ],
  };
  useEffect(() => {
    GetPackages(pidsss).then((res) => {
      setComparePlansState(res);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          SEO Services In Pakistan | Best SEO Company In Lahore | WebSouls
        </title>
        <meta
          name="title"
          content="SEO Services In Pakistan | Best SEO Company In Lahore | WebSouls"
        />
        <meta
          name="description"
          content="Increase your website traffic, boost sales & generate revenue with WebSouls best SEO services in Pakistan. Get your website on No. 1 with top SEO Company in Lahore, Karachi & Islamabad."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "SEO Services In Pakistan - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "WebSouls is proud to have helped numerous small and large-scale businesses in improving their rankings on Search Engine Result Pages, attracting more potential customers, increasing lead generation, and boosting their revenue with the help of our SEO services in Pakistan.",
  "brand": "WebSouls",
  "sku": "SEO Services In Pakistan",
  "mpn": "SEO Services In Pakistan",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/seo-services",
    "priceCurrency": "PKR",
    "lowPrice": "10000",
    "highPrice": "25000",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "4.9",
    "worstRating": "4.7",
    "ratingCount": "2569"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What type of SEO Techniques do you employ?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our team of certified SEO experts have the updated knowledge of Google and its ever-changing algorithms. They conduct a detailed competitor and keyword research, perform site audits, and employ proven white-hat techniques to optimize your site for on-page SEO, off-page SEO, local SEO, and more."
    }
  },{
    "@type": "Question",
    "name": "How long does the optimization process take?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The speed at which your site’s ranking improves varies depending on numerous factors. If your niche isn’t overly competitive, you may see the results within weeks. On the other hand, if you have a very competitive market, it can take months before you see significant improvement. However, in any case, our experts are dedicated to putting in their best efforts in order to develop SEO strategies that’re suited for your business."
    }
  },{
    "@type": "Question",
    "name": "Who makes the content for SEO?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All on-page and off-page articles, as well as any kind of web content (if needed) is written by our team of creative content writers who know how to include the right keywords while avoiding any sort of unnecessary ‘stuffing’."
    }
  },{
    "@type": "Question",
    "name": "Would you provide any guarantee?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No SEO agency can guarantee its client’s no. 1 position on Google’s SERPs, or any specific ranking for a given keyword. That’s because the placement of your site on Search Engine Result Pages is determined my numerous variables. However, we do guarantee to provide all our clients with the best SEO services, and are committed to putting in our maximum efforts in order to help you obtain higher rankings on Google."
    }
  }]
}`}
        </script>
      </Helmet>
      <PopupSlider data={dataPlans} />
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <ComparePlans comparePlansState={comparePlansState} />
      <DomainTips Data={technicalSpecificationsData} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
    </>
  );
};

export default Seo;
